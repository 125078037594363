import * as React from 'react';
import type { SVGProps } from 'react';
const SvgClipBadge = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={28}
      height={28}
      fill="none"
      viewBox="0 0 28 28"
      {...props}
    >
      <mask id="clipBadge_svg__a" fill="#fff">
        <path d="M9.333 1.167A5.835 5.835 0 0 0 3.5 7a5.836 5.836 0 0 0 5.833 5.833A5.836 5.836 0 0 0 15.167 7a5.836 5.836 0 0 0-5.834-5.833Zm0 10.5A4.665 4.665 0 0 1 4.667 7c0-1.08.367-2.071.986-2.859l6.539 6.54a4.61 4.61 0 0 1-2.859.986Zm3.681-1.809L6.475 3.32a4.61 4.61 0 0 1 2.858-.986A4.665 4.665 0 0 1 14 7a4.61 4.61 0 0 1-.986 2.858Z" />
      </mask>
      <path
        fill="#fff"
        d="M9.333 1.167A5.835 5.835 0 0 0 3.5 7a5.836 5.836 0 0 0 5.833 5.833A5.836 5.836 0 0 0 15.167 7a5.836 5.836 0 0 0-5.834-5.833Zm0 10.5A4.665 4.665 0 0 1 4.667 7c0-1.08.367-2.071.986-2.859l6.539 6.54a4.61 4.61 0 0 1-2.859.986Zm3.681-1.809L6.475 3.32a4.61 4.61 0 0 1 2.858-.986A4.665 4.665 0 0 1 14 7a4.61 4.61 0 0 1-.986 2.858Z"
      />
      <path
        fill="#fff"
        d="m5.652 4.141.849-.848-.957-.957L4.71 3.4l.944.741Zm6.54 6.54.741.943 1.063-.836-.956-.956-.848.849Zm.822-.823-.848.849.956.957.836-1.065-.944-.74ZM6.475 3.32l-.742-.943-1.063.835.956.957.849-.849Zm2.858-.986-.002 1.2h.002v-1.2Zm0-2.366A7.035 7.035 0 0 0 2.3 7h2.4a4.635 4.635 0 0 1 4.633-4.634v-2.4ZM2.3 7a7.035 7.035 0 0 0 7.033 7.033v-2.4A4.635 4.635 0 0 1 4.7 7H2.3Zm7.033 7.033A7.035 7.035 0 0 0 16.367 7h-2.4a4.636 4.636 0 0 1-4.634 4.633v2.4ZM16.367 7A7.035 7.035 0 0 0 9.333-.033v2.4A4.635 4.635 0 0 1 13.967 7h2.4Zm-7.034 3.466A3.465 3.465 0 0 1 5.867 7h-2.4a5.865 5.865 0 0 0 5.866 5.867v-2.4ZM5.867 7c0-.802.271-1.534.73-2.117L4.708 3.4A5.81 5.81 0 0 0 3.467 7h2.4ZM4.804 4.99l6.54 6.54 1.696-1.698-6.539-6.539L4.804 4.99Zm6.646 4.747a3.409 3.409 0 0 1-2.114.73l-.005 2.4a5.809 5.809 0 0 0 3.602-1.243L11.45 9.737Zm2.413-.727-6.54-6.54-1.697 1.698 6.54 6.539 1.697-1.697ZM7.217 4.262a3.41 3.41 0 0 1 2.114-.729l.005-2.4a5.81 5.81 0 0 0-3.603 1.243l1.484 1.886Zm2.116-.729A3.465 3.465 0 0 1 12.8 7h2.4a5.865 5.865 0 0 0-5.867-5.867v2.4ZM12.8 7a3.41 3.41 0 0 1-.73 2.117l1.888 1.482A5.81 5.81 0 0 0 15.2 7h-2.4Z"
        mask="url(#clipBadge_svg__a)"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M13.417 13.365a6.953 6.953 0 0 1-1.75.538v6.513a6.413 6.413 0 0 0 6.416 6.417 6.413 6.413 0 0 0 6.417-6.416V7h-1.75v13.416a4.665 4.665 0 0 1-4.667 4.667 4.665 4.665 0 0 1-4.666-4.666v-7.052ZM16.189 2.92a2.921 2.921 0 0 1 2.207.85c.547.548.854 1.29.854 2.063v12.25a1.17 1.17 0 0 1-1.167 1.167 1.17 1.17 0 0 1-1.166-1.167V9.802a7.02 7.02 0 0 1-1.75 2.415v5.866a2.917 2.917 0 1 0 5.833 0V5.833a4.665 4.665 0 0 0-6.246-4.393 7.04 7.04 0 0 1 1.435 1.48Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgClipBadge;
