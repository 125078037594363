import React from 'react';
import createStore from 'zustand';
import { TFunction } from 'i18next';

import { immer } from 'helpers/zustand';
import { uuid } from 'helpers/common';

export enum TooltipTypes {
  SUCCESS,
  INFO,
  ERROR,
}

interface TooltipState {
  id: string;
  type: TooltipTypes;
  body: any;
  animate: boolean;
  translate: boolean;
}

export interface GlobalTooltipState {
  tooltip: TooltipState | undefined;
}

export interface GlobalTooltipConfig {
  type: TooltipTypes;
  body: React.ReactNode | ((t: TFunction) => void) | string;
  id?: string;
  animate?: boolean;
}
export interface GlobalTooltipConfigWithTranslation {
  type: TooltipTypes;
  body: ((t: TFunction) => string) | string;
  id?: string;
  animate?: boolean;
  translate: boolean;
}

export interface GlobalTooltipActions {
  show(config: GlobalTooltipConfig): void;
  show(config: GlobalTooltipConfigWithTranslation): void;
  show(config: any): void;

  remove: () => void;
}

const globalTooltipStore = createStore(
  immer<GlobalTooltipState & GlobalTooltipActions>((set) => ({
    tooltip: undefined,
    show: ({
      type,
      body,
      id,
      animate = true,
      translate = true,
    }: TooltipState) =>
      set((state) => {
        state.tooltip = {
          id: id == null ? uuid() : id,
          type,
          body,
          animate,
          translate,
        };
      }),
    remove: () => set((state) => void (state.tooltip = undefined)),
  }))
);

const globalTooltipStoreState = globalTooltipStore.getState();

export { globalTooltipStore, globalTooltipStoreState };
