import { globalTooltipStoreState } from 'modules/globalTooltip';
import {
  GlobalTooltipConfig,
  GlobalTooltipConfigWithTranslation,
} from 'modules/globalTooltip/store';

export function showTooltip(config: GlobalTooltipConfig): () => void;
export function showTooltip(
  config: GlobalTooltipConfigWithTranslation
): () => void;
export function showTooltip(config: any) {
  const { show, remove } = globalTooltipStoreState;

  show(config);

  return remove;
}
