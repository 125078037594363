import styled, { css, keyframes } from 'styled-components/macro';
import { device } from 'components/Global/MediaQuery';

const appearIntPassport = keyframes`
  0% {
    transform: scale(0.6);
  }
  50% {
    transform: scale(1.4) translateY(-5%);
  }
  100% {
    transform: scale(1.4) translateY(-5%);
  }
`;

const appearPassport = keyframes`
  0% {
    transform: scale(0.6);
  }
  50% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(0.8);
  }
`;

const appearIdCard = keyframes`
  0% {
    transform: scale(0.6);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
`;

export const ScCameraModal = styled.div<
  ScVideoProps & { fullOnMobileScreen?: boolean }
>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  max-height: 348px;
  position: relative;
  overflow: hidden;
  isolation: isolate;
  ${({ rounded }) =>
    rounded != null &&
    css`
      max-height: none;
      border-radius: 50%;
    `}
  ${({ fullOnMobileScreen }) =>
    fullOnMobileScreen &&
    css`
      @media (${device.mobileXL}) {
        max-height: none;
        video {
          object-fit: cover !important;
        }
      }
    `}
  video {
    object-fit: fill;
    object-position: center;
    ${({ rounded }) =>
      !rounded &&
      css`
        max-height: none;
        width: 100%;
        position: relative;
      `}
  }
  .mask {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    /*overflow hidden to contain box shadow*/
    overflow: hidden;
  }
  .example {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    &__container {
      height: 100%;
      width: auto;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      & > .idCard {
        position: relative;
        top: auto;
        left: 10%;
        animation: ${appearIdCard} 5s ease-out infinite;
        @media (${device.mobileXXL}) {
          left: 12%;
        }
      }
      & > .intPassport {
        position: relative;
        top: auto;
        left: 28%;
        animation: ${appearIntPassport} 5s ease-out infinite;
        @media (${device.mobileXXL}) {
          left: 25%;
        }
      }
      & > .passport {
        position: relative;
        top: 6%;
        left: 15%;
        animation: ${appearPassport} 5s ease-out infinite;
      }
    }
  }
`;

export const ScCameraButtonBlock = styled.div`
  padding: 28px 36px 36px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  .makeManualPhoto {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    cursor: pointer;
    color: ${({ theme }) => theme.baseSecondaryContent};
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-decoration-line: underline;
    svg {
      path {
        stroke: ${({ theme }) => theme.baseSecondaryContent};
      }
    }
  }
  @media (${device.mobileXL}) {
    padding: 18px;
  }
`;

export interface ScVideoProps {
  rounded?: boolean;
  centered?: boolean;
}

type ScVideoC = ScVideoProps & {
  isHide: boolean;
};

export const ScVideo = styled.video<ScVideoC>`
  ${({ centered }) =>
    centered
      ? css`
          height: 100%;
          min-width: 100%;
          position: absolute;
          left: 50%;
          transform: scaleX(-1) translateX(50%);
        `
      : css`
          width: 100%;
          height: 100%;
        `}

  display: ${({ isHide }) => (isHide ? 'none' : 'block')};

  ${({ rounded }) =>
    rounded != null &&
    css`
      object-fit: cover !important;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
    `}
`;

interface ScCanvasProps {
  isHide: boolean;
}

export const ScCanvas = styled.canvas<ScCanvasProps>`
  width: 100%;
  vertical-align: top;
  display: ${({ isHide }) => (isHide ? 'none' : 'block')};
  transform: scaleX(1);
`;
