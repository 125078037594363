import React from 'react';
import { useTheme } from 'hooks/theme';

const CameraError = () => {
  const { primary, primaryHover, primaryActive, error } = useTheme();

  return (
    <div style={{ maxWidth: 320, width: '100%', height: 'auto' }}>
      <svg
        width="100%"
        height="auto"
        viewBox="0 0 340 340"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_132_9774)">
          <path
            d="M299.288 31.1301L170.74 47.2861L186.331 171.337L314.879 155.181L299.288 31.1301Z"
            fill="black"
          />
          <path
            d="M304.48 23.8216L175.932 39.9775L191.522 164.029L320.07 147.873L304.48 23.8216Z"
            fill={primary}
          />
          <path
            opacity="0.4"
            d="M286.221 11.4268L278.173 17.9365L284.682 25.9833L292.73 19.4735L286.221 11.4268Z"
            fill={primary}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M273.37 180.202L273.085 185.481L268.654 182.595L264.226 179.708L268.94 177.316L273.655 174.924L273.37 180.202Z"
            fill={primary}
          />
          <path
            opacity="0.2"
            d="M261.565 18.8371L239.106 24.1885L240.07 28.2309L262.528 22.8795L261.565 18.8371Z"
            fill={primary}
          />
          <path
            d="M159.731 104.495C162.417 104.495 164.595 102.318 164.595 99.631C164.595 96.9445 162.417 94.7666 159.731 94.7666C157.044 94.7666 154.866 96.9445 154.866 99.631C154.866 102.318 157.044 104.495 159.731 104.495Z"
            fill={primary}
          />
          <path
            opacity="0.2"
            d="M335.292 102.661C336.965 102.661 338.322 101.305 338.322 99.6314C338.322 97.9581 336.965 96.6016 335.292 96.6016C333.619 96.6016 332.262 97.9581 332.262 99.6314C332.262 101.305 333.619 102.661 335.292 102.661Z"
            fill={primary}
          />
          <path
            opacity="0.4"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M226.137 182.87C226.112 184.173 225.894 185.41 226.165 186.813C228.407 186.675 229.579 186.959 231.554 185.829C232.956 185.028 234.001 183.808 234.984 182.577C236.224 181.022 236.902 179.267 239.089 178.511C241.154 177.798 243.308 178.843 245.536 177.965C248.387 176.841 249.81 174.417 251.092 172.464C251.723 171.5 252.053 170.57 253.7 169.976C254.846 169.563 256.2 169.838 257.547 169.755C260.94 169.545 263.719 168.183 265.023 164.984C264.003 164.361 262.861 164.096 261.809 163.574C259.147 167.535 254.854 164.463 250.685 167.029C247.629 168.909 247.097 173.098 244.062 174.256C242.927 174.69 241.411 174.336 240.409 174.383C239.171 174.44 237.844 174.563 236.607 175.181C233.394 176.787 232.361 181.227 229.217 182.548C228.17 182.988 227.352 182.72 226.137 182.87Z"
            fill={primary}
          />
          <path
            d="M192.332 146.6C193.031 148.933 194.193 150.661 196.015 151.918C197.717 153.091 199.343 153.081 200.356 151.982C201.388 150.864 201.2 149.406 199.709 147.846C198.469 146.549 197.029 145.447 196.263 143.746C195.803 142.727 194.869 142.087 193.661 142.373C192.273 142.701 191.997 143.812 192.063 145.027C192.097 145.642 192.27 146.252 192.332 146.6Z"
            fill="white"
          />
          <path
            d="M202.177 155.345C202.661 156.464 204.647 157.172 205.869 156.698C206.795 156.339 207.125 155.641 206.747 154.719C206.285 153.592 204.342 152.922 203.125 153.441C202.234 153.821 201.889 154.479 202.177 155.345Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M300.171 36.3068C304.397 36.8763 302.949 30.2036 299.984 31.5175C298.44 32.2009 298.527 36.0853 300.171 36.3068Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M302.372 39.871C302.076 39.3328 300.716 38.6392 299.628 39.4339C298.212 40.473 298.235 49.2178 300.088 50.0501C302.732 51.2348 304.739 44.1724 302.372 39.871Z"
            fill="white"
          />
          <path
            d="M296.488 94.8714C296.779 68.6773 275.78 47.2071 249.586 46.9163C223.391 46.6255 201.921 67.6243 201.63 93.8184C201.339 120.013 222.338 141.483 248.533 141.774C274.727 142.064 296.197 121.066 296.488 94.8714Z"
            fill="white"
          />
          <path
            d="M250.272 134.807C272.617 134.145 290.195 115.494 289.533 93.1487C288.872 70.8033 270.221 53.2252 247.875 53.8869C225.53 54.5486 207.952 73.1995 208.614 95.5448C209.275 117.89 227.926 135.468 250.272 134.807Z"
            fill={error}
          />
          <path
            d="M268.301 75.1128C266.942 73.7541 264.74 73.7541 263.381 75.1128L249.068 89.427L234.754 75.1128C233.395 73.7541 231.193 73.7541 229.835 75.1128C228.476 76.4712 228.476 78.6736 229.835 80.032L244.148 94.3462L229.835 108.66C228.476 110.019 228.476 112.221 229.835 113.58C230.514 114.259 231.404 114.599 232.294 114.599C233.184 114.599 234.074 114.259 234.754 113.58L249.068 99.2657L263.381 113.58C264.061 114.259 264.951 114.599 265.841 114.599C266.731 114.599 267.621 114.259 268.301 113.58C269.659 112.221 269.659 110.019 268.301 108.66L253.987 94.3462L268.301 80.032C269.659 78.6736 269.659 76.4712 268.301 75.1128Z"
            fill="white"
          />
          <path
            d="M216.856 125.594C221.422 121.028 221.422 113.626 216.856 109.06C212.29 104.494 204.888 104.494 200.322 109.06C195.756 113.626 195.756 121.028 200.322 125.594C204.888 130.16 212.29 130.16 216.856 125.594Z"
            fill="white"
          />
          <path
            d="M213.637 123.206C213.027 123.206 212.532 122.711 212.532 122.101C212.532 119.885 210.729 118.081 208.512 118.081C206.296 118.081 204.493 119.884 204.493 122.101C204.493 122.711 203.998 123.206 203.388 123.206C202.778 123.206 202.283 122.711 202.283 122.101C202.283 118.666 205.077 115.871 208.512 115.871C211.948 115.871 214.742 118.666 214.742 122.101C214.742 122.712 214.247 123.206 213.637 123.206Z"
            fill={primary}
          />
          <path
            d="M203.523 115.423C204.338 115.423 204.999 114.762 204.999 113.947C204.999 113.132 204.338 112.471 203.523 112.471C202.707 112.471 202.046 113.132 202.046 113.947C202.046 114.762 202.707 115.423 203.523 115.423Z"
            fill={primary}
          />
          <path
            d="M213.596 115.423C214.412 115.423 215.073 114.762 215.073 113.947C215.073 113.132 214.412 112.471 213.596 112.471C212.781 112.471 212.12 113.132 212.12 113.947C212.12 114.762 212.781 115.423 213.596 115.423Z"
            fill={primary}
          />
        </g>
        <path
          d="M146.377 161.668L183.069 154.182L179.044 135.578C177.961 130.564 173.005 127.36 167.973 128.444L140.413 133.958L146.377 161.668Z"
          fill="#1D1D1B"
        />
        <path
          d="M252.156 289.332L294.281 280.065C304.04 277.951 310.23 268.346 308.125 258.595L283.526 144.795C281.423 135.069 271.808 128.871 262.049 130.976L219.75 140.286L252.156 289.332Z"
          fill="#1D1D1B"
        />
        <path
          d="M221.962 140.783L238.426 137.225L236.86 129.988C236.146 126.689 232.916 124.592 229.642 125.304L219.262 127.547L221.962 140.783Z"
          fill="#1D1D1B"
        />
        <path
          d="M227.909 138.771L226.494 132.221C225.789 128.93 222.552 126.833 219.26 127.547L183.726 135.23C180.434 135.936 178.348 139.189 179.06 142.48L180.424 148.785L154.347 154.428L151.465 141.091C150.381 136.077 145.451 132.871 140.411 133.957L71.5549 148.841C66.5083 149.936 63.3133 154.899 64.3967 159.914L67.2783 173.251L34.7974 180.265C25.0382 182.378 18.8571 191.991 20.9606 201.718L45.5593 315.517C47.664 325.261 57.2615 331.46 67.0295 329.353L257.336 288.213C267.08 286.108 273.277 276.495 271.173 266.743L246.573 152.944C244.674 144.156 236.635 138.263 227.909 138.771Z"
          fill="white"
        />
        <path
          opacity="0.6"
          d="M227.909 138.771L226.494 132.221C225.789 128.93 222.552 126.833 219.26 127.547L183.726 135.23C180.434 135.936 178.348 139.189 179.06 142.48L180.424 148.785L154.347 154.428L151.465 141.091C150.381 136.077 145.451 132.871 140.411 133.957L71.5549 148.841C66.5083 149.936 63.3133 154.899 64.3967 159.914L67.2783 173.251L34.7974 180.265C25.0382 182.378 18.8571 191.991 20.9606 201.718L45.5593 315.517C47.664 325.261 57.2615 331.46 67.0295 329.353L257.336 288.213C267.08 286.108 273.277 276.495 271.173 266.743L246.573 152.944C244.674 144.156 236.635 138.263 227.909 138.771Z"
          fill={primary}
        />
        <path
          d="M258.032 251.986C256.923 258.969 254.248 264.391 249.488 268.714C245.045 272.748 240.361 273.346 237.021 270.575C233.621 267.756 233.596 263.488 237.285 258.421C240.352 254.21 244.069 250.482 245.618 245.292C246.548 242.18 248.987 239.979 252.575 240.334C256.695 240.742 257.92 243.831 258.198 247.355C258.338 249.137 258.077 250.961 258.032 251.986Z"
          fill="white"
        />
        <path
          d="M233.079 280.959C232.121 284.364 226.676 287.172 222.979 286.279C220.176 285.602 218.957 283.722 219.687 280.925C220.582 277.502 225.912 274.823 229.614 275.847C232.326 276.595 233.575 278.352 233.079 280.959Z"
          fill="white"
        />
        <path
          d="M28.753 206.346C29.7662 199.348 32.3673 193.89 37.0674 189.502C41.4542 185.408 46.1301 184.745 49.5074 187.47C52.9456 190.242 53.0289 194.51 49.4097 199.627C46.4007 203.88 42.7354 207.658 41.257 212.868C40.3695 215.993 37.9606 218.227 34.3687 217.92C30.2432 217.57 28.9763 214.497 28.6504 210.977C28.4862 209.198 28.7219 207.371 28.753 206.346Z"
          fill="white"
        />
        <path
          d="M229.701 162.221L227.315 151.195C226.748 148.592 224.192 146.952 221.617 147.517L191.228 154.08C188.645 154.639 186.997 157.187 187.557 159.784L189.936 170.81C190.494 173.386 193.059 175.047 195.641 174.482L226.022 167.919C228.605 167.361 230.252 164.79 229.701 162.221Z"
          fill="white"
        />
        <path
          d="M146.563 144.059C145.919 141.105 143.019 139.244 140.098 139.885L74.4707 153.869C71.5406 154.503 69.6704 157.395 70.3048 160.34L71.6072 166.311C72.2403 169.233 75.1499 171.118 78.08 170.477L143.699 156.494C146.629 155.86 148.498 152.943 147.873 150.029L146.563 144.059Z"
          fill="white"
        />
        <path
          d="M76.6907 251.659C84.3265 286.99 119.179 309.445 154.508 301.801C189.855 294.165 212.328 259.327 204.684 223.99C197.046 188.634 162.187 166.188 126.848 173.824C91.5177 181.459 69.0455 216.304 76.6907 251.659Z"
          fill="#1D1D1B"
        />
        <path
          d="M58.8793 255.841C66.5232 291.179 101.375 313.625 136.698 305.999C172.045 298.354 194.509 263.509 186.873 228.179C179.229 192.841 144.385 170.377 109.028 178.014C73.7063 185.649 51.2434 220.51 58.8793 255.841Z"
          fill={primaryActive}
        />
        <path
          d="M72.0777 252.995C78.1449 281.057 105.807 298.867 133.844 292.801C161.914 286.735 179.75 259.095 173.683 231.033C167.617 202.972 139.955 185.162 111.885 191.229C83.8465 197.286 66.0123 224.942 72.0777 252.995Z"
          fill="#1D1D1B"
        />
        <path
          d="M173.629 237.544C173.629 258.289 159.827 275.808 140.902 281.417L138.599 281.104L135.935 282.59C133.32 283.055 130.628 283.297 127.878 283.297C126.368 283.297 124.876 283.224 123.403 283.081L112.631 278.164L101.704 275.074C100.251 274.059 98.8607 272.961 97.5384 271.788L92.6976 262.529L87.7735 259.573C84.178 253.038 82.1323 245.53 82.1323 237.542C82.1323 219.867 92.1547 204.532 106.828 196.914L113.366 196.784L121.744 192.202C123.478 191.969 125.242 191.834 127.032 191.802L138.109 195.893L149.518 197.225C153.233 199.222 156.639 201.718 159.649 204.623L160.67 208.365L163.117 208.363C169.683 216.284 173.629 226.453 173.629 237.544Z"
          fill={primaryHover}
        />
        <path
          d="M106.836 196.926L87.7803 259.572C90.3198 264.187 93.6342 268.311 97.5451 271.78L121.747 192.217C116.432 192.929 111.409 194.552 106.836 196.926Z"
          fill="white"
        />
        <path
          d="M127.879 191.795C127.595 191.795 127.316 191.811 127.033 191.816L101.71 275.063C107.976 279.442 115.383 282.296 123.403 283.075L149.515 197.228C143.074 193.765 135.707 191.795 127.879 191.795Z"
          fill="white"
        />
        <path
          d="M159.643 204.633L135.935 282.576C137.624 282.275 139.281 281.886 140.902 281.406L163.119 208.368C162.032 207.057 160.867 205.815 159.643 204.633Z"
          fill="white"
        />
        <defs>
          <clipPath id="clip0_132_9774">
            <rect
              width="186.813"
              height="186.813"
              fill="white"
              transform="translate(153.187)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default CameraError;
