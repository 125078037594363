import React from 'react';
import { Navigate } from 'react-router-dom';

import { default as defaultTheme } from 'themes/dark';

import {
  getCurrentStage,
  GetCurrentStageResponse,
  initVerification,
} from 'api/verification';
import {
  VerificationStageErrors,
  VerificationStageTypes,
  VerificationStatuses,
} from 'helpers/enums';
import { parseErrorCode } from 'helpers/api';

import { i18nInstance } from 'libs/i18n';
import { auth } from 'services/auth';
import { ENV } from 'env/env.config';

import ResultWithRedirect from 'modules/result';

import InternalError from 'components/internal/InternalError';
import NotFoundBlock from 'components/internal/NotFound';
import Layout from 'components/Layout';
import { mutateDefaultTheme, parseConfigTheme, setTheme } from './theme';

export async function initVerificationMatcher(token?: string) {
  token && auth.set(token);

  try {
    const { data: verification } = await initVerification();

    const localTheme = window.localStorage.getItem('theme');

    const theme = localTheme ? parseConfigTheme(localTheme) : undefined;

    if (theme?.config !== verification.theme?.config) {
      if (verification.theme) {
        window.localStorage.setItem(
          'theme',
          JSON.stringify(verification.theme)
        );

        const mutatedTheme = mutateDefaultTheme(defaultTheme, {
          ...verification.theme.config,
          ...verification.theme.media,
        });

        setTheme && mutatedTheme && setTheme(mutatedTheme);
      } else {
        window.localStorage.removeItem('theme');
      }
    }

    if (
      verification.locale != null &&
      i18nInstance.language !== verification.locale
    ) {
      await i18nInstance.changeLanguage(verification.locale);
    }

    if (verification.status === VerificationStatuses.EXPIRED) {
      return (
        <Layout>
          <ResultWithRedirect expired />
        </Layout>
      );
    }

    if (verification.status > VerificationStatuses.WAITING_USER_INTERACTION) {
      if (verification.returnUrl) {
        window.location.href = verification.returnUrl;
      } else {
        return (
          <Layout>
            <ResultWithRedirect redirect />
          </Layout>
        );
      }
    }

    if (!ENV.IS_MOBILE) {
      return <Navigate to="/request" />;
    }
  } catch {
    return <InternalError />;
  }

  try {
    const { data: stage } = await getCurrentStage();
    switch (stage.type) {
      case VerificationStageTypes.DOCUMENT_UPLOAD:
        if (stage.uploadedDocumentId || stage.primaryDocumentId) {
          const documentId = stage.primaryDocumentId
            ? stage.primaryDocumentId
            : stage.uploadedDocumentId;
          return <Navigate to={`/documents/${documentId}/edit`} />;
        }
        return <Navigate to={`/documents/upload/${stage.country}`} />;
      case VerificationStageTypes.LIVENESS_CHALLENGE:
        return <Navigate to="/liveness" />;
    }
  } catch (e) {
    const errorCode = parseErrorCode(e);

    if (errorCode === VerificationStageErrors.PROVIDER_NOT_INITIALIZED) {
      return <Navigate to="/providers" />;
    } else {
      return <InternalError />;
    }
  }
}

export async function checkVerificationStage(
  requiredStageType?: VerificationStageTypes,
  disableProvidersRedirect?: boolean
) {
  try {
    const { data: verification } = await getCurrentStage();

    if (verification.type === requiredStageType) {
      return {
        stageInfo: verification,
        stageRedirect: undefined,
      };
    }

    switch (verification.type) {
      case VerificationStageTypes.DOCUMENT_UPLOAD:
        return {
          stageInfo: verification,
          stageRedirect: verification.uploadedDocumentId ? (
            <Navigate
              to={`/documents/${verification.uploadedDocumentId}/edit`}
            />
          ) : (
            <Navigate to={`/documents/upload/${verification.country}`} />
          ),
        };
      case VerificationStageTypes.LIVENESS_CHALLENGE:
        return {
          stageInfo: verification,
          stageRedirect: <Navigate to="/liveness" />,
        };
    }
  } catch (e) {
    const errorCode = parseErrorCode(e);

    switch (errorCode) {
      case VerificationStageErrors.VERIFICATION_NOT_INITIALIZED:
        return {
          stageInfo: undefined,
          stageRedirect: <NotFoundBlock />,
        };
      case VerificationStageErrors.USER_INTERACTED_COMPLETED:
        return {
          stageInfo: undefined,
          stageRedirect: <ResultWithRedirect redirect />,
        };
      case VerificationStageErrors.VERIFICATION_EXPIRED:
        return {
          stageInfo: undefined,
          stageRedirect: <ResultWithRedirect expired />,
        };
      case VerificationStageErrors.PROVIDER_NOT_INITIALIZED:
        return {
          stageInfo: undefined,
          stageRedirect: disableProvidersRedirect ? undefined : (
            <Navigate to="/providers" />
          ),
        };
      default:
        return {
          stageInfo: undefined,
          stageRedirect: <InternalError />,
        };
    }
  }
}

export function getLinkToContinue(verification: GetCurrentStageResponse) {
  switch (verification.type) {
    case VerificationStageTypes.DOCUMENT_UPLOAD:
      return verification.uploadedDocumentId
        ? `/documents/${verification.uploadedDocumentId}/edit`
        : '/providers';
    case VerificationStageTypes.LIVENESS_CHALLENGE:
      return '/liveness';
  }
}
