import posthog from 'posthog-js';

if (
  process.env.REACT_APP_POSTHOG_KEY != null &&
  process.env.REACT_APP_POSTHOG_HOST != null
) {
  posthog.init(process.env.REACT_APP_POSTHOG_KEY, {
    api_host: process.env.REACT_APP_POSTHOG_HOST,
    mask_all_text: true,
    mask_all_element_attributes: true,
    session_recording: {
      maskAllInputs: true,
    },
  });
}
