import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

import ScDocumentBlock from './styled';

interface DocumentTypeOption {
  icon: React.ReactNode;
  name: string;
  value: string | number;
}

export interface FormRadioGroupProps {
  registerName: string;
  documents: DocumentTypeOption[];
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const DocumentsRadioGroup: FC<FormRadioGroupProps> = ({
  registerName,
  documents,
  onChange,
}) => {
  const { t } = useTranslation();

  const { register } = useForm();

  return (
    <ScDocumentBlock>
      {documents.map(({ icon, name, value }) => (
        <ScDocumentBlock.Option htmlFor={name} key={value}>
          <input
            {...register(registerName)}
            onChange={onChange}
            type="radio"
            name={registerName}
            value={value}
            className="form-check-input"
            id={name}
          />
          <div className="icon">{icon}</div>
          <div className="name">{t(name)}</div>
        </ScDocumentBlock.Option>
      ))}
    </ScDocumentBlock>
  );
};
