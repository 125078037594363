class AuthService {
  private readonly storage: Storage;
  private readonly keyName: string;

  constructor(storage: Storage, keyName: string) {
    this.storage = storage;
    this.keyName = keyName;
  }

  set = (token: string) => {
    this.storage.setItem(this.keyName, token);
  };

  get = () => this.storage.getItem(this.keyName);
}

export const auth = new AuthService(localStorage, 'VID');
