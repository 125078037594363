import * as React from 'react';
import type { SVGProps } from 'react';
const SvgIntPassportMask = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={297}
      height={213}
      fill="none"
      viewBox="0 0 297 213"
      {...props}
    />
  );
};
export default SvgIntPassportMask;
