import styled, { css } from 'styled-components/macro';
import { device } from 'components/Global/MediaQuery';

export const ScModal = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100dvh;
  position: fixed;
  outline: 0 !important;
  overflow: hidden auto;
  z-index: 1111;
`;

export const ScModalBg = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;

  background: rgba(38, 38, 38, 0.9);
`;

export const ScModalContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100dvh;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box;
  @media (${device.mobileXL}) {
    padding: 0;
  }
`;

export const ScModalContent = styled.div<{ alignTop?: boolean }>`
  position: relative;
  display: inline-block;
  margin: 0 auto;
  text-align: left;
  z-index: 1045;
  width: max-content;
  max-width: 104dvw;
  cursor: auto;
  vertical-align: middle;
  ${({ alignTop }) =>
    alignTop &&
    css`
      @media (${device.mobileXL}) {
        vertical-align: top;
      }
    `}
`;

export const ScModalBlock = styled.div<{ fullOnMobileScreen?: boolean }>`
  position: relative;
  width: max-content;
  max-width: 620px;
  height: auto;
  margin: 0 auto;
  background: ${({ theme }) => theme.base_200};
  box-shadow: 0 4px 48px rgba(0, 0, 0, 0.24);
  border-radius: 12px;
  @media (${device.tablet}) {
    width: calc(100% - 16px);
    margin: 0 8px;
    max-height: 100%;
  }
  @media (${device.mobileXL}) {
    border-radius: ${({ fullOnMobileScreen }) =>
      fullOnMobileScreen ? '0px' : '12px'};
  }
  & > .closeIcon {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
    z-index: 99;
    svg path {
      fill: ${({ theme }) => theme.baseContent};
    }
  }
`;
