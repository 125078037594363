import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Camera } from 'components/Camera';
import { ManualCameraModal } from 'components/form/fileUpload/Camera';
import Icon from 'components/UI/Icon/Icon';
import DocumentPage from 'components/ThemedComponents/Documents/DocumentPage';

import { DocumentCategory } from 'helpers/enums';
import { DocumentType } from 'api/document';

import { useFormData } from '../../context';

import { ScTooltipForCamera } from './styled';

interface ManualPhotoBlockProps {
  type: DocumentType;
  onClose: () => void;
  onNotAvailable: () => void;
  nextStep: () => void;
}

const CAPTURE_OPTIONS: MediaStreamConstraints = {
  audio: false,
  video: {
    width: 3840,
    height: 2160,
    facingMode: 'environment',
  },
};

export const ManualPhotoBlock: FC<ManualPhotoBlockProps> = ({
  type,
  onClose,
  onNotAvailable,
  nextStep,
}) => {
  const {
    data: { documentType, documents },
    setDocumentFile,
    setFormValues,
  } = useFormData();

  const { t } = useTranslation('documents');

  const [pageIndex, setPageIndex] = useState(0);
  const [isSuccess, setIsSuccess] = useState(false);

  const cameraTooltips = useMemo(() => {
    switch (documentType) {
      case DocumentCategory.ID_CARD:
        return {
          frontPage: t('documentsUpload.manualPhoto.frontPage'),
          backPage: t('documentsUpload.manualPhoto.backPage'),
        };
      default:
        return {
          frontPage: t('documentsUpload.manualPhoto.default'),
          backPage: t('documentsUpload.manualPhoto.default'),
        };
    }
  }, [documentType, t]);

  const handleNotAvailable = useCallback(() => {
    onClose();

    onNotAvailable();
  }, [onClose, onNotAvailable]);

  const handleClose = useCallback(() => {
    setFormValues({ documents: {} });
    onClose();
  }, [setFormValues, onClose]);

  const handleSave = useCallback(
    (files: File[]) => {
      setIsSuccess(true);
      files.forEach((page) => {
        setDocumentFile(
          new File([page], `page${pageIndex}.jpg`),
          `page${pageIndex}`,
          true
        );
      });

      const timeout = setTimeout(() => {
        setIsSuccess(false);
      }, 1000);

      return () => {
        clearTimeout(timeout);
      };
    },
    [setDocumentFile, pageIndex]
  );

  useEffect(() => {
    if (documents[`page${type.minPagesCount - 1}`]) {
      nextStep();
    }

    if (documents[`page${pageIndex}`]) {
      setPageIndex((prevState) => prevState + 1);
    }
  }, [documents, pageIndex, type, nextStep]);

  return (
    <ManualCameraModal
      title={
        <TooltipForCamera isSuccess={isSuccess}>
          {pageIndex === 0 || isSuccess
            ? cameraTooltips.frontPage
            : cameraTooltips.backPage}
        </TooltipForCamera>
      }
      onClose={handleClose}
      fullOnMobileScreen
    >
      <Camera
        onNotAvailable={handleNotAvailable}
        captureOptions={CAPTURE_OPTIONS}
        onSave={handleSave}
        withTakePhoto
        fullOnMobileScreen
        applyPhotoWithoutPreview
      />
    </ManualCameraModal>
  );
};

const TooltipForCamera: FC<{
  isSuccess: boolean;
  children: React.ReactNode;
}> = ({ isSuccess, children }) => {
  return (
    <ScTooltipForCamera
      className={classnames('', {
        success: isSuccess,
      })}
    >
      <div className="documentBadge">
        <DocumentPage />
      </div>
      {children}
      <Icon className="successBadge" name="SuccessBadge" />
    </ScTooltipForCamera>
  );
};
