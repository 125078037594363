import styled, { StyledComponentBase } from 'styled-components/macro';
import { device } from 'components/Global/MediaQuery';

interface IScDocumentBlock extends StyledComponentBase<any, {}> {
  Option?: any;
}

const ScDocumentBlock: IScDocumentBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;
  @media (${device.mobileXL}) {
    gap: 16px;
  }
`;

ScDocumentBlock.Option = styled.label`
  padding: 20px 36px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  gap: 16px;
  align-items: center;
  width: 100%;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.base_200};
  border: 1px solid ${({ theme }) => theme.base_300};
  cursor: pointer;
  @media (${device.mobileXL}) {
    padding: 12px 16px;
    gap: 12px;
  }
  &:hover {
    background: ${({ theme }) => theme.base_300};
    border: 1px solid ${({ theme }) => theme.base_500};
    transition: 0.3s;
  }
  &:active {
    background: ${({ theme }) => theme.base_100};
    border: 1px solid ${({ theme }) => theme.base_500};
  }
  &:disabled {
    background: ${({ theme }) => theme.disabled};
    border: 1px solid ${({ theme }) => theme.disabled};
    cursor: not-allowed;
  }
  .icon {
    svg {
      width: 48px;
      height: 48px;
      vertical-align: middle;
      & > path {
        fill: ${({ theme }) => theme.baseContent};
      }
      @media (${device.mobileXL}) {
        width: 28px;
        height: 28px;
      }
    }
  }
  .name {
    font: ${({ theme }) => `700 16px/24px ${theme.mainFontFamilyName}`};
    color: ${({ theme }) => theme.baseContent};
    @media (${device.mobileXL}) {
      font-size: 14px;
    }
  }
  input[type='radio'] {
    display: none;
  }
`;

export default ScDocumentBlock;
