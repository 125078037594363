import React, { FC } from 'react';

import { ScContainer } from 'components/styles/common';
import { SwitchLanguage } from 'components/SwitchLanguage';

import { useTheme } from 'hooks/theme';

import { ScHeader } from './styled';

export const Header: FC = () => {
  const { logo } = useTheme();

  return (
    <ScHeader>
      <ScContainer>
        <div className="header__row">
          {logo && (
            <div className="header__logo">
              <img src={logo} alt="" />
            </div>
          )}
          <div className="header__lang">
            <SwitchLanguage />
          </div>
        </div>
      </ScContainer>
    </ScHeader>
  );
};
