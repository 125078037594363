import { DocumentCategory } from 'helpers/enums';
import { Theme } from 'themes/contracts';

export enum TooltipText {
  prepareDocument = 'documents:documentsUpload.inspectorForm.tooltips.prepareDocument',
  placeDocument = 'documents:documentsUpload.inspectorForm.tooltips.placeDocument',
  rotateDocument = 'documents:documentsUpload.inspectorForm.tooltips.rotateDocument',
  turnDocument = 'documents:documentsUpload.inspectorForm.tooltips.turnDocument',
  completed = 'documents:documentsUpload.inspectorForm.tooltips.completed',
}

export const CAPTURE_OPTIONS: MediaStreamConstraints = {
  audio: false,
  video: {
    width: 1920,
    height: 1080,
    facingMode: 'environment',
  },
};

export const DOCUMENT_INSPECTION_MAX_SIZE = 224;
export const DOCUMENT_INSPECTION_THROTTLE = 100;
export const DOCUMENT_INSPECTION_TIMEOUT = 60000;

interface NextPageAnimation {
  [country: string]: {
    [type in DocumentCategory]?: object;
  };
}
export const getNextPageAnimationsList = (theme: Theme): NextPageAnimation => {
  return {
    ua: {
      [DocumentCategory.PASSPORT]: theme.animationUkrainianPassport,
    },
  };
};

interface NextPageActionTooltip {
  [country: string]: {
    [type in DocumentCategory]?: TooltipText;
  };
}

export const nextPageActionTooltips: NextPageActionTooltip = {
  ua: {
    [DocumentCategory.PASSPORT]: TooltipText.turnDocument,
  },
};
