import * as React from 'react';
import type { SVGProps } from 'react';
const SvgCheck = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18}
      height={14}
      fill="none"
      viewBox="0 0 18 14"
      {...props}
    >
      <path
        fill="#3B3B3B"
        d="M18 2 6 14 .5 8.5l1.41-1.41L6 11.17 16.59.59 18 2Z"
      />
    </svg>
  );
};
export default SvgCheck;
