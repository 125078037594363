import React from 'react';

import { useTheme } from 'hooks/theme';

const MultipleFacesError = () => {
  const { primary, primaryHover, error } = useTheme();
  return (
    <div style={{ maxWidth: 310, width: '100%', height: 'auto' }}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 310 326"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_9_274)">
          <path
            d="M270.288 20.1301L141.74 36.2861L157.331 160.337L285.879 144.181L270.288 20.1301Z"
            fill="black"
          />
          <path
            d="M275.48 12.8216L146.932 28.9775L162.522 153.029L291.07 136.873L275.48 12.8216Z"
            fill={primary}
          />
          <path
            opacity="0.4"
            d="M257.221 0.426804L249.173 6.9365L255.682 14.9833L263.73 8.4735L257.221 0.426804Z"
            fill={primary}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M244.37 169.202L244.084 174.481L239.654 171.595L235.225 168.708L239.94 166.316L244.655 163.924L244.37 169.202Z"
            fill={primary}
          />
          <path
            opacity="0.2"
            d="M232.565 7.8371L210.107 13.1885L211.07 17.2309L233.528 11.8795L232.565 7.8371Z"
            fill={primary}
          />
          <path
            d="M130.731 93.495C133.417 93.495 135.595 91.318 135.595 88.631C135.595 85.9445 133.417 83.7666 130.731 83.7666C128.044 83.7666 125.866 85.9445 125.866 88.631C125.866 91.318 128.044 93.495 130.731 93.495Z"
            fill={primary}
          />
          <path
            opacity="0.2"
            d="M306.292 91.661C307.965 91.661 309.322 90.305 309.322 88.6314C309.322 86.9581 307.965 85.6016 306.292 85.6016C304.618 85.6016 303.262 86.9581 303.262 88.6314C303.262 90.305 304.618 91.661 306.292 91.661Z"
            fill={primary}
          />
          <path
            opacity="0.4"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M197.137 171.87C197.112 173.173 196.894 174.41 197.165 175.813C199.407 175.675 200.579 175.959 202.554 174.829C203.956 174.028 205.001 172.808 205.984 171.577C207.224 170.022 207.902 168.267 210.089 167.511C212.154 166.798 214.308 167.843 216.536 166.965C219.387 165.841 220.81 163.417 222.092 161.464C222.723 160.5 223.053 159.57 224.7 158.976C225.846 158.563 227.2 158.838 228.547 158.755C231.94 158.545 234.719 157.183 236.023 153.984C235.003 153.361 233.861 153.096 232.809 152.574C230.147 156.535 225.854 153.463 221.685 156.029C218.629 157.909 218.097 162.098 215.062 163.256C213.927 163.69 212.411 163.336 211.409 163.383C210.171 163.44 208.844 163.563 207.607 164.181C204.394 165.787 203.361 170.227 200.217 171.548C199.17 171.988 198.352 171.72 197.137 171.87Z"
            fill={primary}
          />
          <path
            d="M163.333 135.6C164.031 137.933 165.193 139.661 167.016 140.918C168.717 142.091 170.343 142.081 171.357 140.982C172.388 139.864 172.201 138.406 170.709 136.846C169.469 135.549 168.03 134.447 167.263 132.746C166.803 131.727 165.869 131.087 164.661 131.373C163.274 131.701 162.997 132.812 163.064 134.027C163.097 134.642 163.27 135.252 163.333 135.6Z"
            fill="white"
          />
          <path
            d="M173.178 144.345C173.661 145.464 175.648 146.172 176.869 145.698C177.795 145.339 178.125 144.641 177.747 143.719C177.285 142.592 175.343 141.922 174.126 142.441C173.235 142.821 172.889 143.479 173.178 144.345Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M271.172 25.3068C275.398 25.8763 273.949 19.2036 270.985 20.5175C269.441 21.2009 269.528 25.0853 271.172 25.3068Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M273.373 28.871C273.076 28.3328 271.716 27.6392 270.628 28.4339C269.213 29.473 269.235 38.2178 271.089 39.0501C273.733 40.2348 275.74 33.1724 273.373 28.871Z"
            fill="white"
          />
          <path
            d="M267.489 83.8714C267.779 57.6773 246.78 36.2071 220.586 35.9163C194.392 35.6255 172.921 56.6243 172.631 82.8184C172.34 109.013 193.339 130.483 219.533 130.774C245.727 131.064 267.198 110.066 267.489 83.8714Z"
            fill="white"
          />
          <path
            d="M221.272 123.807C243.618 123.145 261.196 104.494 260.534 82.1487C259.873 59.8033 241.222 42.2252 218.876 42.8869C196.531 43.5486 178.953 62.1995 179.614 84.5448C180.276 106.89 198.927 124.468 221.272 123.807Z"
            fill={error}
          />
          <path
            d="M239.301 64.1128C237.942 62.7541 235.741 62.7541 234.382 64.1128L220.068 78.427L205.754 64.1128C204.395 62.7541 202.194 62.7541 200.835 64.1128C199.476 65.4712 199.476 67.6736 200.835 69.032L215.149 83.3462L200.835 97.66C199.476 99.019 199.476 101.221 200.835 102.58C201.514 103.259 202.405 103.599 203.295 103.599C204.185 103.599 205.075 103.259 205.754 102.58L220.068 88.2657L234.382 102.58C235.061 103.259 235.951 103.599 236.841 103.599C237.732 103.599 238.622 103.259 239.301 102.58C240.66 101.221 240.66 99.019 239.301 97.66L224.987 83.3462L239.301 69.032C240.66 67.6736 240.66 65.4712 239.301 64.1128Z"
            fill="white"
          />
          <path
            d="M187.857 114.594C192.423 110.028 192.423 102.626 187.857 98.06C183.291 93.494 175.889 93.494 171.323 98.06C166.757 102.626 166.757 110.028 171.323 114.594C175.889 119.16 183.291 119.16 187.857 114.594Z"
            fill="white"
          />
          <path
            d="M184.638 112.206C184.028 112.206 183.533 111.711 183.533 111.101C183.533 108.885 181.73 107.081 179.514 107.081C177.297 107.081 175.494 108.884 175.494 111.101C175.494 111.711 174.999 112.206 174.389 112.206C173.779 112.206 173.284 111.711 173.284 111.101C173.284 107.666 176.078 104.871 179.514 104.871C182.949 104.871 185.744 107.666 185.744 111.101C185.744 111.712 185.248 112.206 184.638 112.206Z"
            fill={primary}
          />
          <path
            d="M174.524 104.423C175.339 104.423 176 103.762 176 102.947C176 102.132 175.339 101.471 174.524 101.471C173.709 101.471 173.048 102.132 173.048 102.947C173.048 103.762 173.709 104.423 174.524 104.423Z"
            fill={primary}
          />
          <path
            d="M184.597 104.423C185.413 104.423 186.074 103.762 186.074 102.947C186.074 102.132 185.413 101.471 184.597 101.471C183.782 101.471 183.121 102.132 183.121 102.947C183.121 103.762 183.782 104.423 184.597 104.423Z"
            fill={primary}
          />
          <path
            d="M191.3 116.033C152.012 116.033 120.163 147.882 120.163 187.17V265.681H262.436V187.17C262.436 147.882 230.587 116.033 191.3 116.033Z"
            fill="white"
          />
          <path
            d="M238.049 244.599H211.964C237.301 235.953 255.532 211.96 255.532 183.696C255.532 148.153 226.719 119.34 191.176 119.34C155.634 119.34 126.82 148.153 126.82 183.696C126.82 211.961 145.051 235.953 170.388 244.599H144.057C120.702 244.599 101.769 263.532 101.769 286.887V324.489L143.884 315.293L188.117 324.489H280.337V286.887C280.337 263.532 261.404 244.599 238.049 244.599Z"
            fill="white"
          />
          <path
            opacity="0.8"
            d="M238.049 244.599H211.964C237.301 235.953 255.532 211.96 255.532 183.696C255.532 148.153 226.719 119.34 191.176 119.34C155.634 119.34 126.82 148.153 126.82 183.696C126.82 211.961 145.051 235.953 170.388 244.599H144.057C120.702 244.599 101.769 263.532 101.769 286.887V324.489L143.884 315.293L188.117 324.489H280.337V286.887C280.337 263.532 261.404 244.599 238.049 244.599Z"
            fill={primaryHover}
          />
          <path
            d="M191.288 117.356C154.819 117.356 125.256 146.92 125.256 183.389V206.218C160.023 197.741 187.205 169.919 194.737 134.787C199.701 160.774 222.535 180.418 249.968 180.418C252.43 180.418 256.75 179.575 259.129 179.268C257.333 144.405 226.599 117.356 191.288 117.356Z"
            fill="white"
          />
          <path
            d="M206.934 190.93C206.974 193.271 208.905 195.139 211.249 195.098C213.591 195.06 215.46 193.128 215.42 190.783C215.379 188.441 213.447 186.573 211.104 186.614C208.761 186.652 206.895 188.588 206.934 190.93Z"
            fill="white"
          />
          <path
            d="M179.5 190.93C179.461 193.271 177.53 195.139 175.186 195.098C172.843 195.06 170.976 193.128 171.015 190.783C171.056 188.441 172.988 186.573 175.331 186.614C177.674 186.652 179.54 188.588 179.5 190.93Z"
            fill="white"
          />
          <path
            d="M203.128 204.651C202.038 204.561 201.083 205.37 200.992 206.46C200.588 211.347 196.785 214.292 193.218 214.292C189.651 214.292 185.848 211.347 185.443 206.46C185.352 205.37 184.396 204.561 183.307 204.651C182.212 204.732 181.409 205.699 181.498 206.787C182.093 213.948 186.805 218.25 193.218 218.25C199.89 218.25 204.343 213.948 204.937 206.787C205.027 205.699 204.223 204.732 203.128 204.651Z"
            fill="white"
          />
          <path
            d="M272.264 271.725C271.421 267.702 269.713 264.628 266.825 262.254C264.13 260.038 261.392 259.825 259.532 261.529C257.638 263.263 257.745 265.742 260.033 268.579C261.934 270.937 264.199 272.996 265.247 275.966C265.876 277.747 267.356 278.955 269.428 278.646C271.81 278.293 272.433 276.463 272.493 274.408C272.524 273.371 272.32 272.319 272.264 271.725Z"
            fill="white"
          />
          <path
            d="M256.947 255.613C256.293 253.663 253.052 252.188 250.93 252.812C249.321 253.285 248.666 254.412 249.171 256.015C249.789 257.977 252.961 259.381 255.081 258.681C256.635 258.168 257.31 257.113 256.947 255.613Z"
            fill="white"
          />
          <path
            d="M146.192 240.244H118.38C145.394 231.025 164.831 205.445 164.831 175.309C164.831 174.021 164.796 172.741 164.726 171.47L99.798 116.085L31.3503 197.734C38.2459 217.667 54.0768 233.428 74.051 240.244H45.9767C21.0758 240.244 0.890106 260.43 0.890106 285.331V325.422H191.279V285.331C191.278 260.43 171.093 240.244 146.192 240.244Z"
            fill="white"
          />
          <path
            opacity="0.76"
            d="M146.192 240.244H118.38C145.394 231.025 164.831 205.445 164.831 175.309C164.831 174.021 164.796 172.741 164.726 171.47L99.798 116.085L31.3503 197.734C38.2459 217.667 54.0768 233.428 74.051 240.244H45.9767C21.0758 240.244 0.890106 260.43 0.890106 285.331V325.422H191.279V285.331C191.278 260.43 171.093 240.244 146.192 240.244Z"
            fill={primary}
          />
          <path
            d="M109.667 185.735C109.707 188.076 111.638 189.944 113.982 189.903C116.325 189.865 118.193 187.933 118.153 185.588C118.112 183.246 116.18 181.378 113.837 181.419C111.494 181.457 109.628 183.393 109.667 185.735Z"
            fill="white"
          />
          <path
            d="M82.234 185.735C82.194 188.076 80.263 189.944 77.919 189.903C75.577 189.865 73.709 187.933 73.748 185.588C73.789 183.246 75.721 181.378 78.064 181.419C80.407 181.457 82.273 183.393 82.234 185.735Z"
            fill="white"
          />
          <path
            d="M105.861 199.455C104.772 199.364 103.816 200.174 103.725 201.263C103.321 206.151 99.518 209.096 95.951 209.096C92.384 209.096 88.581 206.151 88.176 201.263C88.085 200.174 87.129 199.364 86.04 199.455C84.945 199.535 84.142 200.502 84.231 201.591C84.826 208.752 89.538 213.054 95.951 213.054C102.623 213.054 107.076 208.752 107.67 201.591C107.76 200.503 106.956 199.535 105.861 199.455Z"
            fill="white"
          />
          <path
            d="M99.809 123.164C105.103 150.871 129.447 171.815 158.696 171.815C160.734 171.815 162.736 171.674 164.72 171.471C162.728 135.362 132.824 106.692 96.216 106.692C58.321 106.692 27.6003 137.413 27.6003 175.308C27.6003 183.165 28.9487 190.7 31.3809 197.728C65.7144 187.172 92.193 158.69 99.809 123.164Z"
            fill="white"
          />
          <path
            opacity="0.2"
            d="M99.809 123.164C105.103 150.871 129.447 171.815 158.696 171.815C160.734 171.815 162.736 171.674 164.72 171.471C162.728 135.362 132.824 106.692 96.216 106.692C58.321 106.692 27.6003 137.413 27.6003 175.308C27.6003 183.165 28.9487 190.7 31.3809 197.728C65.7144 187.172 92.193 158.69 99.809 123.164Z"
            fill={primary}
          />
          <path
            d="M43.6852 144.519C44.5848 140.229 46.4058 136.952 49.4843 134.42C52.3581 132.058 55.2769 131.83 57.2603 133.647C59.2795 135.496 59.1655 138.14 56.7266 141.165C54.6995 143.679 52.2845 145.874 51.1675 149.04C50.4966 150.939 48.9195 152.228 46.7094 151.898C44.1704 151.52 43.5062 149.571 43.4419 147.38C43.4087 146.273 43.6262 145.152 43.6852 144.519Z"
            fill="white"
          />
          <path
            d="M60.018 127.34C60.7153 125.261 64.1718 123.689 66.4343 124.354C68.1486 124.858 68.8473 126.06 68.309 127.769C67.6507 129.861 64.2692 131.357 62.008 130.611C60.3514 130.065 59.6321 128.939 60.018 127.34Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_9_274">
            <rect width="310" height="326" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default MultipleFacesError;
