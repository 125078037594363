import { useEffect } from 'react';

export function usePreventScroll(element: HTMLElement) {
  useEffect(() => {
    element.style.overflow = 'hidden';

    return () => {
      element.style.overflow = 'unset';
    };
  }, [element.style]);
}
