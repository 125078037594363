import React, { FC } from 'react';

import { Modal, ModalBlock, ModalBlockProps } from 'components/Modal';
import { ScCameraModalBlock, ScManualCameraModalBlock } from './styled';
import { Camera, CameraProps } from 'components/Camera';
import { showTooltip } from 'helpers/tooltip';
import { TooltipTypes } from 'modules/globalTooltip';
import { translationFactory } from 'helpers/trans';

type CameraModalProps = ModalBlockProps &
  Pick<CameraProps, 'onSave'> & { title: string };

export const CameraModal: FC<CameraModalProps> = ({
  title,
  onSave,
  ...props
}) => {
  const handleNotAvailable = () => {
    props.onClose();

    showTooltip({
      type: TooltipTypes.SUCCESS,
      translate: true,
      body: translationFactory(
        'common:fields.uploadFile.errors.webcamNotAvailable'
      ),
    });
  };

  return (
    <Modal onClose={props.onClose}>
      {(propsModal) => (
        <ModalBlock {...props} {...propsModal}>
          <ScCameraModalBlock>
            <div className="title">{title}</div>
            <div className="camera">
              <Camera
                withTakePhoto
                onSave={onSave}
                captureOptions={{
                  video: {
                    height: {
                      ideal: 300,
                    },
                    width: {
                      ideal: undefined,
                    },
                    facingMode: 'environment',
                  },
                }}
                onNotAvailable={handleNotAvailable}
              />
            </div>
          </ScCameraModalBlock>
        </ModalBlock>
      )}
    </Modal>
  );
};

type ManualCameraModalProps = ModalBlockProps & {
  title: string | JSX.Element;
  fullOnMobileScreen?: boolean;
};

export const ManualCameraModal: FC<ManualCameraModalProps> = ({
  title,
  fullOnMobileScreen,
  children,
  ...props
}) => {
  return (
    <Modal alignTop={fullOnMobileScreen}>
      {(propsModal) => (
        <ModalBlock
          fullOnMobileScreen={fullOnMobileScreen}
          {...props}
          {...propsModal}
        >
          <ScManualCameraModalBlock fullOnMobileScreen={fullOnMobileScreen}>
            <h5>{title}</h5>
            <div className="camera">{children}</div>
          </ScManualCameraModalBlock>
        </ModalBlock>
      )}
    </Modal>
  );
};
