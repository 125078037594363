import styled from 'styled-components/macro';
import { device } from 'components/Global/MediaQuery';

export const ScHeader = styled.div`
  padding: 30px 0 0;
  .header {
    &__row {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      gap: 8px;
    }
    &__logo {
      display: flex;
      height: 36px;
      width: auto;
      @media (${device.tablet}) {
        height: 24px;
        max-width: 180px;
      }
    }
  }
`;
