import React, { FC } from 'react';
import { ScLoader } from './styled';
import { CSSProperties } from 'styled-components';

interface LoaderProps {
  relative?: boolean;
  small?: boolean;
  bgColor?: string;
  borderRadius?: string;
  style?: CSSProperties;
}

export const Loader: FC<LoaderProps> = (props) => (
  <ScLoader {...props}>
    <div className="loadingio-spinner-ellipsis-841072ms0ro">
      <div className="ldio-4uxzczc88hy">
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  </ScLoader>
);
