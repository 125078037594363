import React, { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Content } from 'components/Content';
import { DocumentErrorCode } from 'helpers/enums';
import { Button } from 'components/Button';
import { Icon } from 'components/UI/Icon';
import { RedirectModal } from 'components/UI/RedirectBlock';
import { BlurredError, DefaultError } from 'components/ThemedComponents/Errors';

import { ENV } from 'env/env.config';

import { ScResult } from './styled';

interface ResultErrorScreenProps {
  errorCode?: DocumentErrorCode | null;
  documentId?: number;
  textPrimaryButton?: string;
  onClickPrimaryButton?: () => void;
  textSecondaryButton?: string;
  onClickSecondaryButton?: () => void;
}

export const ResultErrorScreen: FC<ResultErrorScreenProps> = ({
  errorCode,
  documentId,
  textPrimaryButton,
  onClickPrimaryButton,
  textSecondaryButton,
  onClickSecondaryButton,
}) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [showRedirectModal, setShowRedirectModal] = useState(false);

  const toggleRedirectModal = () =>
    setShowRedirectModal((prevState) => !prevState);

  const handleCallApproveDocument = useCallback(async () => {
    if (documentId) {
      navigate({
        pathname: `/documents/${documentId}/edit`,
        search: 'isForced=true',
      });
    }
  }, [documentId, navigate]);

  const { image, title, text, desktopText } = useMemo(() => {
    switch (errorCode) {
      case DocumentErrorCode.INVALID_PAGES_COUNT:
        return {
          image: <DefaultError />,
          title: t('documents:documentsUpload.errors.InvalidPagesCount.title'),
          desktopText: t(
            'documents:documentsUpload.errors.InvalidPagesCount.desktopText'
          ),
          text: t('documents:documentsUpload.errors.InvalidPagesCount.text'),
        };
      case DocumentErrorCode.INVALID_FILES:
        return {
          image: <DefaultError />,
          title: t('documents:documentsUpload.errors.InvalidFiles.title'),
          desktopText: t(
            'documents:documentsUpload.errors.InvalidFiles.desktopText'
          ),
          text: t('documents:documentsUpload.errors.InvalidFiles.text'),
        };
      case DocumentErrorCode.INVALID_DOCUMENT_TYPE:
        return {
          image: <DefaultError />,
          title: t(
            'documents:documentsUpload.errors.InvalidDocumentType.title'
          ),
          desktopText: t(
            'documents:documentsUpload.errors.InvalidDocumentType.desktopText'
          ),
          text: t('documents:documentsUpload.errors.InvalidDocumentType.text'),
        };
      case DocumentErrorCode.PRIMARY_DOCUMENT_EXISTS:
        return {
          image: <DefaultError />,
          title: t(
            'documents:documentsUpload.errors.primaryAlreadyExists.title'
          ),
          desktopText: t(
            'documents:documentsUpload.errors.primaryAlreadyExists.desktopText'
          ),
          text: t('documents:documentsUpload.errors.primaryAlreadyExists.text'),
        };
      case DocumentErrorCode.UPLOAD_ATTEMPTS_EXCEEDED:
        return {
          image: <DefaultError />,
          title: t(
            'documents:documentsUpload.errors.uploadAttemptsExceeded.title'
          ),
          desktopText: t(
            'documents:documentsUpload.errors.uploadAttemptsExceeded.desktopText'
          ),
          text: t(
            'documents:documentsUpload.errors.uploadAttemptsExceeded.text'
          ),
        };
      case DocumentErrorCode.INVALID_PAGE:
        return {
          image: <DefaultError />,
          title: t('documents:documentsUpload.errors.invalidPage.title'),
          desktopText: t(
            'documents:documentsUpload.errors.invalidPage.desktopText'
          ),
          text: t('documents:documentsUpload.errors.invalidPage.text'),
        };
      case DocumentErrorCode.FRAUD:
        return {
          image: <DefaultError />,
          title: t('documents:documentsUpload.errors.fraud.title'),
          desktopText: t('documents:documentsUpload.errors.fraud.desktopText'),
          text: t('documents:documentsUpload.errors.fraud.text'),
        };
      case DocumentErrorCode.BLUR:
        return {
          image: <BlurredError />,
          title: t('documents:documentsUpload.errors.blur.title'),
          desktopText: t('documents:documentsUpload.errors.blur.desktopText'),
          text: t('documents:documentsUpload.errors.blur.text'),
        };
      case DocumentErrorCode.PRINT:
        return {
          image: <DefaultError />,
          title: t('documents:documentsUpload.errors.print.title'),
          desktopText: t('documents:documentsUpload.errors.print.desktopText'),
          text: t('documents:documentsUpload.errors.print.text'),
        };
      default:
        return {
          image: <DefaultError />,
          title: t('documents:documentsUpload.errors.default.title'),
          desktopText: t(
            'documents:documentsUpload.errors.default.desktopText'
          ),
          text: t('documents:documentsUpload.errors.default.text'),
        };
    }
  }, [t, errorCode]);

  const button = useMemo(() => {
    return onClickPrimaryButton || onClickSecondaryButton ? (
      <>
        {onClickPrimaryButton && (
          <Button transparent onClick={onClickPrimaryButton}>
            {textPrimaryButton || t('common:tryAgain')}
          </Button>
        )}
        {onClickSecondaryButton && (
          <Button onClick={onClickSecondaryButton}>
            {textSecondaryButton || t('common:tryAgain')}
          </Button>
        )}
      </>
    ) : null;
  }, [
    t,
    onClickPrimaryButton,
    onClickSecondaryButton,
    textPrimaryButton,
    textSecondaryButton,
  ]);

  const buttonApprove = useMemo(
    () =>
      documentId ? (
        <Button asLink autoWidth onClick={handleCallApproveDocument}>
          {t('documents:documentsUpload.approveDocument')}
        </Button>
      ) : null,
    [documentId, handleCallApproveDocument, t]
  );

  return (
    <Content>
      <ScResult>
        {image}
        <h1>{title}</h1>
        <div className="text">
          <p>{ENV.IS_MOBILE ? text : desktopText}</p>
        </div>

        {button ? <div className="button-block">{button}</div> : null}

        {!ENV.IS_MOBILE && (
          <div className="redirectLink">
            <Button
              onClick={toggleRedirectModal}
              asLink
              autoWidth
              icon={<Icon name="PhoneBadge" />}
            >
              {t('common:request.continueOnMobile')}
            </Button>
          </div>
        )}

        {buttonApprove && <div className="btn-approve">{buttonApprove}</div>}
      </ScResult>

      {showRedirectModal && <RedirectModal onClose={toggleRedirectModal} />}
    </Content>
  );
};
