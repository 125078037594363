import React from 'react';
import { Params } from 'react-router-dom';

import {
  documentTypes as getDocumentTypes,
  getDocumentInfo,
} from 'api/document';

import { checkVerificationStage } from 'helpers/routes';
import { VerificationStageTypes } from 'helpers/enums';
import { titleGetter } from 'helpers/trans';

import InternalError from 'components/internal/InternalError';
import NotFoundBlock from 'components/internal/NotFound';
import { DocumentTitle } from 'components/DocumentTitle';

import DocumentsUpload from './components/pages/DocumentUpload';
import DocumentEditForm from './components/pages/DocumentEdit';

const documentsRoute = [
  {
    path: 'upload/:country',
    element: (
      <DocumentTitle
        getTitle={titleGetter('common:documentTitles.countrySelect')}
      >
        <DocumentsUpload />
      </DocumentTitle>
    ),
    errorElement: <InternalError />,
    loader: async ({ params }: { params: Params<string> }) => ({
      documentTypes: await getDocumentTypes({
        country: params.country as string,
      }).then((r) => r.data),
      currentStage: await checkVerificationStage(
        VerificationStageTypes.DOCUMENT_UPLOAD
      ),
    }),
  },
  {
    path: ':documentId/edit',
    element: (
      <DocumentTitle
        getTitle={titleGetter('common:documentTitles.documentEdit')}
      >
        <DocumentEditForm />
      </DocumentTitle>
    ),
    errorElement: <InternalError />,
    loader: async ({ params: { documentId } }: { params: Params<string> }) => ({
      document: await getDocumentInfo(Number(documentId)).then((r) => r.data),
      currentStage: await checkVerificationStage(
        VerificationStageTypes.DOCUMENT_UPLOAD
      ),
    }),
  },
  {
    path: '*',
    element: <NotFoundBlock />,
  },
];

export default documentsRoute;
