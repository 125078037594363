import React from 'react';
import { useTheme } from 'hooks/theme';

const FaceNotFoundError = () => {
  const { primary, error } = useTheme();
  return (
    <div style={{ maxWidth: 340, width: '100%', height: 'auto' }}>
      <svg
        width="100%"
        height="auto"
        viewBox="0 0 340 340"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_8_230)">
          <path
            d="M298.594 31.6612L167.852 48.0928L183.709 174.26L314.45 157.828L298.594 31.6612Z"
            fill="black"
          />
          <path
            d="M303.873 24.2282L173.132 40.6597L188.989 166.827L319.73 150.395L303.873 24.2282Z"
            fill={primary}
          />
          <path
            opacity="0.4"
            d="M285.303 11.6224L277.118 18.2432L283.738 26.4272L291.923 19.8065L285.303 11.6224Z"
            fill={primary}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M272.233 183.277L271.943 188.646L267.437 185.71L262.933 182.774L267.727 180.341L272.523 177.908L272.233 183.277Z"
            fill={primary}
          />
          <path
            opacity="0.2"
            d="M260.227 19.1573L237.385 24.6L238.365 28.7115L261.207 23.2688L260.227 19.1573Z"
            fill={primary}
          />
          <path
            d="M156.655 106.278C159.388 106.278 161.603 104.063 161.603 101.331C161.603 98.5985 159.388 96.3834 156.655 96.3834C153.923 96.3834 151.708 98.5985 151.708 101.331C151.708 104.063 153.923 106.278 156.655 106.278Z"
            fill={primary}
          />
          <path
            opacity="0.2"
            d="M335.211 104.412C336.913 104.412 338.293 103.033 338.293 101.331C338.293 99.629 336.913 98.2493 335.211 98.2493C333.509 98.2493 332.13 99.629 332.13 101.331C332.13 103.033 333.509 104.412 335.211 104.412Z"
            fill={primary}
          />
          <path
            opacity="0.4"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M224.195 185.99C224.169 187.314 223.947 188.573 224.223 190C226.504 189.859 227.695 190.149 229.704 188.999C231.129 188.184 232.193 186.944 233.193 185.691C234.454 184.11 235.143 182.325 237.368 181.556C239.468 180.831 241.658 181.894 243.924 181.001C246.824 179.858 248.271 177.392 249.575 175.406C250.217 174.426 250.553 173.48 252.228 172.876C253.393 172.456 254.77 172.735 256.141 172.651C259.592 172.437 262.418 171.052 263.744 167.798C262.706 167.165 261.545 166.895 260.475 166.365C257.767 170.392 253.401 167.268 249.161 169.878C246.053 171.79 245.512 176.051 242.425 177.229C241.271 177.67 239.729 177.31 238.71 177.358C237.451 177.416 236.101 177.541 234.843 178.17C231.576 179.803 230.525 184.319 227.327 185.662C226.263 186.11 225.43 185.837 224.195 185.99Z"
            fill={primary}
          />
          <path
            d="M189.812 149.101C190.523 151.473 191.704 153.231 193.558 154.51C195.288 155.703 196.942 155.692 197.973 154.575C199.022 153.437 198.832 151.954 197.315 150.367C196.054 149.049 194.589 147.928 193.81 146.198C193.342 145.161 192.392 144.511 191.163 144.801C189.752 145.135 189.471 146.265 189.539 147.501C189.573 148.126 189.749 148.746 189.812 149.101Z"
            fill="white"
          />
          <path
            d="M199.825 157.995C200.317 159.133 202.337 159.853 203.58 159.371C204.521 159.005 204.857 158.295 204.473 157.358C204.003 156.212 202.027 155.53 200.79 156.059C199.883 156.445 199.532 157.114 199.825 157.995Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M299.491 36.9251C303.79 37.5043 302.317 30.7178 299.301 32.0541C297.731 32.7491 297.819 36.6998 299.491 36.9251Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M301.73 40.5507C301.429 40.0034 300.045 39.2979 298.939 40.1061C297.499 41.163 297.522 50.057 299.407 50.9035C302.096 52.1084 304.137 44.9255 301.73 40.5507Z"
            fill="white"
          />
          <path
            d="M295.745 96.4901C296.04 69.8492 274.683 48.0126 248.042 47.7169C221.401 47.4211 199.564 68.7782 199.268 95.4191C198.973 122.06 220.33 143.897 246.971 144.192C273.612 144.488 295.449 123.131 295.745 96.4901Z"
            fill="white"
          />
          <path
            d="M248.74 137.106C271.467 136.433 289.345 117.464 288.672 94.7374C287.999 72.0109 269.03 54.1329 246.303 54.8059C223.577 55.4789 205.699 74.4479 206.372 97.1745C207.045 119.901 226.014 137.779 248.74 137.106Z"
            fill={error}
          />
          <path
            d="M267.076 76.3937C265.694 75.0118 263.455 75.0118 262.073 76.3937L247.515 90.9521L232.957 76.3937C231.575 75.0118 229.336 75.0118 227.954 76.3937C226.572 77.7753 226.572 80.0152 227.954 81.3968L242.512 95.9552L227.954 110.514C226.572 111.895 226.572 114.135 227.954 115.517C228.645 116.208 229.55 116.553 230.455 116.553C231.361 116.553 232.266 116.208 232.957 115.517L247.515 100.959L262.073 115.517C262.764 116.208 263.669 116.553 264.575 116.553C265.48 116.553 266.385 116.208 267.076 115.517C268.458 114.135 268.458 111.895 267.076 110.514L252.518 95.9552L267.076 81.3968C268.458 80.0152 268.458 77.7753 267.076 76.3937Z"
            fill="white"
          />
          <path
            d="M214.754 127.736C219.398 123.092 219.398 115.563 214.754 110.92C210.111 106.276 202.582 106.276 197.938 110.92C193.294 115.563 193.294 123.092 197.938 127.736C202.582 132.38 210.111 132.38 214.754 127.736Z"
            fill="white"
          />
          <path
            d="M211.481 125.307C210.86 125.307 210.357 124.803 210.357 124.182C210.357 121.928 208.523 120.094 206.269 120.094C204.014 120.094 202.181 121.928 202.181 124.182C202.181 124.803 201.677 125.307 201.057 125.307C200.436 125.307 199.933 124.803 199.933 124.182C199.933 120.689 202.774 117.846 206.269 117.846C209.763 117.846 212.605 120.689 212.605 124.182C212.605 124.804 212.101 125.307 211.481 125.307Z"
            fill={primary}
          />
          <path
            d="M201.193 117.391C202.023 117.391 202.695 116.718 202.695 115.889C202.695 115.06 202.023 114.388 201.193 114.388C200.364 114.388 199.692 115.06 199.692 115.889C199.692 116.718 200.364 117.391 201.193 117.391Z"
            fill={primary}
          />
          <path
            d="M211.439 117.391C212.268 117.391 212.94 116.718 212.94 115.889C212.94 115.06 212.268 114.388 211.439 114.388C210.61 114.388 209.938 115.06 209.938 115.889C209.938 116.718 210.61 117.391 211.439 117.391Z"
            fill={primary}
          />
        </g>
        <path
          d="M196.823 238.979H167.048C195.969 229.109 216.778 201.723 216.778 169.46C216.778 168.081 216.74 166.711 216.665 165.35L147.154 106.056L73.8749 193.468C81.2572 214.808 98.2057 231.681 119.59 238.979H89.5338C62.8752 238.979 41.2646 260.59 41.2646 287.248V330.17H245.093V287.248C245.092 260.59 223.481 238.979 196.823 238.979Z"
          fill="white"
        />
        <path
          opacity="0.76"
          d="M196.823 238.979H167.048C195.969 229.109 216.778 201.723 216.778 169.46C216.778 168.081 216.74 166.711 216.665 165.35L147.154 106.056L73.8749 193.468C81.2572 214.808 98.2057 231.681 119.59 238.979H89.5338C62.8752 238.979 41.2646 260.59 41.2646 287.248V330.17H245.093V287.248C245.092 260.59 223.481 238.979 196.823 238.979Z"
          fill={primary}
        />
        <path
          d="M145.5 222C149.09 222 152 218.866 152 215C152 211.134 149.09 208 145.5 208C141.91 208 139 211.134 139 215C139 218.866 141.91 222 145.5 222Z"
          fill="white"
        />
        <path
          d="M144.217 204C144.161 204 144.106 203.999 144.05 203.998C140.475 203.908 137.65 200.933 137.741 197.354C137.747 197.12 137.887 191.602 137.997 189.65C138.52 180.381 143.909 175.865 147.843 172.568C151.594 169.425 153.857 167.528 154.044 161.879C154.137 159.059 153.128 156.371 151.201 154.31C149.274 152.249 146.662 151.063 143.844 150.97C138.028 150.784 133.138 155.358 132.946 161.182C132.828 164.759 129.859 167.572 126.261 167.446C122.687 167.327 119.885 164.331 120.004 160.753C120.432 147.784 131.347 137.594 144.273 138.012C150.547 138.22 156.365 140.862 160.655 145.45C164.946 150.039 167.194 156.026 166.987 162.308C166.609 173.748 160.567 178.812 156.156 182.509C152.583 185.504 151.125 186.859 150.926 190.38C150.844 191.834 150.725 196.143 150.687 197.681C150.598 201.204 147.717 204 144.217 204Z"
          fill="white"
        />
        <path
          d="M147.166 113.634C152.833 143.297 178.896 165.72 210.21 165.72C212.391 165.72 214.535 165.569 216.658 165.352C214.526 126.693 182.511 96 143.32 96C102.749 96 69.8601 128.889 69.8601 169.459C69.8601 177.871 71.3036 185.937 73.9075 193.462C110.665 182.16 139.012 151.668 147.166 113.634Z"
          fill="white"
        />
        <path
          opacity="0.2"
          d="M147.166 113.634C152.833 143.297 178.897 165.72 210.21 165.72C212.391 165.72 214.535 165.569 216.659 165.352C214.527 126.693 182.511 96 143.32 96C102.75 96 69.8604 128.889 69.8604 169.459C69.8604 177.871 71.3039 185.937 73.9078 193.462C110.665 182.16 139.013 151.668 147.166 113.634Z"
          fill={primary}
        />
        <path
          d="M87.0809 136.497C88.044 131.905 89.9935 128.397 93.2894 125.686C96.366 123.158 99.4908 122.914 101.614 124.859C103.776 126.838 103.654 129.668 101.043 132.907C98.8726 135.598 96.2872 137.948 95.0913 141.338C94.3731 143.371 92.6847 144.75 90.3186 144.398C87.6004 143.993 86.8893 141.906 86.8204 139.56C86.7849 138.376 87.0177 137.175 87.0809 136.497Z"
          fill="white"
        />
        <path
          d="M104.566 118.105C105.313 115.879 109.013 114.195 111.435 114.907C113.27 115.447 114.019 116.734 113.442 118.564C112.737 120.804 109.117 122.405 106.696 121.606C104.923 121.022 104.153 119.816 104.566 118.105Z"
          fill="white"
        />
        <defs>
          <clipPath id="clip0_8_230">
            <rect
              width="190"
              height="190"
              fill="white"
              transform="translate(150)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default FaceNotFoundError;
