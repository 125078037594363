import styled from 'styled-components/macro';
import { device } from 'components/Global/MediaQuery';

export const ScSwitchLanguage = styled.div`
  position: relative;
`;

export const ScButtonSwitchLanguage = styled.button`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  background: none;
  border: none;
  color: ${({ theme }) => theme.baseContent};
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  @media (${device.mobileXL}) {
    font-size: 12px;
  }
  .icon {
    svg {
      display: block;
      width: 24px;
      height: 24px;
      path {
        fill: ${({ theme }) => theme.baseSecondaryContent};
      }
      @media (${device.mobileXL}) {
        width: 18px;
        height: 18px;
      }
    }
  }
  .value {
    line-height: 1.19;
    margin-left: 12px;
    @media (${device.mobileXL}) {
      margin-left: 8px;
    }
  }
  .arrow {
    display: flex;
    transition: 0.3s ease-in-out;
    &.active {
      transform: rotate(180deg);
    }
    svg path {
      fill: ${({ theme }) => theme.baseSecondaryContent};
    }
  }
`;

export const ScDropdownSwitchLanguage = styled.ul`
  position: absolute;
  top: 150%;
  right: 0;
  background: ${({ theme }) => theme.base_200};
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.24);
  min-width: 120px;
  border-radius: 12px;
  list-style: none;
  z-index: 100;
  overflow: hidden;
  @media (${device.mobileXL}) {
    min-width: 90px;
  }

  & > li {
    padding: 10px 12px;
    cursor: pointer;
    font-size: 12px;
    line-height: 1.3;
    color: ${({ theme }) => theme.baseContent};
    transition: all 0.1s ease;
    &:hover,
    &.selected {
      color: ${({ theme }) => theme.baseContent};
      background-color: ${({ theme }) => theme.base_100};
    }
  }
`;
