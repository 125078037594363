import * as React from 'react';
import type { SVGProps } from 'react';
const SvgSecurityBadge = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={28}
      height={28}
      fill="none"
      viewBox="0 0 28 28"
      {...props}
    >
      <path
        fill="#fff"
        d="M14 1.167 3.5 5.833v7c0 6.475 4.48 12.53 10.5 14 6.02-1.47 10.5-7.525 10.5-14v-7L14 1.166Zm0 12.821h8.167c-.619 4.807-3.827 9.088-8.167 10.43V14H5.833V7.35L14 3.722v10.266Z"
      />
    </svg>
  );
};
export default SvgSecurityBadge;
