import React, { FC, Fragment, memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, Controller, Control, Validate } from 'react-hook-form';
import axios from 'axios';
import { Buffer } from 'buffer';
import { useLoaderData, useNavigate, useSearchParams } from 'react-router-dom';

import { GetCurrentStageResponse } from 'api/verification';

import { createVerificationLink, getToday, getTodayYear } from 'helpers/common';
import { formatDate } from 'helpers/masks';
import {
  composeValidators,
  cyrillicRegexValidator,
  dateValidator,
  latinRegexValidator,
  regex as regexValidator,
  required,
} from 'helpers/validators';
import { DocumentFieldType } from 'helpers/enums';
import { showTooltip } from 'helpers/tooltip';
import { translationFactory } from 'helpers/trans';
import { parseErrorCode } from 'helpers/api';

import { auth } from 'services/auth';

import { useApi } from 'hooks/api';
import { DocumentField, confirmDocument, ParsedDocument } from 'api/document';

import { Content } from 'components/Content';
import {
  InputBlock,
  MaskedInputBlock,
} from 'components/form/primitives/InputBlock';
import { Button } from 'components/Button';

import { TooltipTypes } from 'modules/globalTooltip';

import { DocumentEditFieldList, ScPreview, ScPreviews } from './styled';
import { DocumentConfirmErrors } from './utils';

type DocumentEditFormValues = { [name: string]: string };

const DocumentEditForm: FC = () => {
  const { t } = useTranslation('documents', { useSuspense: true });

  const navigate = useNavigate();

  let [searchParams] = useSearchParams();

  const [callConfirmDocument] = useApi(confirmDocument);

  const { document, currentStage } = useLoaderData() as {
    document: ParsedDocument;
    currentStage: {
      stageInfo: GetCurrentStageResponse;
      stageRedirect?: JSX.Element;
    };
  };

  const { parsedData, type, id, pages } = document;

  const { handleSubmit, control, formState } = useForm<DocumentEditFormValues>({
    defaultValues: parsedData
      ? parsedData
      : { surname: '', name: '', date_of_birth: '', number: '' },
    mode: 'onChange',
  });

  const onSubmit = async (data: DocumentEditFormValues) => {
    const params = {
      userData: data,
      isForced: !!searchParams.get('isForced'),
    };
    const { error } = await callConfirmDocument(id, params).request;

    if (error != null) {
      const errorCode = parseErrorCode(error);

      if (errorCode === DocumentConfirmErrors.VERIFICATION_NOT_INITIALIZED) {
        const token = auth.get();

        if (token) {
          window.location.href = createVerificationLink(token);
        }
      }

      if (errorCode === DocumentConfirmErrors.USER_INTERACTION_COMPLETED) {
        return navigate(`/result`);
      }

      showTooltip({
        type: TooltipTypes.ERROR,
        translate: true,
        body: translationFactory('common:errors.somethingWentWrong'),
      });
      return;
    }

    navigate(`/liveness`);
  };

  if (currentStage?.stageRedirect) return currentStage.stageRedirect;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Content
        title={t('documentEdit.title')}
        description={t('documentEdit.description')}
        button={
          <Button disabled={!formState.isValid} type="submit">
            {t('documentEdit.button')}
          </Button>
        }
        flexGap={36}
      >
        <ScPreviews single={pages.length === 1}>
          {pages.map((src, index) => (
            <FilePreview key={index} src={src} />
          ))}
        </ScPreviews>
        <DocumentEditFieldList>
          {type.fields.map((field) => (
            <FieldGroup key={field.name} control={control} {...field} />
          ))}
        </DocumentEditFieldList>
      </Content>
    </form>
  );
};

const FilePreview: FC<{ src: string }> = ({ src }) => {
  const [img, setImg] = useState<any>(null);

  const fetchImage = async (src: string) => {
    const token = auth.get();

    if (token) {
      axios
        .get(src, {
          headers: { Authorization: `Bearer ${token}` },
          responseType: 'arraybuffer',
        })
        .then((response: any) => {
          let data = `data:${
            response.headers['content-type']
          };base64,${new Buffer(response.data, 'binary').toString('base64')}`;
          setImg(data);
        });
    }
  };

  useEffect(() => {
    fetchImage(src);
  }, [src]);

  return (
    <ScPreview>
      <div className="file">{img && <img src={img} alt="" />}</div>
    </ScPreview>
  );
};

const validateDate = composeValidators(required, dateValidator);
const validateLatin = composeValidators(required, latinRegexValidator);
const validateCyrillic = composeValidators(required, cyrillicRegexValidator);

const DEFAULT_DATE_VALIDATORS: { [field: string]: Validate<string> } = {
  date_of_birth: (value: string) =>
    getToday() <=
    new Date(
      Number(value.slice(0, 4)),
      Number(value.slice(5, 7)) - 1,
      Number(value.slice(8, 10))
    )
      ? 'validation:pastDate'
      : getTodayYear() - Number(value.slice(0, 4)) >= 120
      ? 'validation:ageExceed'
      : undefined,
  date_of_expiry: (value: string) =>
    getToday() >
    new Date(
      Number(value.slice(0, 4)),
      Number(value.slice(5, 7)) - 1,
      Number(value.slice(8, 10))
    )
      ? 'validation:expiredDocument'
      : undefined,
};

interface FieldGroupProps extends DocumentField {
  control: Control<DocumentEditFormValues, any>;
}

const FieldGroup: FC<FieldGroupProps> = ({
  control,
  type,
  name,
  regex,
  title,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation('documents');

  const field = useMemo(() => {
    function makeValidator(validator: Validate<string>) {
      if (regex != null) {
        return composeValidators(
          validator,
          regexValidator(new RegExp(regex), 'validation:invalid')
        );
      }

      return validator;
    }

    switch (type) {
      case DocumentFieldType.text:
        return (
          <Controller
            render={({ field, fieldState: { error } }) => (
              <InputBlock
                {...field}
                label={title[language]}
                error={error?.message ? t(`${error?.message}`) : null}
              />
            )}
            control={control}
            name={name}
            rules={{
              validate: makeValidator(required),
            }}
          />
        );
      case DocumentFieldType.textCyrillic:
        return (
          <Controller
            render={({ field, fieldState: { error } }) => (
              <InputBlock
                {...field}
                label={title[language]}
                error={error?.message ? t(`${error?.message}`) : null}
              />
            )}
            control={control}
            name={name}
            rules={{
              validate: makeValidator(validateCyrillic),
            }}
          />
        );
      case DocumentFieldType.textLatin:
        return (
          <Controller
            render={({ field, fieldState: { error } }) => (
              <InputBlock
                {...field}
                label={title[language]}
                error={error?.message ? t(`${error?.message}`) : null}
              />
            )}
            control={control}
            name={name}
            rules={{
              validate: makeValidator(validateLatin),
            }}
          />
        );
      case DocumentFieldType.date:
        let validator = validateDate;
        if (name in DEFAULT_DATE_VALIDATORS) {
          validator = composeValidators(
            validator,
            DEFAULT_DATE_VALIDATORS[name]
          );
        }

        return (
          <Controller
            render={({ field, fieldState: { error } }) => (
              <MaskedInputBlock
                {...field}
                label={title[language]}
                mask={formatDate}
                placeholder={t('documentEdit.date.placeholder')}
                error={error?.message ? t(`${error?.message}`) : null}
              />
            )}
            control={control}
            name={name}
            rules={{
              validate: makeValidator(validator),
            }}
          />
        );

      default:
        return null;
    }
  }, [t, name, regex, type, control, language, title]);

  return <Fragment>{field}</Fragment>;
};

export default memo(DocumentEditForm);
