import { factory } from 'api/base';

import {
  LivenessGesture,
  VerificationScheme,
  VerificationStatuses,
  VerificationStageTypes,
  VerificationRejectionReason,
  ProvidersProtocol,
} from 'helpers/enums';
import { snakify } from 'helpers/common';

import { ENV } from 'env/env.config';
import { Theme } from 'themes/contracts';

export type ThemeMediaConfig = { logo: string; background?: string };

export interface InitVerificationResponse {
  id: number;
  status: VerificationStatuses;
  returnUrl?: string;
  locale?: string;
  theme: {
    config: Partial<Theme> | null;
    media: Partial<ThemeMediaConfig> | null;
  } | null;
  isSuccess?: boolean;
  rejectionReasons?: VerificationRejectionReason[];
}

export const initVerification = factory(
  (r) => () => r<InitVerificationResponse>('put', '/verifications/init')
);

export const infoVerification = factory(
  (r) => () => r<InitVerificationResponse>('get', '/verifications/info')
);

export interface Provider {
  id: string;
  name: string;
  protocol: ProvidersProtocol;
  countries: string[] | null;
}

export const getProviders = factory(
  (r) => () => r<Provider[]>('get', '/providers')
);

export const getProvidersPriorityCountries = factory(
  (r) => () => r<string[]>('get', '/providers/priority_countries')
);

export interface InitProviderParams {
  providerId: string;
  country?: string;
}

export const initProvider = factory(
  (r) => (params: InitProviderParams) =>
    r<Provider[]>('put', '/providers/init', snakify(params))
);

interface DocumentStageResponse {
  id: number;
  type: VerificationStageTypes.DOCUMENT_UPLOAD;
  country: string;
  config: {};
  primaryDocumentId?: string;
  uploadedDocumentId?: string;
}

interface LivenessStageResponse {
  id: number;
  type: VerificationStageTypes.LIVENESS_CHALLENGE;
  config: { gestures: LivenessGesture[] };
}

export type GetCurrentStageResponse =
  | DocumentStageResponse
  | LivenessStageResponse;

export const getCurrentStage = factory(
  (r) => () => r<GetCurrentStageResponse>('get', '/stages/current')
);

interface CreateVerificationParams {
  locale: string;
  returnUrl: string;
  scheme: VerificationScheme;
}

export const createVerification = factory(
  (r) => (params: CreateVerificationParams) =>
    r<{ url: string; token: string; id: number }>(
      'post',
      '/demo/verifications',
      snakify(params),
      { baseURL: ENV.VERIFICATION_URL }
    )
);

export interface SendLinkParams {
  email: string;
  lang: string;
}

export const sendVerificationLink = factory(
  (r) => (params: SendLinkParams) =>
    r<{ timeout: number }>('post', '/emails/verification_url', snakify(params))
);
