import React, { forwardRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { languages } from 'libs/i18n';
import { useComponentVisible } from 'hooks/visibility';
import { useLanguageChange } from 'hooks/translation';

import { Icon } from 'components/UI/Icon';

import {
  ScButtonSwitchLanguage,
  ScDropdownSwitchLanguage,
  ScSwitchLanguage,
} from './styled';

export const SwitchLanguage = () => {
  const [changeLanguage] = useLanguageChange();

  const { ref, openerRef, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  const handleOpen = () => {
    setIsComponentVisible(!isComponentVisible);
  };

  const handleChangeLanguage = (lang: string) => {
    changeLanguage(lang);
    handleOpen();
  };

  return (
    <ScSwitchLanguage>
      <ButtonSwitchLanguage
        dropdownOpen={isComponentVisible}
        ref={openerRef}
        onClick={handleOpen}
      />
      {isComponentVisible && (
        <DropdownSwitchLanguage ref={ref} onChange={handleChangeLanguage} />
      )}
    </ScSwitchLanguage>
  );
};

const ButtonSwitchLanguage = forwardRef<
  HTMLButtonElement,
  { onClick: () => void; dropdownOpen: boolean }
>((props, ref) => {
  const {
    i18n: { language },
  } = useTranslation();

  const currentLanguage = useMemo(() => {
    return languages.find(({ locale }) => locale === language);
  }, [language]);

  return (
    <ScButtonSwitchLanguage ref={ref} {...props}>
      <div className="icon">
        <Icon name="LangIcon" />
      </div>
      <div className="value">{currentLanguage?.name}</div>
      <div
        className={classnames('arrow', {
          active: props.dropdownOpen,
        })}
      >
        <Icon name="ArrowIcon" />
      </div>
    </ScButtonSwitchLanguage>
  );
});

const DropdownSwitchLanguage = forwardRef<
  HTMLUListElement,
  { onChange: (value: string) => void }
>(({ onChange }, ref) => {
  const {
    i18n: { language },
  } = useTranslation();

  return (
    <ScDropdownSwitchLanguage ref={ref}>
      {languages.map(({ name, locale }) => (
        <li
          className={language === locale ? 'selected' : ''}
          key={locale}
          onClick={() => onChange(locale)}
        >
          {name}
        </li>
      ))}
    </ScDropdownSwitchLanguage>
  );
});
