import { factory } from 'api/base';
import { snakify } from 'helpers/common';
import { DocumentCategory, DocumentFieldType } from 'helpers/enums';
import { getResponseCamelization } from 'helpers/api';

export interface DocumentField {
  name: string;
  title: { [lang: string]: string };
  type: DocumentFieldType;
  regex?: string;
}

export interface DocumentType {
  id: number;
  country: string;
  category: DocumentCategory;
  maxPagesCount: number;
  minPagesCount: number;
  fields: DocumentField[];
  isInspectionAvailable: boolean;
  mask?: {
    ratio: number;
    widthPercent: number;
  };
}

export const documentTypes = factory(
  (r) => (params: { country: string }) =>
    r<DocumentType[]>('get', `/documents/types`, snakify(params))
);

interface DocumentUploadResult {
  id: number;
}

export const uploadDocument = factory(
  (r) =>
    (
      country: string,
      category: DocumentCategory,
      pages: File[],
      manual: boolean = false
    ) => {
      const formData = new FormData();

      pages.forEach((f, index) => {
        formData.append(`${index}.jpg`, f);
      });

      formData.append('country', country);
      formData.append('category', category.toString());
      formData.append('manual', manual.toString());

      return r<DocumentUploadResult>('post', '/documents/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        timeout: 3 * 60 * 1000, // 3 min
      });
    }
);

export interface ParsedDocument {
  id: number;
  isPrimary?: boolean;
  type: DocumentType;
  pages: string[];
  status: number;
  parsedData: { [name: string]: string } | undefined;
  userData: { [name: string]: string } | undefined;
}

export const getDocumentInfo = factory(
  (r) => (documentId: number) =>
    r<ParsedDocument>('get', `/documents/${documentId}/info`, undefined, {
      transformResponse: getResponseCamelization([['data', 'parsedData']]),
    })
);

export const confirmDocument = factory(
  (r) =>
    (
      documentId,
      params: { isForced?: boolean; userData: { [name: string]: string } }
    ) =>
      r('put', `/documents/${documentId}/confirm`, snakify(params))
);

export const inspectDocument = factory(
  (r) =>
    (
      country: string,
      category: DocumentCategory,
      index: number,
      image: Blob
    ) => {
      const formData = new FormData();

      formData.append('country', country);
      formData.append('category', category.toString());
      formData.append('index', index.toString());
      formData.append('image', image);

      return r<boolean>('post', '/documents/inspect', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    }
);
