import { Mutex } from 'async-mutex';

import { Subscribable, canvasToBlob } from 'helpers/common';
import { DocumentCategory } from 'helpers/enums';
import { inspectDocument } from 'api/document';

export interface DocumentMask {
  ratio: number;
  widthPercent: number;
}

export enum DocumentInspectorEvent {
  START,
  NEXT_PAGE,
  FINISH,
  TIMEOUT,
}

export type DocumentInspectorAction =
  | { type: DocumentInspectorEvent.START }
  | { type: DocumentInspectorEvent.NEXT_PAGE; payload: number }
  | { type: DocumentInspectorEvent.FINISH; payload: Blob[] }
  | { type: DocumentInspectorEvent.TIMEOUT };

export type DocumentInspectorSubscriber = (
  action: DocumentInspectorAction
) => void | Promise<void>;

export class DocumentInspector extends Subscribable<DocumentInspectorSubscriber> {
  readonly country: string;
  readonly category: DocumentCategory;
  readonly pagesCount: number;
  readonly mask: DocumentMask;
  readonly maxSize: number;
  readonly imageType: string;

  private maskWidth: number | null;
  private maskHeight: number | null;
  private maskOffsetX: number | null;
  private maskOffsetY: number | null;

  private currentPageIndex: number;
  private isStarted: boolean;
  private isFinished: boolean;
  private readonly pages: Blob[];
  private timeout: number | null;
  private readonly timeoutLength: number;

  private readonly inspectionCanvas: HTMLCanvasElement;
  private readonly inspectionContext: CanvasRenderingContext2D | null;

  private readonly fullCanvas: HTMLCanvasElement;
  private readonly fullContext: CanvasRenderingContext2D | null;

  private readonly inspectionLock: Mutex;

  constructor(
    country: string,
    category: DocumentCategory,
    pagesCount: number,
    mask: DocumentMask,
    maxSize: number = 224,
    timeout = 20000,
    imageType = 'image/jpeg'
  ) {
    super();

    this.country = country;
    this.category = category;
    this.pagesCount = pagesCount;
    this.mask = mask;
    this.maxSize = maxSize;
    this.imageType = imageType;

    this.currentPageIndex = 0;

    this.inspectionCanvas = document.createElement('canvas');
    this.inspectionContext = this.inspectionCanvas.getContext('2d');

    this.fullCanvas = document.createElement('canvas');
    this.fullContext = this.fullCanvas.getContext('2d');

    this.isStarted = false;
    this.isFinished = false;

    this.maskWidth = null;
    this.maskHeight = null;
    this.maskOffsetX = null;
    this.maskOffsetY = null;

    this.pages = [];

    this.timeoutLength = timeout;
    this.timeout = null;

    this.inspectionLock = new Mutex();
  }

  private nextPage = () => {
    this.currentPageIndex++;

    this.notify({
      type: DocumentInspectorEvent.NEXT_PAGE,
      payload: this.currentPageIndex,
    });
  };

  private start = () => {
    this.isStarted = true;

    this.timeout = setTimeout(() => {
      this.finish(true);
    }, this.timeoutLength) as any as number;

    this.notify({ type: DocumentInspectorEvent.START });
  };

  private finish = (timeout: boolean = false) => {
    this.isFinished = true;

    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    this.notify(
      timeout
        ? { type: DocumentInspectorEvent.TIMEOUT }
        : { type: DocumentInspectorEvent.FINISH, payload: this.pages }
    );
  };

  private isLastPage = () => this.currentPageIndex === this.pagesCount - 1;

  private initMaskDimensions = (frame: HTMLVideoElement) => {
    const height = frame.videoHeight;
    const width = frame.videoWidth;

    this.maskWidth = Math.round(width * (this.mask.widthPercent / 100));
    this.maskHeight = Math.round(this.maskWidth * this.mask.ratio);

    if (this.maskWidth > width) {
      this.maskWidth = width;
    }

    if (this.maskHeight > height) {
      this.maskHeight = height;
    }

    this.maskOffsetX = Math.round((width - this.maskWidth) / 2);
    this.maskOffsetY = Math.round((height - this.maskHeight) / 2);
  };

  private initCanvas = (frame: HTMLVideoElement) => {
    if (this.maskHeight == null || this.maskWidth == null) return;

    if (this.maskWidth > this.maskHeight) {
      this.inspectionCanvas.width = this.maxSize;
      this.inspectionCanvas.height = Math.round(this.maxSize * this.mask.ratio);
    } else {
      this.inspectionCanvas.height = this.maxSize;
      this.inspectionCanvas.width = Math.round(this.maxSize / this.mask.ratio);
    }

    this.fullCanvas.height = frame.videoHeight;
    this.fullCanvas.width = frame.videoWidth;
  };

  private drawFrame = (
    frame: HTMLVideoElement,
    canvas: HTMLCanvasElement,
    canvasCtx: CanvasRenderingContext2D,
    mask = true
  ) => {
    if (mask) {
      if (
        this.maskOffsetY == null ||
        this.maskOffsetX == null ||
        this.maskHeight == null ||
        this.maskWidth == null
      )
        return null;

      canvasCtx.drawImage(
        frame,
        this.maskOffsetX,
        this.maskOffsetY,
        this.maskWidth,
        this.maskHeight,
        0,
        0,
        canvas.width,
        canvas.height
      );
    } else {
      canvasCtx.drawImage(
        frame,
        0,
        0,
        frame.videoWidth,
        frame.videoHeight,
        0,
        0,
        canvas.width,
        canvas.height
      );
    }
  };

  private inspect = async (document: Blob) => {
    if (!this.isStarted) {
      this.start();
    }

    const pageIndex = this.currentPageIndex;

    try {
      const { data: isConfirmed } = await inspectDocument(
        this.country,
        this.category,
        pageIndex,
        document
      );

      return isConfirmed;
    } catch (e) {
      return false;
    }
  };

  private addPage = (page: Blob) => {
    this.pages.push(page);
  };

  private _processFrame = async (frame: HTMLVideoElement) => {
    if (
      this.isFinished ||
      this.inspectionContext == null ||
      this.fullContext == null
    )
      return;

    if (!this.isStarted) {
      this.initMaskDimensions(frame);

      this.initCanvas(frame);

      this.start();
    }

    this.drawFrame(frame, this.inspectionCanvas, this.inspectionContext);
    this.drawFrame(frame, this.fullCanvas, this.fullContext, false);

    const documentForInspection = await canvasToBlob(
      this.inspectionCanvas,
      this.imageType
    );

    if (documentForInspection == null) return;

    const isConfirmed = await this.inspect(documentForInspection);

    if (isConfirmed) {
      const fullDocument = await canvasToBlob(this.fullCanvas, this.imageType);

      if (fullDocument == null) return;

      this.addPage(fullDocument);

      if (this.isLastPage()) {
        this.finish();
      } else {
        this.nextPage();
      }
    }
  };

  processFrame = (frame: HTMLVideoElement) =>
    this.inspectionLock.runExclusive(() => this._processFrame(frame));
}
