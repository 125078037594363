export function addStylesheet(fileName: string) {
  var head = document.head;
  var link = document.createElement('link');

  link.type = 'text/css';
  link.rel = 'stylesheet';
  link.href = fileName;

  head.appendChild(link);
}

export function capitalize(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function blobToFile(theBlob: Blob, fileName: string): File {
  return new File([theBlob], fileName, {
    lastModified: new Date().getTime(),
    type: theBlob.type,
  });
}

export function hexToRGB(hex: string, alpha: number) {
  const r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
  } else {
    return 'rgb(' + r + ', ' + g + ', ' + b + ')';
  }
}

export function getRandomInt(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function enumKeys(E: any): string[] {
  return Object.keys(E).filter((k) => typeof E[k] === 'number');
}

export function enumStringValues(E: any): string[] {
  const keys = Object.keys(E).filter((k) => typeof E[k] === 'string');
  return keys.map((k) => E[k]);
}

export function enumValues(E: any): number[] {
  return enumKeys(E).map((k) => E[k]);
}

export function isEnumContains(E: any, T: any): boolean {
  return enumValues(E).includes(T);
}

export function randEnumValue<T>(
  enumObj: T,
  enumExclude?: T[keyof T]
): T[keyof T] {
  const enumValues = Object.values(enumObj);
  const i = Math.floor(Math.random() * enumValues.length);

  if (enumValues[i] === enumExclude) {
    randEnumValue(enumObj, enumExclude);
  }

  return enumValues[i];
}

export function isObject(o: any) {
  return o === Object(o) && !Array.isArray(o) && typeof o !== 'function';
}

export function entries<T extends { [key: string]: any }>(
  obj: T
): [string, T[keyof T]][] {
  let ownProps = Object.keys(obj),
    i = ownProps.length,
    resArray = new Array(i); // preallocate the Array
  while (i--) resArray[i] = [ownProps[i], obj[ownProps[i]]];

  return resArray;
}

export function toCamel(s: string) {
  if (!s.includes('_')) return s;

  return s.replace(/([_][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace('_', '');
  });
}

export function toSnake(s: string) {
  return s
    .replace(/[\w]([A-Z])/g, function (m) {
      return m[0] + '_' + m[1];
    })
    .toLowerCase();
}

export function isEmpty(obj: object) {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}

export function deepKeysTransformFactory(transform: (key: string) => string) {
  function deepKeysTransform<T extends { [key: string]: any } | any[]>(
    o: T,
    blacklists?: string[][]
  ): object {
    if (isObject(o)) {
      return Object.keys(o).reduce((previous, current) => {
        const transformedKey = transform(current);

        const keyValue = (o as { [key: string]: any })[current];

        if (blacklists) {
          const keyBlacklists = blacklists
            .filter((bl) => bl[0] === transformedKey)
            .map((bl) => bl.slice(1));

          if (keyBlacklists.find((bl) => bl.length === 0)) {
            previous[transformedKey] = keyValue;
          } else {
            previous[transformedKey] = deepKeysTransform(
              keyValue,
              keyBlacklists
            );
          }
        } else {
          previous[transformedKey] = deepKeysTransform(keyValue);
        }

        return previous;
      }, {} as { [key: string]: any });
    } else if (Array.isArray(o)) {
      return o.map((i) => {
        return deepKeysTransform(i, blacklists);
      });
    }

    return o;
  }

  return deepKeysTransform;
}

export const camelize = deepKeysTransformFactory(toCamel);
export const snakify = deepKeysTransformFactory(toSnake);

export function uuid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export const eternity = new Promise(() => {}) as Promise<any>;

export function bytesToMb(bytes: number) {
  return Math.round(bytes / Math.pow(1024, 2));
}

export function getToday() {
  const date = new Date();

  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);

  return date;
}

export function getTodayYear() {
  const date = new Date();

  return date.getFullYear();
}

export class Subscribable<S extends (...args: any[]) => void | Promise<void>> {
  private subscribers: S[];

  constructor() {
    this.subscribers = [];
  }

  subscribe = (s: S) => {
    this.subscribers.push(s);
  };

  unsubscribe = (s: S) => {
    this.subscribers = this.subscribers.reduce(
      (a, cs) => (cs === s ? a : [...a, cs]),
      [] as S[]
    );
  };

  protected notify = (...args: Parameters<S>) =>
    Promise.all(this.subscribers.map((s) => s(...args)));
}

export function mobileAndTabletCheck() {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw(n|u)|c55\/|capi|ccwa|cdm|cell|chtm|cldc|cmd|co(mp|nd)|craw|da(it|ll|ng)|dbte|dcs|devi|dica|dmob|do(c|p)o|ds(12|d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(|_)|g1 u|g560|gene|gf5|gmo|go(\.w|od)|gr(ad|un)|haie|hcit|hd(m|p|t)|hei|hi(pt|ta)|hp( i|ip)|hsc|ht(c(| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i(20|go|ma)|i230|iac( ||\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|[a-w])|libw|lynx|m1w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|mcr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|([1-8]|c))|phil|pire|pl(ay|uc)|pn2|po(ck|rt|se)|prox|psio|ptg|qaa|qc(07|12|21|32|60|[2-7]|i)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h|oo|p)|sdk\/|se(c(|0|1)|47|mc|nd|ri)|sgh|shar|sie(|m)|sk0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h|v|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl|tdg|tel(i|m)|tim|tmo|to(pl|sh)|ts(70|m|m3|m5)|tx9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas|your|zeto|zte/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || (window as any).opera);
  return check;
}

export function createVerificationLink(token: string) {
  return `${window.location.origin}/verification/${token}`;
}

export function canvasToBlob(
  canvas: HTMLCanvasElement,
  type?: string,
  quality?: any
) {
  return new Promise<Blob | null>((resolve) => {
    canvas.toBlob(
      (blob) => {
        resolve(blob);
      },
      type,
      quality
    );
  });
}

export function sortByPriority(strings: string[], priorities: string[]) {
  return strings.sort((a, b) => {
    const priorityA = priorities.indexOf(a);
    const priorityB = priorities.indexOf(b);

    // If a is not found in the priorities array, move it to the end
    if (priorityA === -1) return 1;
    // If b is not found in the priorities array, move it to the end
    if (priorityB === -1) return -1;

    // Compare the priorities and return the result
    return priorityA - priorityB;
  });
}
