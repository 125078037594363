import libAxios, { AxiosPromise, AxiosRequestConfig } from 'axios';
import qs from 'qs';

import { RequestMethod } from 'types/common';
import { getResponseCamelization } from 'helpers/api';
import { RequestContext, RequestFn } from 'types/api';
import { auth } from 'services/auth';

function getStaticGlobalHeaders() {
  const headers: { [name: string]: string } = {};

  return headers;
}

function getAuthorizationHeader() {
  const token = auth.get();

  if (token == null) return;

  return `Bearer ${token}`;
}

function getDynamicGlobalHeaders() {
  const headers: { [name: string]: string } = {};

  const authHeader = getAuthorizationHeader();

  if (authHeader != null) {
    headers.Authorization = authHeader;
  }

  return headers;
}

const axiosGlobalConfig: AxiosRequestConfig = {
  baseURL: process.env.REACT_APP_API_URL,
  timeout: parseInt(process.env.REACT_APP_REQUEST_TIMEOUT || '20') * 1000,
  headers: getStaticGlobalHeaders(),
};

axiosGlobalConfig.transformResponse = [getResponseCamelization()];
axiosGlobalConfig.paramsSerializer = (params) =>
  qs.stringify(params, { arrayFormat: 'repeat' });

const axios = libAxios.create(axiosGlobalConfig);

axios.interceptors.response.use(
  (response) => {
    if (response.data.errorCode != null && response.data.errorCode !== 0) {
      return Promise.reject(response);
    }

    if (response.data && typeof response.data.data !== 'undefined') {
      response.data = response.data.data;
    } else {
      response.data = {};
    }

    return response;
  },
  (error) => {
    return Promise.reject(error.response);
  }
);

export function request<T = any>(
  this: AxiosRequestConfig | void,
  method: RequestMethod,
  uri: string,
  data?: object,
  functionScopedConfig?: AxiosRequestConfig
): AxiosPromise<T> {
  const conf: AxiosRequestConfig = {
    method,
    url: uri,
    [method === 'get' ? 'params' : 'data']: data,
    ...this,
    ...functionScopedConfig,
  };

  const dynamicGlobalHeaders = getDynamicGlobalHeaders();

  if (conf.headers == null) {
    conf.headers = dynamicGlobalHeaders;
  } else {
    conf.headers = { ...dynamicGlobalHeaders, ...conf.headers };
  }

  return axios(conf);
}

export function factory<Args extends any[], Return>(
  handler: (r: typeof request) => RequestFn<Args, Return>
) {
  return function (this: RequestContext, ...rest: Args) {
    const r = request.bind(this) as typeof request; // bind looses generic

    return handler(r)(...rest);
  };
}
