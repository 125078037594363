import * as React from 'react';
import type { SVGProps } from 'react';
const SvgInterferenceBadge = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={28}
      height={28}
      fill="none"
      viewBox="0 0 28 28"
      {...props}
    >
      <path
        fill="#fff"
        d="M3.279 3.278 1.622 4.923 4.27 7.572A11.562 11.562 0 0 0 2.334 14c0 6.44 5.226 11.667 11.666 11.667 2.38 0 4.585-.712 6.429-1.937l2.648 2.648 1.645-1.645L3.28 3.278ZM14 23.333c-5.145 0-9.333-4.188-9.333-9.333 0-1.727.478-3.337 1.307-4.737l12.763 12.764A9.254 9.254 0 0 1 14 23.333ZM9.264 5.973 7.572 4.27A11.562 11.562 0 0 1 14 2.333c6.44 0 11.667 5.227 11.667 11.667 0 2.38-.712 4.585-1.937 6.428l-1.703-1.703A9.18 9.18 0 0 0 23.334 14c0-5.145-4.189-9.333-9.334-9.333a9.254 9.254 0 0 0-4.736 1.306Z"
      />
    </svg>
  );
};
export default SvgInterferenceBadge;
