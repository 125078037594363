import { TOptions, TFunction } from 'i18next';
import {
  errorHandlerFactoryWithTranslation,
  TranslationMapping,
} from './error';
import { capitalize } from './common';

export function translationFactory(
  key: string | string[],
  options: {
    params?: TOptions;
    format?: (value: string) => string;
  } = {}
) {
  return (t: TFunction) => {
    const trans = t(key, options.params);

    return options.format ? options.format(trans) : trans;
  };
}

export const titleGetter = translationFactory;

export function errorTranslationFactory(
  errorCode: number,
  mapping: TranslationMapping,
  def?: string | string[]
) {
  return (t: TFunction) => {
    const errorHandler = errorHandlerFactoryWithTranslation(
      t,
      mapping,
      def || 'common:errors.unknown'
    );
    const error = errorHandler(errorCode);

    return typeof error === 'string'
      ? error
      : capitalize(t('common:errors.unknown'));
  };
}
