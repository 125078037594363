import React from 'react';
import { useTheme } from 'hooks/theme';

const Success = () => {
  const { primary, primaryBg } = useTheme();

  return (
    <div style={{ maxWidth: 280, width: '100%', height: 'auto' }}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 280 280"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_5_71)">
          <mask
            id="mask0_5_71"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="280"
            height="280"
          >
            <path
              d="M279.176 0.824203H0.824203V279.176H279.176V0.824203Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask0_5_71)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M99.248 37.679C99.248 40.1956 97.209 42.2354 94.692 42.2354C92.175 42.2354 90.135 40.1956 90.135 37.679C90.135 35.1624 92.175 33.1221 94.692 33.1221C97.209 33.1226 99.248 35.1624 99.248 37.679Z"
              fill={primaryBg}
            />
            <path
              d="M273.604 88.969L279.176 92.605L250.401 136.687L244.835 133.051L273.604 88.969Z"
              fill={primary}
            />
            <path
              d="M241.698 71.455L235.053 71.769L236.798 108.683L243.442 108.369L241.698 71.455Z"
              fill={primary}
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M55.1278 151.531L2.6525 176.286L0.824203 146.722L16.5043 156.025L20.1099 134.88L34.9756 147.352L39.6961 130.009L55.1278 151.531Z"
              fill={primary}
            />
            <path
              d="M222.136 230.574C226.791 242.29 221.045 255.611 209.331 260.266C197.616 264.922 184.295 259.179 179.638 247.461C174.981 235.747 180.727 222.425 192.441 217.771C204.158 213.114 217.478 218.857 222.136 230.574ZM185.602 245.094C188.95 253.521 198.533 257.654 206.961 254.303C215.391 250.955 219.52 241.372 216.171 232.945C212.821 224.515 203.239 220.383 194.811 223.733C186.384 227.083 182.252 236.665 185.602 245.094Z"
              fill={primaryBg}
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M268.797 153.148C268.797 155.664 266.757 157.704 264.24 157.704C261.724 157.704 259.684 155.664 259.684 153.148C259.684 150.631 261.724 148.591 264.24 148.591C266.757 148.591 268.797 150.631 268.797 153.148Z"
              fill={primary}
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M155.531 256.026C155.531 258.543 153.491 260.582 150.975 260.582C148.458 260.582 146.418 258.543 146.418 256.026C146.418 253.51 148.458 251.47 150.975 251.47C153.491 251.47 155.531 253.51 155.531 256.026Z"
              fill={primary}
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M112.164 4.62601C113.872 5.81931 115.676 6.70271 117.236 8.37391C114.945 11.1439 114.21 12.926 110.888 14.4161C108.533 15.4724 105.972 15.6763 103.453 15.7872C100.276 15.9259 97.369 15.149 94.331 17.2649C91.465 19.2623 90.788 23.032 87.555 25.0843C83.417 27.7129 78.943 27.2681 75.212 27.0876C73.37 26.9969 71.857 26.5467 69.5386 28.1168C67.9261 29.2085 67.0057 31.2173 65.6308 32.8825C62.1628 37.0703 57.7853 39.3797 52.4221 38.0532C52.5781 36.1482 53.3099 34.4221 53.6263 32.571C61.2533 32.858 61.3245 24.4161 68.5681 21.4434C73.876 19.2628 79.794 22.5204 84.148 19.6869C85.778 18.6295 86.749 16.3352 87.752 15.0837C88.992 13.537 90.4 11.9376 92.364 10.9204C97.466 8.27881 104.178 11.1243 108.848 8.30381C110.402 7.36491 110.826 6.05421 112.164 4.62601Z"
              fill={primary}
            />
            <path
              d="M112.005 253.496L99.902 263.286L109.691 275.387L121.794 265.598L112.005 253.496Z"
              fill={primary}
            />
            <path
              opacity="0.4"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M270.576 196.451L266.558 206.082L260.222 197.787L253.889 189.492L264.24 188.155L274.591 186.818L270.576 196.451Z"
              fill={primary}
            />
            <path
              d="M210.887 48.5652L19.3497 72.638L42.58 257.474L234.117 233.401L210.887 48.5652Z"
              fill="black"
            />
            <path
              d="M218.622 37.6757L27.0852 61.748L50.3154 246.584L241.852 222.512L218.622 37.6757Z"
              fill={primary}
            />
            <path
              d="M55.6241 62.2987C51.1607 63.3577 47.7829 65.3535 45.2191 68.6448C42.827 71.716 42.6688 74.776 44.6248 76.802C46.6146 78.864 49.3775 78.671 52.4752 76.036C55.0494 73.846 57.2795 71.259 60.5616 70.003C62.5296 69.2488 63.8344 67.5629 63.4288 65.2594C62.964 62.6135 60.9051 61.9719 58.6115 61.9649C57.4524 61.9616 56.2852 62.2193 55.6241 62.2987Z"
              fill="white"
            />
            <path
              d="M38.0993 79.859C35.9437 80.646 34.3938 84.306 35.1516 86.654C35.7263 88.434 37.0033 89.132 38.7767 88.522C40.947 87.775 42.4198 84.196 41.5766 81.851C40.9595 80.133 39.7618 79.412 38.0993 79.859Z"
              fill="white"
            />
            <path
              d="M66.8125 238.538C62.4023 237.275 59.1197 235.126 56.7097 231.721C54.4612 228.543 54.4438 225.479 56.4906 223.545C58.5734 221.577 61.3243 221.896 64.2981 224.671C66.769 226.977 68.8778 229.664 72.098 231.07C74.03 231.913 75.255 233.657 74.745 235.94C74.159 238.561 72.073 239.107 69.7814 239.009C68.6234 238.959 67.4692 238.648 66.8125 238.538Z"
              fill="white"
            />
            <path
              d="M136.293 215.274L66.8782 146.651L83.666 129.67L129.862 175.34L177.527 81.91L198.797 92.762L136.293 215.274Z"
              fill="white"
            />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_5_71">
            <rect
              width="280"
              height="278.352"
              fill="white"
              transform="translate(0 0.824203)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default Success;
