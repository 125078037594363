import React, { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useApi } from 'hooks/api';
import { VerificationResultStatuses } from 'helpers/enums';
import { infoVerification } from 'api/verification';
import { Loader } from 'components/Loader';
import { Content } from 'components/Content';

import { ResultScreen } from './ResultScreen';

interface ResultWithRedirectProps {
  redirect?: boolean;
  expired?: boolean;
}

const ResultWithRedirect: FC<ResultWithRedirectProps> = ({
  redirect,
  expired,
}) => {
  const { t } = useTranslation();

  const [requestInfoVerification, { data, pending }] = useApi(infoVerification);

  const fetchInfoVerification = useCallback(async () => {
    const { response, error } = await requestInfoVerification().request;

    if (response && error == null) {
      return response.data.returnUrl;
    } else {
      return null;
    }
  }, [requestInfoVerification]);

  useEffect(() => {
    fetchInfoVerification().then((r) => {
      return r && redirect ? window.location.assign(r) : null;
    });
  }, [fetchInfoVerification, redirect]);

  return (
    <>
      {pending || (data?.returnUrl && redirect) ? (
        <Content>
          <Loader relative small borderRadius={'12px'} />
        </Content>
      ) : (
        <ResultScreen
          textPrimaryButton={t('common:returnBack')}
          resultStatus={
            expired
              ? VerificationResultStatuses.EXPIRED
              : VerificationResultStatuses.PROCESSING
          }
          onClickPrimaryButton={
            data?.returnUrl
              ? () => data?.returnUrl && window.location.assign(data.returnUrl)
              : undefined
          }
        />
      )}
    </>
  );
};

export default ResultWithRedirect;
