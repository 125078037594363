import * as React from 'react';
import type { SVGProps } from 'react';
const SvgColorfulBadge = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={28}
      height={28}
      fill="none"
      viewBox="0 0 28 28"
      {...props}
    >
      <path
        fill="#fff"
        d="M19.912 13.99 8.325 2.335 6.498 4.172l3.084 3.103-6.675 6.716a1.944 1.944 0 0 0-.574 1.382 1.953 1.953 0 0 0 .574 1.382l7.129 7.171a1.95 1.95 0 0 0 1.374.574c.492 0 .998-.195 1.373-.574l7.129-7.171a1.952 1.952 0 0 0 0-2.764Zm-14.71 1.383 6.208-6.246 6.208 6.246H5.201Zm17.873 1.956s-2.593 2.83-2.593 4.563c0 1.435 1.167 2.608 2.593 2.608 1.425 0 2.592-1.173 2.592-2.608 0-1.734-2.593-4.563-2.593-4.563Z"
      />
    </svg>
  );
};
export default SvgColorfulBadge;
