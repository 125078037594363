import { mobileAndTabletCheck } from 'helpers/common';

export const ENV = {
  IS_DEV: process.env.NODE_ENV === 'development',
  IS_PROD: process.env.NODE_ENV === 'production',
  IS_LOCAL: process.env.IS_LOCAL,

  API_URL: process.env.REACT_APP_API_URL,
  PUBLIC_URL: process.env.PUBLIC_URL,
  CONTACT_URL: process.env.REACT_APP_CONTACT_URL,
  VERIFICATION_URL: process.env.REACT_APP_VERIFICATION_URL,
  BUILD_VERSION: process.env.BUILD_VERSION,
  APP_THEME: process.env.REACT_APP_THEME,
  LIVENESS_TIMEOUT: process.env.REACT_APP_LIVENESS_TIMEOUT
    ? parseInt(process.env.REACT_APP_LIVENESS_TIMEOUT)
    : 15000,
  IS_MOBILE: mobileAndTabletCheck(),
};
