import * as React from 'react';
import type { SVGProps } from 'react';
const SvgLightBadge = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={28}
      height={28}
      fill="none"
      viewBox="0 0 28 28"
      {...props}
    >
      <path
        fill="#fff"
        d="M14 10.5a3.51 3.51 0 0 1 3.5 3.5 3.51 3.51 0 0 1-3.5 3.5 3.51 3.51 0 0 1-3.5-3.5 3.51 3.51 0 0 1 3.5-3.5Zm0-2.333A5.835 5.835 0 0 0 8.167 14 5.835 5.835 0 0 0 14 19.833 5.835 5.835 0 0 0 19.834 14 5.836 5.836 0 0 0 14 8.167Zm-11.666 7h2.333A1.17 1.17 0 0 0 5.834 14a1.17 1.17 0 0 0-1.167-1.167H2.334A1.17 1.17 0 0 0 1.167 14a1.17 1.17 0 0 0 1.167 1.167Zm21 0h2.333A1.17 1.17 0 0 0 26.834 14a1.17 1.17 0 0 0-1.167-1.167h-2.333A1.17 1.17 0 0 0 22.167 14a1.17 1.17 0 0 0 1.167 1.167Zm-10.5-12.834v2.334A1.17 1.17 0 0 0 14 5.833a1.17 1.17 0 0 0 1.167-1.166V2.333A1.17 1.17 0 0 0 14 1.167a1.17 1.17 0 0 0-1.166 1.166Zm0 21v2.334A1.17 1.17 0 0 0 14 26.833a1.17 1.17 0 0 0 1.167-1.166v-2.334A1.17 1.17 0 0 0 14 22.167a1.17 1.17 0 0 0-1.166 1.166ZM6.989 5.343a1.162 1.162 0 1 0-1.645 1.645L6.58 8.225a1.162 1.162 0 0 0 1.645 0c.444-.455.455-1.202 0-1.645L6.99 5.343ZM21.42 19.775a1.16 1.16 0 0 0-1.645 0 1.163 1.163 0 0 0 0 1.645l1.237 1.237a1.162 1.162 0 0 0 1.898-1.268 1.163 1.163 0 0 0-.253-.377l-1.237-1.237Zm1.237-12.787a1.162 1.162 0 1 0-1.645-1.645L19.775 6.58a1.162 1.162 0 0 0 0 1.645c.455.443 1.202.455 1.645 0l1.237-1.237ZM8.225 21.42a1.163 1.163 0 1 0-1.645-1.645l-1.236 1.237a1.162 1.162 0 0 0 0 1.645c.455.443 1.201.455 1.645 0l1.236-1.237Z"
      />
    </svg>
  );
};
export default SvgLightBadge;
