import React from 'react';
import { createRoot } from 'react-dom/client';

import 'webrtc-adapter';
import 'libs/posthog';

import App from './app/app';

import 'libs/i18n';

const container = document.getElementById('root');
if (container) {
  const root = createRoot(container);
  root.render(<App />);
}
