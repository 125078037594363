import { CameraErrors } from '../../components/CameraErrorScreen/constants';
import { TooltipText } from './constants';

interface DocumentInspectorState {
  isCameraModalOpen: boolean;
  isReadyForInspection: boolean;
  error?: CameraErrors;
  isStreaming: boolean;
  isManual: boolean;
  tooltip: TooltipText;
}

export enum DocumentInspectorFormEvent {
  OPEN_MODAL,
  START_INSPECTION,
  TIMEOUT,
  CHANGE_TOOLTIP,
  RESET,
  STREAMING,
  MANUAL,
  ERROR,
}

type DocumentInspectorFormAction =
  | { type: DocumentInspectorFormEvent.OPEN_MODAL }
  | { type: DocumentInspectorFormEvent.START_INSPECTION }
  | { type: DocumentInspectorFormEvent.TIMEOUT }
  | { type: DocumentInspectorFormEvent.CHANGE_TOOLTIP; payload: TooltipText }
  | { type: DocumentInspectorFormEvent.RESET; payload: DocumentInspectorState }
  | { type: DocumentInspectorFormEvent.STREAMING }
  | { type: DocumentInspectorFormEvent.MANUAL; payload: boolean }
  | { type: DocumentInspectorFormEvent.ERROR; payload: CameraErrors };

export const documentInspectorReducer: (
  state: DocumentInspectorState,
  action: DocumentInspectorFormAction
) => DocumentInspectorState = (state, action) => {
  switch (action.type) {
    case DocumentInspectorFormEvent.OPEN_MODAL:
      return { ...state, isCameraModalOpen: true, isTimedOut: false };
    case DocumentInspectorFormEvent.START_INSPECTION:
      return { ...state, isReadyForInspection: true };
    case DocumentInspectorFormEvent.TIMEOUT:
      return { ...state, isTimedOut: true };
    case DocumentInspectorFormEvent.CHANGE_TOOLTIP:
      return { ...state, tooltip: action.payload };
    case DocumentInspectorFormEvent.RESET:
      return action.payload;
    case DocumentInspectorFormEvent.STREAMING:
      return { ...state, isStreaming: true };
    case DocumentInspectorFormEvent.MANUAL:
      return { ...state, isManual: action.payload };
    case DocumentInspectorFormEvent.ERROR:
      return {
        isCameraModalOpen: false,
        isReadyForInspection: false,
        isStreaming: false,
        isManual: false,
        tooltip: TooltipText.prepareDocument,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const initialDocumentInspectorState: DocumentInspectorState = {
  isCameraModalOpen: false,
  isReadyForInspection: false,
  isStreaming: false,
  isManual: false,
  tooltip: TooltipText.prepareDocument,
};
