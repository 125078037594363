import styled from 'styled-components/macro';

import { device } from 'components/Global/MediaQuery';
import checkbox from 'assets/svg/checkbox.svg';

export const ScFieldWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  .label {
    font: ${({ theme }) => `700 12px/12px ${theme.mainFontFamilyName}`};
    color: ${({ theme }) => theme.baseContent};
  }
  .error {
    color: ${({ theme }) => theme.error};
    font: ${({ theme }) => `700 12px/12px ${theme.mainFontFamilyName}`}
`;

interface ScInputProps {
  error: boolean;
  searchBorder?: boolean;
}

export const ScInput = styled.input<ScInputProps>`
  width: 100%;
  min-height: 48px;
  padding: 12px 20px;
  background: ${({ theme }) => theme.base_200};
  border-radius: 8px;
  border: 1px solid;
  border-color: ${({ searchBorder, error, theme }) =>
    searchBorder ? theme.baseContent : error ? theme.error : theme.base_300};
  box-sizing: border-box;
  color: ${({ theme }) => theme.baseContent};
  font-size: 14px;
  appearance: none;
  @media (${device.mobileXL}) {
    min-height: 40px;
  }
  &::placeholder {
    color: ${({ theme }) => theme.baseContent};
    text-transform: capitalize;
  }
  &:focus {
    background: ${({ theme }) => theme.primaryBg};
    border-color: ${({ theme }) => theme.primary};
    outline: none;
  }
  &:disabled {
    color: ${({ theme }) => theme.disabledContent};
    background: ${({ theme }) => theme.disabled};
    border-color: ${({ theme }) => theme.disabled};
    cursor: not-allowed;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-transition-delay: 9999s;
  }
`;

export interface ScSelectBlockSizes {
  large?: boolean;
  withoutBorder?: boolean;
}

interface ScSelectBlockProps extends ScSelectBlockSizes {
  active: boolean;
  error: boolean;
}

export const ScSelectBlock = styled.div<ScSelectBlockProps>`
  position: relative;
  z-index: 1;
  & > .select-button {
    position: relative;
    white-space: nowrap;
    text-overflow: ellipsis;
    z-index: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    min-height: 56px;
    width: 100%;
    background-color: ${({ theme }) => theme.base_200};
    border-radius: 12px;
    border: 1px solid;
    border-color: ${({ active, error }) => {
      switch (true) {
        case error === true:
          return ({ theme }) => theme.error;
        case active === true:
          return ({ theme }) => theme.base_500;
        default:
          return ({ theme }) => theme.base_300;
      }
    }};
    font: ${({ theme }) => `500 16px/16px ${theme.mainFontFamilyName}`};
    color: ${({ theme }) => theme.baseContent};
    cursor: pointer;
    &:hover {
      border-color: ${({ theme }) => theme.base_500};
    }
    & > .value {
      width: 100%;
      padding: 8px 32px;
      .placeholder {
        color: ${({ theme }) => theme.baseContent};
      }
    }
    & > .arrow {
      position: absolute;
      right: 28px;
      transition: 0.3s;
      ${({ active }) => (active ? 'top: 10px' : '')};
      transform: ${({ active }) =>
        active ? 'rotate(180deg)' : 'rotate(0deg)'};
      svg path {
        fill: ${({ theme }) => theme.baseContent};
      }
    }
  }
`;

export const ScOptions = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: ${({ theme }) => theme.base_200};
  margin-top: 8px;
  border-radius: 12px;
  overflow: auto;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.24);
  padding: 12px 0;
  & > p {
    font: ${({ theme }) => `500 16px/16px ${theme.mainFontFamilyName}`};
    text-align: center;
    padding: 5px 10px;
  }
  & > ul {
    display: block;
    max-height: 180px;
    overflow: auto;
    list-style: none;
    & > li {
      font-size: 14px;
      padding: 6px 32px;
      cursor: pointer;
      background-color: transparent;
      color: ${({ theme }) => theme.baseContent};
      transition: color 0.2s;
      @media (${device.mobileM}) {
        padding: 4px 32px;
      }
      &:hover {
        background-color: ${({ theme }) => theme.base_400};
      }
      &.active {
        background-color: ${({ theme }) => theme.base_400};
      }
    }
  }
`;

export const ScCountryOptions = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: ${({ theme }) => theme.base_200};
  margin-top: 8px;
  overflow: auto;
  border-radius: 12px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.24);
  padding: 12px 0;
  & > p {
    font: ${({ theme }) => `500 16px/16px ${theme.mainFontFamilyName}`};
    text-align: center;
    padding: 5px 10px;
  }
  & .option {
    font: ${({ theme }) => `500 16px/16px ${theme.mainFontFamilyName}`};
    padding: 6px 32px;
    cursor: pointer;
    background-color: transparent;
    color: ${({ theme }) => theme.baseContent};
    transition: color 0.2s;
    @media (${device.mobileM}) {
      padding: 4px 32px;
    }
    &:hover {
      background-color: ${({ theme }) => theme.base_400};
    }
    &.active {
      background-color: ${({ theme }) => theme.base_400};
    }
    &.disabled {
      background-color: ${({ theme }) => theme.disabled};
      pointer-events: none;
    }
  }
`;

export const ScCountryOption = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  .icon {
    img,
    svg {
      vertical-align: middle;
      width: 36px;
      height: auto;
    }
  }
  .name {
    overflow: hidden;
    white-space: break-spaces;
    text-overflow: ellipsis;
    p {
      line-height: 1.2;
    }
  }
`;

export const ScSearchOptions = styled.div`
  position: relative;
  padding: 12px;
  margin-top: -6px;
  .search-icon {
    position: absolute;
    z-index: 1;
    left: 30px;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    svg path {
      fill: ${({ theme }) => theme.baseContent};
    }
  }
  .close-button {
    position: absolute;
    z-index: 1;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    cursor: pointer;
    background-color: transparent;
    svg path {
      fill: ${({ theme }) => theme.baseContent};
    }
  }
  input {
    padding-left: 45px;
  }
`;

export const ScCheckbox = styled.div`
  min-width: 20px;
  height: 20px;
  cursor: pointer;
  .checkbox {
    display: inline-block;
    border-radius: 4px;
    background: ${({ theme }) => theme.backgroundItemHoverColor};
    border: 1px solid ${({ theme }) => theme.borderHoverColor};
    width: 100%;
    height: 100%;
  }
  input {
    display: none !important;
  }
  input:checked ~ span {
    border-color: ${({ theme }) => theme.btnMainColor};
    background: url(${checkbox}) ${({ theme }) => theme.btnMainColor} no-repeat
      50% 50%;
  }
`;

export const ScCheckboxLabel = styled.label`
  cursor: pointer;
  position: relative;
  color: ${({ theme }) => theme.baseContent};
  font-size: 16px;
  font-family: ${({ theme }) => theme.mainFontFamilyName};
  display: flex;
  align-items: center;
  @media (${device.tablet}) {
    align-items: flex-start;
  }
  ${ScCheckbox} {
    margin-right: 12px;
  }
  @media (${device.mobileL}) {
    font-size: 12px;
  }
`;

export const ScCheckboxError = styled.div`
  color: #ff4a1a;
  margin-top: 5px;
  position: absolute;
  top: 100%;
  @media (${device.tablet}) {
    text-align: left !important;
    font-size: 11px !important;
  }
`;
