import * as React from 'react';
import type { SVGProps } from 'react';
const SvgSmooth = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={28}
      height={28}
      fill="none"
      viewBox="0 0 28 28"
      {...props}
    >
      <path
        fill="#fff"
        d="M19.833 19.822c-1.575 0-2.567.49-3.442.933-.758.385-1.376.7-2.391.7-1.05 0-1.634-.292-2.392-.7-.875-.443-1.832-.933-3.442-.933s-2.566.49-3.441.933c-.759.385-1.365.7-2.392.7v2.275c1.575 0 2.567-.49 3.442-.933.758-.385 1.365-.7 2.391-.7 1.027 0 1.634.291 2.392.7.875.443 1.832.933 3.442.933s2.566-.49 3.441-.933c.759-.385 1.377-.7 2.392-.7 1.05 0 1.633.291 2.392.7.875.443 1.843.933 3.441.933v-2.275c-1.05 0-1.633-.292-2.391-.7-.875-.443-1.867-.933-3.442-.933Zm0-5.192c-1.575 0-2.567.502-3.442.933-.758.374-1.376.7-2.391.7-1.05 0-1.634-.291-2.392-.7-.875-.443-1.832-.933-3.442-.933s-2.566.502-3.441.933c-.759.374-1.365.7-2.392.7v2.275c1.575 0 2.567-.501 3.442-.933.758-.408 1.341-.7 2.391-.7s1.634.292 2.392.7c.875.443 1.832.933 3.442.933s2.566-.501 3.441-.933c.759-.408 1.342-.7 2.392-.7 1.05 0 1.633.292 2.392.7.875.443 1.843.933 3.441.933v-2.275c-1.05 0-1.633-.291-2.391-.7-.875-.443-1.867-.933-3.442-.933Zm3.442-9.427c-.875-.443-1.844-.933-3.442-.933s-2.567.49-3.442.933c-.758.374-1.376.7-2.391.7-1.05 0-1.634-.291-2.392-.7-.875-.431-1.832-.933-3.442-.933s-2.566.49-3.441.933c-.759.385-1.365.7-2.392.7v2.252c1.575 0 2.567-.502 3.442-.933.758-.385 1.365-.7 2.391-.7 1.027 0 1.634.291 2.392.7.875.443 1.832.933 3.442.933s2.566-.502 3.441-.933c.759-.374 1.377-.7 2.392-.7 1.05 0 1.633.291 2.392.7.875.443 1.843.933 3.441.933V5.88c-1.05 0-1.633-.292-2.391-.677Zm-3.442 4.235c-1.575 0-2.567.502-3.442.934-.758.408-1.341.7-2.391.7s-1.634-.292-2.392-.7c-.875-.444-1.832-.934-3.442-.934s-2.566.502-3.441.934c-.759.408-1.342.7-2.392.7v2.275c1.575 0 2.567-.502 3.442-.934.758-.373 1.376-.7 2.391-.7 1.015 0 1.634.292 2.392.7.875.444 1.832.934 3.442.934s2.566-.502 3.441-.934c.759-.373 1.377-.7 2.392-.7 1.05 0 1.633.292 2.392.7.875.444 1.843.934 3.441.934v-2.275c-1.05 0-1.633-.292-2.391-.7-.875-.444-1.867-.934-3.442-.934Z"
      />
    </svg>
  );
};
export default SvgSmooth;
