import styled from 'styled-components/macro';
import { device } from 'components/Global/MediaQuery';

export const ScRedirectBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .qr {
    max-width: 194px;
    width: 100%;
    margin: 36px auto;
  }
  .linkToCopy {
    width: 100%;
    &__label {
      font-size: 12px;
      color: ${({ theme }) => theme.baseContent};
      margin: 4px 0;
    }
    div {
      display: flex;
      align-items: center;
      padding: 0 16px;
      background: ${({ theme }) => theme.base_200};
      width: 100%;
      height: 48px;
      border-width: 1px;
      border-style: solid;
      border-color: ${({ theme }) => theme.base_400};
      box-sizing: border-box;
      border-radius: 8px;
      color: ${({ theme }) => theme.baseContent};
      font-size: 14px;
      p {
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 4px;
      }
      & .copyIcon {
        cursor: pointer;
        margin-left: auto;
        min-width: 24px;
        path {
          fill: ${({ theme }) => theme.baseContent};
        }
      }
      @media (${device.mobileXL}) {
        height: 40px;
      }
    }
  }
`;
