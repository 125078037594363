import React from 'react';
import { useTheme } from 'hooks/theme';

const FastMovementError = () => {
  const { primary, error } = useTheme();
  return (
    <div style={{ maxWidth: 340, width: '100%', height: 'auto' }}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 340 340"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_5_2"
          maskUnits="userSpaceOnUse"
          x="153"
          y="0"
          width="187"
          height="187"
        >
          <path d="M340 0H153.187V186.813H340V0Z" fill="white" />
        </mask>
        <g mask="url(#mask0_5_2)">
          <path
            d="M299.288 31.1292L170.74 47.2852L186.331 171.336L314.879 155.18L299.288 31.1292Z"
            fill="black"
          />
          <path
            d="M304.48 23.8206L175.932 39.9766L191.522 164.028L320.07 147.872L304.48 23.8206Z"
            fill={primary}
          />
          <path
            opacity="0.4"
            d="M286.221 11.4278L278.173 17.9375L284.682 25.9843L292.73 19.4745L286.221 11.4278Z"
            fill={primary}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M273.37 180.202L273.084 185.481L268.654 182.595L264.225 179.708L268.94 177.316L273.655 174.924L273.37 180.202Z"
            fill={primary}
          />
          <path
            opacity="0.2"
            d="M261.565 18.8361L239.107 24.1875L240.07 28.23L262.528 22.8785L261.565 18.8361Z"
            fill={primary}
          />
          <path
            d="M159.731 104.496C162.417 104.496 164.595 102.319 164.595 99.632C164.595 96.9455 162.417 94.7676 159.731 94.7676C157.044 94.7676 154.866 96.9455 154.866 99.632C154.866 102.319 157.044 104.496 159.731 104.496Z"
            fill={primary}
          />
          <path
            opacity="0.2"
            d="M335.292 102.661C336.965 102.661 338.322 101.305 338.322 99.6314C338.322 97.9581 336.965 96.6016 335.292 96.6016C333.618 96.6016 332.262 97.9581 332.262 99.6314C332.262 101.305 333.618 102.661 335.292 102.661Z"
            fill={primary}
          />
          <path
            opacity="0.4"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M226.137 182.87C226.112 184.173 225.894 185.41 226.165 186.813C228.407 186.675 229.579 186.959 231.554 185.829C232.956 185.028 234.001 183.808 234.984 182.577C236.224 181.022 236.902 179.267 239.089 178.511C241.154 177.798 243.308 178.843 245.535 177.965C248.387 176.841 249.81 174.417 251.092 172.464C251.723 171.5 252.053 170.57 253.7 169.976C254.845 169.563 256.2 169.838 257.547 169.755C260.94 169.545 263.719 168.183 265.023 164.984C264.003 164.361 262.861 164.096 261.809 163.574C259.147 167.535 254.854 164.463 250.685 167.029C247.629 168.909 247.097 173.098 244.062 174.256C242.927 174.69 241.411 174.336 240.409 174.383C239.171 174.44 237.844 174.563 236.607 175.181C233.394 176.787 232.361 181.227 229.217 182.548C228.17 182.988 227.351 182.72 226.137 182.87Z"
            fill={primary}
          />
          <path
            d="M192.333 146.602C193.031 148.935 194.193 150.663 196.016 151.92C197.717 153.093 199.343 153.083 200.357 151.984C201.388 150.866 201.201 149.408 199.709 147.848C198.469 146.551 197.03 145.449 196.263 143.748C195.803 142.729 194.869 142.089 193.661 142.375C192.274 142.703 191.997 143.814 192.064 145.029C192.097 145.643 192.27 146.254 192.333 146.602Z"
            fill="white"
          />
          <path
            d="M202.178 155.348C202.661 156.467 204.648 157.175 205.869 156.701C206.795 156.342 207.125 155.644 206.747 154.722C206.285 153.595 204.343 152.925 203.126 153.444C202.235 153.824 201.889 154.481 202.178 155.348Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M300.172 36.3068C304.398 36.8763 302.949 30.2036 299.985 31.5175C298.441 32.2009 298.528 36.0853 300.172 36.3068Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M302.373 39.8729C302.076 39.3347 300.716 38.6411 299.628 39.4358C298.213 40.475 298.235 49.2198 300.089 50.0521C302.733 51.2368 304.74 44.1744 302.373 39.8729Z"
            fill="white"
          />
          <path
            d="M296.489 94.8724C296.779 68.6783 275.78 47.208 249.586 46.9172C223.392 46.6265 201.921 67.6253 201.631 93.8194C201.34 120.014 222.339 141.484 248.533 141.775C274.727 142.065 296.198 121.067 296.489 94.8724Z"
            fill="white"
          />
          <path
            d="M250.272 134.808C272.618 134.146 290.196 115.495 289.534 93.1496C288.873 70.8043 270.222 53.2262 247.876 53.8878C225.531 54.5495 207.953 73.2004 208.614 95.5458C209.276 117.891 227.927 135.469 250.272 134.808Z"
            fill={error}
          />
          <path
            d="M268.301 75.1128C266.942 73.7541 264.741 73.7541 263.382 75.1128L249.068 89.427L234.754 75.1128C233.395 73.7541 231.194 73.7541 229.835 75.1128C228.476 76.4712 228.476 78.6736 229.835 80.032L244.149 94.3462L229.835 108.66C228.476 110.019 228.476 112.221 229.835 113.58C230.514 114.259 231.405 114.599 232.295 114.599C233.185 114.599 234.075 114.259 234.754 113.58L249.068 99.2657L263.382 113.58C264.061 114.259 264.952 114.599 265.841 114.599C266.732 114.599 267.622 114.259 268.301 113.58C269.66 112.221 269.66 110.019 268.301 108.66L253.987 94.3462L268.301 80.032C269.66 78.6736 269.66 76.4712 268.301 75.1128Z"
            fill="white"
          />
          <path
            d="M216.857 125.593C221.423 121.027 221.423 113.625 216.857 109.059C212.291 104.493 204.889 104.493 200.323 109.059C195.757 113.625 195.757 121.027 200.323 125.593C204.889 130.159 212.291 130.159 216.857 125.593Z"
            fill="white"
          />
          <path
            d="M213.638 123.206C213.028 123.206 212.533 122.711 212.533 122.101C212.533 119.885 210.73 118.081 208.514 118.081C206.297 118.081 204.494 119.884 204.494 122.101C204.494 122.711 203.999 123.206 203.389 123.206C202.779 123.206 202.284 122.711 202.284 122.101C202.284 118.666 205.078 115.871 208.514 115.871C211.949 115.871 214.743 118.666 214.743 122.101C214.743 122.712 214.248 123.206 213.638 123.206Z"
            fill={primary}
          />
          <path
            d="M203.524 115.423C204.339 115.423 205 114.762 205 113.947C205 113.132 204.339 112.471 203.524 112.471C202.708 112.471 202.047 113.132 202.047 113.947C202.047 114.762 202.708 115.423 203.524 115.423Z"
            fill={primary}
          />
          <path
            d="M213.597 115.423C214.412 115.423 215.073 114.762 215.073 113.947C215.073 113.132 214.412 112.471 213.597 112.471C212.782 112.471 212.121 113.132 212.121 113.947C212.121 114.762 212.782 115.423 213.597 115.423Z"
            fill={primary}
          />
        </g>
        <g opacity="0.34">
          <path
            d="M202.28 251.245H174.468C201.482 242.026 220.919 216.446 220.919 186.31C220.919 185.022 220.884 183.742 220.814 182.471L155.886 127.086L87.4381 208.735C94.3337 228.668 110.165 244.429 130.139 251.245H102.065C77.1637 251.245 56.978 271.431 56.978 296.332V336.423H247.367V296.332C247.366 271.431 227.181 251.245 202.28 251.245Z"
            fill="white"
          />
          <path
            opacity="0.76"
            d="M202.28 251.245H174.468C201.482 242.026 220.919 216.446 220.919 186.31C220.919 185.022 220.884 183.742 220.814 182.471L155.886 127.086L87.4381 208.735C94.3337 228.668 110.165 244.429 130.139 251.245H102.065C77.1637 251.245 56.978 271.431 56.978 296.332V336.423H247.367V296.332C247.366 271.431 227.181 251.245 202.28 251.245Z"
            fill={primary}
          />
          <path
            d="M165.755 196.735C165.795 199.076 167.726 200.944 170.07 200.903C172.412 200.865 174.281 198.933 174.241 196.588C174.2 194.246 172.268 192.378 169.925 192.419C167.582 192.457 165.716 194.393 165.755 196.735Z"
            fill="white"
          />
          <path
            d="M138.321 196.735C138.282 199.076 136.351 200.944 134.007 200.903C131.664 200.865 129.797 198.933 129.836 196.588C129.877 194.246 131.809 192.378 134.152 192.419C136.495 192.457 138.361 194.393 138.321 196.735Z"
            fill="white"
          />
          <path
            d="M161.949 210.454C160.859 210.363 159.904 211.173 159.813 212.262C159.409 217.15 155.606 220.095 152.039 220.095C148.472 220.095 144.669 217.15 144.264 212.262C144.173 211.173 143.217 210.363 142.128 210.454C141.033 210.534 140.23 211.501 140.319 212.59C140.914 219.751 145.626 224.053 152.039 224.053C158.711 224.053 163.164 219.751 163.758 212.59C163.848 211.502 163.044 210.534 161.949 210.454Z"
            fill="white"
          />
          <path
            d="M155.897 134.163C161.191 161.87 185.535 182.814 214.784 182.814C216.821 182.814 218.824 182.673 220.808 182.47C218.816 146.361 188.912 117.691 152.304 117.691C114.409 117.691 83.6883 148.412 83.6883 186.307C83.6883 194.164 85.0367 201.699 87.4689 208.727C121.802 198.171 148.281 169.689 155.897 134.163Z"
            fill="white"
          />
          <path
            opacity="0.2"
            d="M155.897 134.163C161.191 161.87 185.535 182.814 214.784 182.814C216.821 182.814 218.824 182.673 220.808 182.47C218.816 146.361 188.912 117.691 152.304 117.691C114.409 117.691 83.6883 148.412 83.6883 186.307C83.6883 194.164 85.0367 201.699 87.4689 208.727C121.802 198.171 148.281 169.689 155.897 134.163Z"
            fill={primary}
          />
          <path
            d="M99.7731 155.52C100.673 151.23 102.494 147.953 105.572 145.421C108.446 143.059 111.365 142.831 113.348 144.648C115.367 146.497 115.253 149.141 112.815 152.166C110.787 154.68 108.372 156.875 107.255 160.041C106.584 161.94 105.007 163.229 102.797 162.899C100.258 162.521 99.5941 160.572 99.5298 158.381C99.4966 157.274 99.7141 156.153 99.7731 155.52Z"
            fill="white"
          />
          <path
            d="M116.106 138.34C116.803 136.261 120.26 134.689 122.522 135.354C124.236 135.858 124.935 137.06 124.397 138.769C123.739 140.861 120.357 142.357 118.096 141.611C116.439 141.065 115.72 139.939 116.106 138.34Z"
            fill="white"
          />
        </g>
        <g opacity="0.34">
          <path
            d="M140.362 251.245H168.174C141.16 242.026 121.722 216.446 121.722 186.31C121.722 185.022 121.758 183.742 121.828 182.471L186.756 127.086L255.204 208.735C248.308 228.668 232.477 244.429 212.503 251.245H240.577C265.478 251.245 285.664 271.431 285.664 296.332V336.423H95.2748V296.332C95.2755 271.431 115.461 251.245 140.362 251.245Z"
            fill="white"
          />
          <path
            opacity="0.76"
            d="M140.362 251.245H168.174C141.16 242.026 121.722 216.446 121.722 186.31C121.722 185.022 121.758 183.742 121.828 182.471L186.756 127.086L255.204 208.735C248.308 228.668 232.477 244.429 212.503 251.245H240.577C265.478 251.245 285.664 271.431 285.664 296.332V336.423H95.2748V296.332C95.2755 271.431 115.461 251.245 140.362 251.245Z"
            fill={primary}
          />
          <path
            d="M176.886 196.735C176.846 199.076 174.916 200.944 172.572 200.903C170.229 200.865 168.361 198.933 168.401 196.588C168.442 194.246 170.374 192.378 172.717 192.419C175.06 192.457 176.926 194.393 176.886 196.735Z"
            fill="white"
          />
          <path
            d="M204.32 196.735C204.36 199.076 206.291 200.944 208.635 200.903C210.977 200.865 212.845 198.933 212.806 196.588C212.765 194.246 210.832 192.378 208.49 192.419C206.147 192.457 204.28 194.393 204.32 196.735Z"
            fill="white"
          />
          <path
            d="M180.692 210.454C181.782 210.363 182.738 211.173 182.829 212.262C183.233 217.15 187.036 220.095 190.603 220.095C194.169 220.095 197.973 217.15 198.377 212.262C198.469 211.173 199.424 210.363 200.514 210.454C201.609 210.534 202.412 211.501 202.322 212.59C201.728 219.751 197.016 224.053 190.603 224.053C183.931 224.053 179.478 219.751 178.884 212.59C178.794 211.502 179.597 210.534 180.692 210.454Z"
            fill="white"
          />
          <path
            d="M186.744 134.163C181.451 161.87 157.106 182.814 127.857 182.814C125.82 182.814 123.818 182.673 121.834 182.47C123.826 146.361 153.73 117.691 190.337 117.691C228.233 117.691 258.953 148.412 258.953 186.307C258.953 194.164 257.605 201.699 255.173 208.727C220.839 198.171 194.361 169.689 186.744 134.163Z"
            fill="white"
          />
          <path
            opacity="0.2"
            d="M186.744 134.163C181.451 161.87 157.106 182.814 127.857 182.814C125.82 182.814 123.818 182.673 121.834 182.47C123.826 146.361 153.73 117.691 190.337 117.691C228.233 117.691 258.953 148.412 258.953 186.307C258.953 194.164 257.605 201.699 255.173 208.727C220.839 198.171 194.361 169.689 186.744 134.163Z"
            fill={primary}
          />
          <path
            d="M242.869 155.52C241.969 151.23 240.148 147.953 237.069 145.421C234.196 143.059 231.277 142.831 229.293 144.648C227.274 146.497 227.388 149.141 229.827 152.166C231.854 154.68 234.269 156.875 235.386 160.041C236.057 161.94 237.634 163.229 239.844 162.899C242.383 162.521 243.048 160.572 243.112 158.381C243.145 157.274 242.928 156.153 242.869 155.52Z"
            fill="white"
          />
          <path
            d="M226.536 138.34C225.838 136.261 222.382 134.689 220.12 135.354C218.405 135.858 217.707 137.06 218.245 138.769C218.903 140.861 222.285 142.357 224.546 141.611C226.202 141.065 226.922 139.939 226.536 138.34Z"
            fill="white"
          />
        </g>
        <g opacity="0.1">
          <path
            d="M177.06 251.245H149.248C176.262 242.026 195.7 216.446 195.7 186.31C195.7 185.022 195.664 183.742 195.594 182.471L130.666 127.086L62.2184 208.735C69.114 228.668 84.9449 244.429 104.919 251.245H76.8448C51.9439 251.245 31.7582 271.431 31.7582 296.332V336.423H222.147V296.332C222.146 271.431 201.961 251.245 177.06 251.245Z"
            fill="white"
          />
          <path
            opacity="0.76"
            d="M177.06 251.245H149.248C176.262 242.026 195.7 216.446 195.7 186.31C195.7 185.022 195.664 183.742 195.594 182.471L130.666 127.086L62.2184 208.735C69.114 228.668 84.9449 244.429 104.919 251.245H76.8448C51.9439 251.245 31.7582 271.431 31.7582 296.332V336.423H222.147V296.332C222.146 271.431 201.961 251.245 177.06 251.245Z"
            fill={primary}
          />
          <path
            d="M140.536 196.735C140.575 199.076 142.506 200.944 144.85 200.903C147.193 200.865 149.061 198.933 149.021 196.588C148.98 194.246 147.048 192.378 144.705 192.419C142.362 192.457 140.496 194.393 140.536 196.735Z"
            fill="white"
          />
          <path
            d="M113.102 196.735C113.062 199.076 111.131 200.944 108.787 200.903C106.445 200.865 104.577 198.933 104.616 196.588C104.657 194.246 106.589 192.378 108.932 192.419C111.275 192.457 113.141 194.393 113.102 196.735Z"
            fill="white"
          />
          <path
            d="M136.73 210.454C135.64 210.363 134.684 211.173 134.593 212.262C134.189 217.15 130.386 220.095 126.819 220.095C123.252 220.095 119.449 217.15 119.044 212.262C118.953 211.173 117.997 210.363 116.908 210.454C115.813 210.534 115.01 211.501 115.099 212.59C115.694 219.751 120.406 224.053 126.819 224.053C133.491 224.053 137.944 219.751 138.538 212.59C138.628 211.502 137.824 210.534 136.73 210.454Z"
            fill="white"
          />
          <path
            d="M130.677 134.163C135.971 161.87 160.316 182.814 189.565 182.814C191.602 182.814 193.604 182.673 195.588 182.47C193.596 146.361 163.692 117.691 127.084 117.691C89.1892 117.691 58.4685 148.412 58.4685 186.307C58.4685 194.164 59.8169 201.699 62.2491 208.727C96.5826 198.171 123.061 169.689 130.677 134.163Z"
            fill="white"
          />
          <path
            opacity="0.2"
            d="M130.677 134.163C135.971 161.87 160.316 182.814 189.565 182.814C191.602 182.814 193.604 182.673 195.588 182.47C193.596 146.361 163.692 117.691 127.084 117.691C89.1892 117.691 58.4685 148.412 58.4685 186.307C58.4685 194.164 59.8169 201.699 62.2491 208.727C96.5826 198.171 123.061 169.689 130.677 134.163Z"
            fill={primary}
          />
          <path
            d="M74.5533 155.52C75.4529 151.23 77.2739 147.953 80.3524 145.421C83.2262 143.059 86.145 142.831 88.1284 144.648C90.1476 146.497 90.0336 149.141 87.5948 152.166C85.5676 154.68 83.1526 156.875 82.0356 160.041C81.3647 161.94 79.7876 163.229 77.5775 162.899C75.0385 162.521 74.3743 160.572 74.31 158.381C74.2769 157.274 74.4943 156.153 74.5533 155.52Z"
            fill="white"
          />
          <path
            d="M90.886 138.34C91.5834 136.261 95.0398 134.689 97.3024 135.354C99.0166 135.858 99.7154 137.06 99.1771 138.769C98.5188 140.861 95.1373 142.357 92.8761 141.611C91.2195 141.065 90.5002 139.939 90.886 138.34Z"
            fill="white"
          />
        </g>
        <g opacity="0.1">
          <path
            d="M165.582 251.245H193.394C166.379 242.026 146.942 216.446 146.942 186.31C146.942 185.022 146.978 183.742 147.048 182.471L211.976 127.086L280.423 208.735C273.528 228.668 257.697 244.429 237.723 251.245H265.797C290.698 251.245 310.883 271.431 310.883 296.332V336.423H120.495V296.332C120.495 271.431 140.681 251.245 165.582 251.245Z"
            fill="white"
          />
          <path
            opacity="0.76"
            d="M165.582 251.245H193.394C166.379 242.026 146.942 216.446 146.942 186.31C146.942 185.022 146.978 183.742 147.048 182.471L211.976 127.086L280.423 208.735C273.528 228.668 257.697 244.429 237.723 251.245H265.797C290.698 251.245 310.883 271.431 310.883 296.332V336.423H120.495V296.332C120.495 271.431 140.681 251.245 165.582 251.245Z"
            fill={primary}
          />
          <path
            d="M202.106 196.735C202.066 199.076 200.136 200.944 197.792 200.903C195.449 200.865 193.581 198.933 193.62 196.588C193.661 194.246 195.594 192.378 197.937 192.419C200.279 192.457 202.146 194.393 202.106 196.735Z"
            fill="white"
          />
          <path
            d="M229.54 196.735C229.58 199.076 231.51 200.944 233.854 200.903C236.197 200.865 238.065 198.933 238.026 196.588C237.985 194.246 236.052 192.378 233.709 192.419C231.367 192.457 229.5 194.393 229.54 196.735Z"
            fill="white"
          />
          <path
            d="M205.912 210.454C207.002 210.363 207.958 211.173 208.048 212.262C208.453 217.15 212.256 220.095 215.823 220.095C219.389 220.095 223.192 217.15 223.597 212.262C223.689 211.173 224.644 210.363 225.734 210.454C226.828 210.534 227.632 211.501 227.542 212.59C226.948 219.751 222.236 224.053 215.823 224.053C209.151 224.053 204.698 219.751 204.103 212.59C204.014 211.502 204.817 210.534 205.912 210.454Z"
            fill="white"
          />
          <path
            d="M211.964 134.163C206.671 161.87 182.326 182.814 153.077 182.814C151.04 182.814 149.037 182.673 147.054 182.47C149.045 146.361 178.95 117.691 215.557 117.691C253.453 117.691 284.173 148.412 284.173 186.307C284.173 194.164 282.825 201.699 280.393 208.727C246.059 198.171 219.58 169.689 211.964 134.163Z"
            fill="white"
          />
          <path
            opacity="0.2"
            d="M211.964 134.163C206.671 161.87 182.326 182.814 153.077 182.814C151.04 182.814 149.037 182.673 147.054 182.47C149.045 146.361 178.95 117.691 215.557 117.691C253.453 117.691 284.173 148.412 284.173 186.307C284.173 194.164 282.825 201.699 280.393 208.727C246.059 198.171 219.58 169.689 211.964 134.163Z"
            fill={primary}
          />
          <path
            d="M268.088 155.52C267.189 151.23 265.368 147.953 262.289 145.421C259.415 143.059 256.497 142.831 254.513 144.648C252.494 146.497 252.608 149.141 255.047 152.166C257.074 154.68 259.489 156.875 260.606 160.041C261.277 161.94 262.854 163.229 265.064 162.899C267.603 162.521 268.267 160.572 268.332 158.381C268.365 157.274 268.147 156.153 268.088 155.52Z"
            fill="white"
          />
          <path
            d="M251.756 138.34C251.058 136.261 247.602 134.689 245.339 135.354C243.625 135.858 242.926 137.06 243.465 138.769C244.123 140.861 247.504 142.357 249.766 141.611C251.422 141.065 252.141 139.939 251.756 138.34Z"
            fill="white"
          />
        </g>
        <path
          d="M220.027 251.245H192.215C219.229 242.026 238.667 216.446 238.667 186.31C238.667 185.022 238.631 183.742 238.561 182.471L173.633 127.086L105.185 208.735C112.081 228.668 127.912 244.429 147.886 251.245H119.812C94.9109 251.245 74.7253 271.431 74.7253 296.332V336.423H265.114V296.332C265.113 271.431 244.928 251.245 220.027 251.245Z"
          fill="white"
        />
        <path
          opacity="0.76"
          d="M220.027 251.245H192.215C219.229 242.026 238.667 216.446 238.667 186.31C238.667 185.022 238.631 183.742 238.561 182.471L173.633 127.086L105.185 208.735C112.081 228.668 127.912 244.429 147.886 251.245H119.812C94.9109 251.245 74.7253 271.431 74.7253 296.332V336.423H265.114V296.332C265.113 271.431 244.928 251.245 220.027 251.245Z"
          fill={primary}
        />
        <path
          d="M183.503 196.735C183.542 199.076 185.473 200.944 187.817 200.903C190.16 200.865 192.028 198.933 191.988 196.588C191.947 194.246 190.015 192.378 187.672 192.419C185.329 192.457 183.463 194.393 183.503 196.735Z"
          fill="white"
        />
        <path
          d="M156.069 196.735C156.029 199.076 154.098 200.944 151.754 200.903C149.412 200.865 147.544 198.933 147.583 196.588C147.624 194.246 149.556 192.378 151.899 192.419C154.242 192.457 156.108 194.393 156.069 196.735Z"
          fill="white"
        />
        <path
          d="M179.697 210.454C178.607 210.363 177.651 211.173 177.56 212.262C177.156 217.15 173.353 220.095 169.786 220.095C166.219 220.095 162.416 217.15 162.011 212.262C161.92 211.173 160.964 210.363 159.875 210.454C158.78 210.534 157.977 211.501 158.066 212.59C158.661 219.751 163.373 224.053 169.786 224.053C176.458 224.053 180.911 219.751 181.505 212.59C181.595 211.502 180.792 210.534 179.697 210.454Z"
          fill="white"
        />
        <path
          d="M173.644 134.163C178.938 161.87 203.283 182.814 232.532 182.814C234.569 182.814 236.571 182.673 238.555 182.47C236.563 146.361 206.659 117.691 170.052 117.691C132.156 117.691 101.436 148.412 101.436 186.307C101.436 194.164 102.784 201.699 105.216 208.727C139.55 198.171 166.028 169.689 173.644 134.163Z"
          fill="white"
        />
        <path
          opacity="0.2"
          d="M173.644 134.163C178.938 161.87 203.283 182.814 232.532 182.814C234.569 182.814 236.571 182.673 238.555 182.47C236.563 146.361 206.659 117.691 170.052 117.691C132.156 117.691 101.436 148.412 101.436 186.307C101.436 194.164 102.784 201.699 105.216 208.727C139.55 198.171 166.028 169.689 173.644 134.163Z"
          fill={primary}
        />
        <path
          d="M117.52 155.52C118.42 151.23 120.241 147.953 123.319 145.421C126.193 143.059 129.112 142.831 131.095 144.648C133.115 146.497 133.001 149.141 130.562 152.166C128.535 154.68 126.12 156.875 125.003 160.041C124.332 161.94 122.755 163.229 120.545 162.899C118.006 162.521 117.341 160.572 117.277 158.381C117.244 157.274 117.461 156.153 117.52 155.52Z"
          fill="white"
        />
        <path
          d="M133.853 138.34C134.55 136.261 138.007 134.689 140.269 135.354C141.984 135.858 142.682 137.06 142.144 138.769C141.486 140.861 138.104 142.357 135.843 141.611C134.187 141.065 133.467 139.939 133.853 138.34Z"
          fill="white"
        />
      </svg>
    </div>
  );
};

export default FastMovementError;
