import React, { forwardRef, InputHTMLAttributes } from 'react';
import InputMask from 'react-input-mask';

import { FieldWrapper, FieldWrapperProps } from './FieldWrapper';
import { ScInput } from './styled';

export type InputBlockProps = InputHTMLAttributes<HTMLInputElement> &
  FieldWrapperProps & { disabled?: boolean; searchBorder?: boolean };

export const InputBlock = forwardRef<HTMLInputElement, InputBlockProps>(
  ({ error, label, disabled, ...rest }, ref) => (
    <FieldWrapper error={error} label={label}>
      <ScInput {...rest} error={error != null} ref={ref} />
    </FieldWrapper>
  )
);

export type MaskedInputBlockProps = InputBlockProps & {
  mask: string | (string | RegExp)[];
};

export const MaskedInputBlock = forwardRef<
  HTMLInputElement,
  MaskedInputBlockProps
>(({ error, disabled, mask, value, onChange, label, ...rest }, ref) => (
  <FieldWrapper label={label} error={error}>
    <InputMask
      mask={mask}
      value={value}
      onChange={onChange}
      {...rest}
      children={<ScInput error={error != null} ref={ref} disabled={disabled} />}
    />
  </FieldWrapper>
));
