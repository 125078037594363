import styled, { css } from 'styled-components/macro';
import { device } from 'components/Global/MediaQuery';

export const ScCameraModalBlock = styled.div`
  .title {
    font-weight: 700;
    font-size: 24px;
    line-height: 1.1;
    color: ${({ theme }) => theme.baseContent};
    padding: 36px 36px 24px;
    @media (${device.mobileL}) {
      font-size: 18px;
      padding: 12px;
    }
  }
`;

export const ScManualCameraModalBlock = styled.div<{
  fullOnMobileScreen?: boolean;
}>`
  max-width: 620px;
  width: 100%;
  & > h5 {
    font: ${({ theme }) => `700 24px/26px ${theme.mainFontFamilyName}`};
    color: ${({ theme }) => theme.baseContent};
    padding: 36px 54px 24px 32px;
    @media (${device.mobileL}) {
      padding: 44px 20px 12px 12px;
      font-size: 18px;
    }
  }
  ${({ fullOnMobileScreen }) =>
    fullOnMobileScreen &&
    css`
      @media (${device.mobileXL}) {
        position: relative;
        height: 100dvh;
        width: 100vw;
        .title {
          position: absolute;
          width: 100%;
          top: 44px;
          z-index: 99;
        }
        .camera {
          max-height: 83dvh;
          display: flex;
          flex-direction: column;
          height: 100%;
        }
      }
    `}
`;
