import * as React from 'react';
import type { SVGProps } from 'react';
const SvgArrowBack = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={10}
      height={16}
      fill="none"
      viewBox="0 0 10 16"
      {...props}
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M10 2 4 8l6 6-2 2-8-8 8-8 2 2Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgArrowBack;
