import React, { lazy } from 'react';

import demoRoute from 'modules/demo/route';
import documentsRoute from 'modules/documents/route';

import InternalError from 'components/internal/InternalError';
import ResultWithRedirect from 'modules/result';

const NotFoundBlock = lazy(() => import('components/internal/NotFound'));

const route = [
  {
    path: 'providers',
    errorElement: <InternalError />,
    async lazy() {
      let { providersLoader, ProvidersView } = await import(
        'modules/providers/route'
      );
      return {
        loader: providersLoader,
        Component: ProvidersView,
      };
    },
  },
  {
    path: 'documents',
    children: documentsRoute,
  },
  {
    path: 'liveness',
    async lazy() {
      let { livenessLoader, LivenessView } = await import(
        'modules/liveness/route'
      );
      return {
        loader: livenessLoader,
        Component: LivenessView,
      };
    },
  },
  {
    path: 'request',
    async lazy() {
      let { requestLoader, RequestView } = await import(
        'modules/request/route'
      );
      return {
        loader: requestLoader,
        Component: RequestView,
      };
    },
  },
  {
    path: 'result',
    element: <ResultWithRedirect />,
  },
  {
    path: 'demo',
    children: demoRoute,
  },
  {
    path: '*',
    element: <NotFoundBlock />,
  },
];

export default route;
