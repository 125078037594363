import { Theme } from 'themes/contracts';
import { ThemeMediaConfig } from 'api/verification';

export let setTheme: undefined | ((theme: Theme) => void) = undefined;

export const ThemePromise = new Promise<Theme>(
  (resolve) => void (setTheme = resolve)
);

export function parseConfigTheme(localTheme: string) {
  try {
    const themeConfigCurrent: {
      config: Partial<Theme> | undefined;
      media: Partial<ThemeMediaConfig> | undefined;
    } = JSON.parse(localTheme);

    return themeConfigCurrent;
  } catch (e) {
    return undefined;
  }
}

export function mutateDefaultTheme(
  defaultTheme: Theme,
  themeConfig?: Partial<Theme>
) {
  return themeConfig ? { ...defaultTheme, ...themeConfig } : defaultTheme;
}
