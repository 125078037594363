import { TOptions } from 'i18next';
import * as Yup from 'yup';
import { Validate } from 'react-hook-form';

export type ValidatorMessage = string | [string, TOptions];

export type Validator<T = any, VS = any> = (
  value: T | undefined,
  values: VS,
  length?: number
) => ValidatorMessage | void;

export function composeValidators(
  ...validators: Validate<string>[]
): Validate<string> {
  return (...args) => {
    for (let validator of validators) {
      const error = validator(...args);

      if (error != null) return error;
    }
  };
}

export const requiredFactory: (msg: string) => Validate<string> =
  (msg) => (value) => {
    return !value || value.length === 0 ? msg : undefined;
  };

export const required = requiredFactory('validation:required');

export function regex<T extends string>(re: RegExp, msg: string): Validate<T> {
  return (value) =>
    value == null ? undefined : re.test(value) ? undefined : msg;
}

export function inverseRegex<T extends string>(
  re: RegExp,
  msg: string
): Validate<T> {
  return (value) =>
    value == null ? undefined : re.test(value) ? msg : undefined;
}

export function lt<T extends string>(
  param: number,
  msg: ValidatorMessage
): Validator<T> {
  return (value) =>
    value == null ? undefined : value.length < param ? undefined : msg;
}

export const lt256 = lt(256, ['validation:string.max', { val: 255 }]);

export function gt<T extends string>(
  param: number,
  msg: ValidatorMessage
): Validator<T> {
  return (value) =>
    value == null ? undefined : value.length > param ? undefined : msg;
}

// eslint-disable-next-line'
export const dateRe =
  /^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$/i;

export const dateRegexValidator = regex(dateRe, 'validation:invalid');

export const dateValidator: Validate<string> = (value) => {
  if (value == null) return;

  if (value.length !== 10) return 'validation:invalid';

  let y = Number(value.slice(0, 4)),
    m = Number(value.slice(5, 7)),
    d = Number(value.slice(8, 10));

  if (isNaN(y) || isNaN(m) || isNaN(d)) return 'validation:invalid';

  // Assume not leap year by default (note zero index for Jan)
  const daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  // If evenly divisible by 4 and not evenly divisible by 100,
  // or is evenly divisible by 400, then a leap year
  if ((!(y % 4) && y % 100) || !(y % 400)) {
    daysInMonth[1] = 29;
  }

  if (!(!/\D/.test(String(d)) && d > 0 && d <= daysInMonth[--m])) {
    return 'validation:invalid';
  }
};

export const latinRe = /.*[A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff].*/;
export const cyrillicRe = /.*[\u0400-\u04FF].*/;

export const latinRegexValidator = inverseRegex(
  cyrillicRe,
  'validation:invalid'
);
export const cyrillicRegexValidator = inverseRegex(
  latinRe,
  'validation:invalid'
);

export function useFieldRules() {
  const REQUIRED = 'validation:required';

  return {
    REQUIRED,
    requiredNum: Yup.number().required(REQUIRED),
    requiredStr: Yup.string().required(REQUIRED),
  };
}

export const emailRe =
  // eslint-disable-next-line
  /^((([a-z]|\d|[!#$%&'*+\-\/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#$%&'*+\-\/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i;

export const email = regex(emailRe, 'validation:email');

export const mailValidator = composeValidators(required, email);
