import { keyframes } from 'styled-components/macro';

export const AnimationHideKeyframes = keyframes`
  0%{
    transform: translateX(100%)
  } 
  4%{
    transform: translateX(0);
    opacity: 1;
  }
  80%{
    opacity: 1;
  }
  100%{
    opacity: 0
  }
`;

export const AnimationTopHideKeyframes = keyframes`
  0%{
    transform: translateY(-100%)
  } 
  4%{
    transform: translateY(0);
    opacity: 1;
  }
  80%{
    opacity: 1;
  }
  100%{
    opacity: 0
  }
`;
