import * as React from 'react';
import type { SVGProps } from 'react';
const SvgCheckIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={108}
      height={108}
      fill="none"
      viewBox="0 0 108 108"
      {...props}
    >
      <path
        fill="#00DF53"
        fillRule="evenodd"
        d="M54 0C24.177 0 0 24.177 0 54s24.177 54 54 54 54-24.177 54-54S83.823 0 54 0Zm23.406 44.87a4.91 4.91 0 1 0-7.54-6.284L48.757 63.91 37.834 52.984a4.91 4.91 0 0 0-6.941 6.941L45.62 74.653a4.908 4.908 0 0 0 7.241-.33L77.406 44.87Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgCheckIcon;
