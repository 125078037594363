import React from 'react';
import { useTheme } from 'hooks/theme';

const InvalidMovementsError = () => {
  const { primary, primaryActive, error, errorHover, base_100 } = useTheme();
  return (
    <div style={{ maxWidth: 304, width: '100%', height: 'auto' }}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 304 273"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M73.8241 146.646C114.196 146.646 146.924 113.918 146.924 73.546C146.924 33.174 114.196 0.445999 73.8241 0.445999C33.4521 0.445999 0.724091 33.174 0.724091 73.546C0.724091 113.918 33.4521 146.646 73.8241 146.646Z"
          fill={errorHover}
        />
        <path
          d="M73.8249 141.971C111.615 141.971 142.25 111.336 142.25 73.546C142.25 35.7559 111.615 5.121 73.8249 5.121C36.0348 5.121 5.3999 35.7559 5.3999 73.546C5.3999 111.336 36.0348 141.971 73.8249 141.971Z"
          fill={base_100}
        />
        <path
          d="M41.8381 81.412C46.3115 81.412 49.9379 77.802 49.9379 73.348C49.9379 68.895 46.3115 65.284 41.8381 65.284C37.3647 65.284 33.7383 68.895 33.7383 73.348C33.7383 77.802 37.3647 81.412 41.8381 81.412Z"
          fill={primaryActive}
        />
        <path
          d="M104.055 81.412C108.529 81.412 112.155 77.802 112.155 73.348C112.155 68.895 108.529 65.284 104.055 65.284C99.582 65.284 95.956 68.895 95.956 73.348C95.956 77.802 99.582 81.412 104.055 81.412Z"
          fill={primaryActive}
        />
        <path
          d="M70.3821 114.996C53.2033 114.996 39.3179 101.172 39.3179 84.07V62.714C39.3179 45.611 53.2033 31.7871 70.3821 31.7871C87.561 31.7871 101.446 45.611 101.446 62.714V84.07C101.535 101.084 87.561 114.996 70.3821 114.996Z"
          fill={primary}
        />
        <path
          d="M50.0638 69.892C52.1285 69.892 53.8022 68.226 53.8022 66.17C53.8022 64.115 52.1285 62.448 50.0638 62.448C47.9992 62.448 46.3254 64.115 46.3254 66.17C46.3254 68.226 47.9992 69.892 50.0638 69.892Z"
          fill="#2B292D"
        />
        <path
          d="M74.9872 69.892C77.0518 69.892 78.7255 68.226 78.7255 66.17C78.7255 64.115 77.0518 62.448 74.9872 62.448C72.9225 62.448 71.2488 64.115 71.2488 66.17C71.2488 68.226 72.9225 69.892 74.9872 69.892Z"
          fill="#2B292D"
        />
        <path
          d="M66.7636 77.763L63.8814 79.2C62.8282 79.724 61.5938 79.748 60.5191 79.265L57.1833 77.763"
          stroke="#2B292D"
          strokeWidth="1.77919"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M46.1475 83.716C46.1475 83.716 62.1691 99.223 78.6358 83.716"
          stroke="#2B292D"
          strokeWidth="1.77919"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <mask
          id="mask0_7_149"
          maskUnits="userSpaceOnUse"
          x="39"
          y="31"
          width="63"
          height="84"
        >
          <path
            d="M70.3816 114.997C53.2028 114.997 39.3174 101.173 39.3174 84.071V62.715C39.3174 45.612 53.2028 31.7881 70.3816 31.7881C87.56 31.7881 101.446 45.612 101.446 62.715V84.071C101.535 101.085 87.56 114.997 70.3816 114.997Z"
            fill={primary}
          />
        </mask>
        <g mask="url(#mask0_7_149)">
          <path
            d="M102.319 64.48L89.05 51.27C69.768 58.081 41.5712 51.27 41.5712 51.27L29.9607 65.202C29.9607 45.284 46.1324 29.1836 66.1398 29.1836C76.0916 29.1836 85.214 33.2086 91.745 39.711C98.172 46.109 102.111 54.779 102.319 64.48Z"
            fill={primary}
          />
          <path
            opacity="0.8"
            d="M102.319 64.48L89.05 51.27C69.768 58.081 41.5712 51.27 41.5712 51.27L29.9607 65.202C29.9607 45.284 46.1324 29.1836 66.1398 29.1836C76.0916 29.1836 85.214 33.2086 91.745 39.711C98.172 46.109 102.112 54.779 102.319 64.48Z"
            fill="white"
          />
        </g>
        <path
          d="M199.75 105.951C200.449 108.283 201.611 110.012 203.433 111.269C205.135 112.442 206.761 112.431 207.774 111.333C208.806 110.215 208.618 108.756 207.127 107.196C205.887 105.9 204.447 104.798 203.681 103.097C203.22 102.077 202.287 101.438 201.079 101.724C199.691 102.052 199.415 103.162 199.481 104.378C199.515 104.992 199.688 105.603 199.75 105.951Z"
          fill="white"
        />
        <path
          d="M209.595 114.696C210.079 115.814 212.065 116.523 213.287 116.049C214.213 115.689 214.543 114.991 214.165 114.07C213.702 112.943 211.76 112.273 210.543 112.792C209.652 113.172 209.307 113.829 209.595 114.696Z"
          fill="white"
        />
        <path
          d="M303.906 54.222C304.197 28.0279 283.198 6.5576 257.004 6.2669C230.809 5.9761 209.339 26.9749 209.048 53.169C208.757 79.363 229.756 100.833 255.951 101.124C282.145 101.415 303.615 80.416 303.906 54.222Z"
          fill="white"
        />
        <path
          d="M257.69 94.157C280.035 93.496 297.614 74.845 296.952 52.499C296.29 30.1539 277.639 12.5758 255.294 13.2375C232.948 13.8991 215.37 32.5501 216.032 54.895C216.694 77.241 235.345 94.819 257.69 94.157Z"
          fill={error}
        />
        <path
          d="M275.719 34.4634C274.36 33.1046 272.158 33.1046 270.8 34.4634L256.486 48.778L242.172 34.4634C240.813 33.1046 238.611 33.1046 237.253 34.4634C235.894 35.8218 235.894 38.024 237.253 39.383L251.567 53.697L237.253 68.011C235.894 69.369 235.894 71.572 237.253 72.93C237.932 73.61 238.822 73.949 239.712 73.949C240.602 73.949 241.493 73.61 242.172 72.93L256.486 58.616L270.8 72.93C271.479 73.61 272.369 73.949 273.259 73.949C274.149 73.949 275.039 73.61 275.719 72.93C277.077 71.572 277.077 69.369 275.719 68.011L261.405 53.697L275.719 39.383C277.077 38.024 277.077 35.8218 275.719 34.4634Z"
          fill="white"
        />
        <path
          d="M208.763 187.244H180.951C207.966 178.025 227.403 152.445 227.403 122.309C227.403 121.021 227.367 119.741 227.297 118.47L162.369 63.085L93.922 144.734C100.817 164.667 116.648 180.428 136.622 187.244H108.548C83.6471 187.244 63.4614 207.43 63.4614 232.331V272.422H253.85V232.331C253.85 207.43 233.664 187.244 208.763 187.244Z"
          fill="white"
        />
        <path
          opacity="0.76"
          d="M208.763 187.244H180.951C207.966 178.025 227.403 152.445 227.403 122.309C227.403 121.021 227.367 119.741 227.297 118.47L162.369 63.085L93.922 144.734C100.817 164.667 116.648 180.428 136.622 187.244H108.548C83.6471 187.244 63.4614 207.43 63.4614 232.331V272.422H253.85V232.331C253.85 207.43 233.664 187.244 208.763 187.244Z"
          fill={primary}
        />
        <path
          d="M201.195 132.735C201.235 135.076 203.165 136.944 205.509 136.903C207.852 136.865 209.72 134.933 209.681 132.588C209.64 130.246 207.707 128.378 205.364 128.419C203.022 128.457 201.155 130.393 201.195 132.735Z"
          fill="white"
        />
        <path
          d="M173.761 132.735C173.721 135.076 171.791 136.944 169.447 136.903C167.104 136.865 165.236 134.933 165.275 132.588C165.316 130.246 167.249 128.378 169.591 128.419C171.934 128.457 173.801 130.393 173.761 132.735Z"
          fill="white"
        />
        <path
          d="M197.389 146.455C196.299 146.364 195.343 147.174 195.253 148.263C194.848 153.151 191.045 156.096 187.478 156.096C183.912 156.096 180.109 153.151 179.704 148.263C179.612 147.174 178.657 146.364 177.567 146.455C176.473 146.535 175.669 147.502 175.759 148.591C176.353 155.752 181.065 160.054 187.478 160.054C194.15 160.054 198.603 155.752 199.198 148.591C199.287 147.503 198.484 146.535 197.389 146.455Z"
          fill="white"
        />
        <path
          d="M162.381 70.164C167.674 97.871 192.019 118.815 221.268 118.815C223.305 118.815 225.308 118.674 227.291 118.471C225.3 82.362 195.395 53.692 158.788 53.692C120.893 53.692 90.172 84.413 90.172 122.308C90.172 130.165 91.52 137.7 93.952 144.728C128.286 134.172 154.765 105.69 162.381 70.164Z"
          fill="white"
        />
        <path
          opacity="0.2"
          d="M162.381 70.164C167.674 97.871 192.019 118.815 221.268 118.815C223.305 118.815 225.308 118.674 227.291 118.471C225.3 82.362 195.395 53.692 158.788 53.692C120.893 53.692 90.172 84.413 90.172 122.308C90.172 130.165 91.52 137.7 93.952 144.728C128.286 134.172 154.765 105.69 162.381 70.164Z"
          fill={primary}
        />
        <path
          d="M106.256 91.519C107.156 87.229 108.977 83.952 112.056 81.42C114.929 79.058 117.848 78.83 119.832 80.647C121.851 82.496 121.737 85.14 119.298 88.165C117.271 90.679 114.856 92.874 113.739 96.04C113.068 97.939 111.491 99.228 109.281 98.898C106.742 98.52 106.077 96.571 106.013 94.38C105.98 93.273 106.197 92.152 106.256 91.519Z"
          fill="white"
        />
        <path
          d="M122.589 74.34C123.287 72.261 126.743 70.689 129.006 71.354C130.72 71.858 131.419 73.06 130.88 74.769C130.222 76.861 126.84 78.357 124.579 77.611C122.923 77.065 122.203 75.939 122.589 74.34Z"
          fill="white"
        />
      </svg>
    </div>
  );
};

export default InvalidMovementsError;
