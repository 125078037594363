import styled from 'styled-components/macro';
import successBadge from 'assets/svg/successBadge.svg';
import { hexToRGB } from 'helpers/common';

export const ScDocumentUploadForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  text-align: center;
  .documentIllustration {
    width: 100%;
    &.passport {
      max-width: 275px;
    }
    &.intPassport {
      max-width: 212px;
    }
    &.idCard {
      max-width: 326px;
    }
    svg {
      width: 100%;
    }
  }
  & > h4 {
    font: ${({ theme }) => `700 28px/32px ${theme.mainFontFamilyName}`};
  }
`;

export const ScDocumentMask = styled.div<{
  widthPercent: number;
  aspectRatio: number;
}>`
  display: flex;
  /*using box-shadow to create background*/
  -webkit-box-shadow: 0 0 0 50000px rgba(0, 0, 0, 0.7);
  box-shadow: 0 0 0 50000px rgba(0, 0, 0, 0.7);
  /*change height and position as needed*/
  z-index: 10;
  border-radius: 8px;
  width: ${({ widthPercent }) => `${widthPercent}%`};
  height: 0;
  padding-bottom: ${({ widthPercent, aspectRatio }) =>
    `calc(${widthPercent}%/(1/${aspectRatio}))`};
  transform: scale(1.1);

  svg {
    width: 100%;
    height: 100%;
    position: absolute;
    border: 4px solid #ffffff;
    border-radius: 8px;

    &.success {
      border: 4px solid ${({ theme }) => theme.successActive};
      background-color: ${({ theme }) => hexToRGB(theme.successActive, 0.25)};
      background-image: url(${successBadge});
      background-repeat: no-repeat;
      background-position: 95% 5%;

      & path {
        fill: ${({ theme }) => theme.successActive};
      }
    }
    &.rotated {
      transform: rotateY(180deg);
      background-color: transparent;
      transition: 3s ease-out;
      & path {
        fill: transparent;
      }
    }
  }
`;
