import copy from 'copy-to-clipboard';

import { TooltipTypes } from 'modules/globalTooltip';
import { capitalize } from 'helpers/common';
import { showTooltip } from 'helpers/tooltip';
import { translationFactory } from 'helpers/trans';

export const copyWithTooltip: typeof copy = (text, options) => {
  const copied = copy(text, options);
  if (copied) {
    showTooltip({
      type: TooltipTypes.SUCCESS,
      translate: true,
      body: translationFactory('common:copied', { format: capitalize }),
    });
  }

  return copied;
};
