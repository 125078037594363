import React, { FC, lazy, useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';

import { Routes } from 'router/routes';
import { GlobalStyles } from 'components/styles/GlobalStyles';
import { default as defaultTheme } from 'themes/dark';

import {
  mutateDefaultTheme,
  parseConfigTheme,
  ThemePromise,
} from 'helpers/theme';

const InternalError = lazy(() => import('components/internal/InternalError'));
const ErrorBoundary = lazy(() => import('components/internal/ErrorBoundary'));

const App: FC = () => {
  const localTheme = window.localStorage.getItem('theme');
  const theme = localTheme ? parseConfigTheme(localTheme) : undefined;

  const [themeConfig, setThemeConfig] = useState(
    mutateDefaultTheme(defaultTheme, {
      ...theme?.config,
      ...theme?.media,
    })
  );

  useEffect(() => {
    (async () => {
      const theme = await ThemePromise;

      setThemeConfig(theme);
    })();
  }, []);

  return (
    <ThemeProvider theme={themeConfig}>
      <GlobalStyles />

      <ErrorBoundary fallback={<InternalError />}>
        <Routes />
      </ErrorBoundary>
    </ThemeProvider>
  );
};

export default App;
