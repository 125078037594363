import * as React from 'react';
import type { SVGProps } from 'react';
const SvgStableBadge = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={28}
      height={28}
      fill="none"
      viewBox="0 0 28 28"
      {...props}
    >
      <path
        fill="#fff"
        d="M14 9.333A4.665 4.665 0 0 0 9.334 14 4.665 4.665 0 0 0 14 18.667 4.665 4.665 0 0 0 18.667 14 4.665 4.665 0 0 0 14 9.333Zm10.43 3.5a10.493 10.493 0 0 0-9.263-9.263V1.167h-2.333V3.57a10.493 10.493 0 0 0-9.264 9.263H1.167v2.334H3.57a10.493 10.493 0 0 0 9.264 9.263v2.403h2.333V24.43a10.494 10.494 0 0 0 9.263-9.263h2.404v-2.334H24.43ZM14 22.167A8.16 8.16 0 0 1 5.834 14 8.16 8.16 0 0 1 14 5.833 8.16 8.16 0 0 1 22.167 14 8.16 8.16 0 0 1 14 22.167Z"
      />
    </svg>
  );
};
export default SvgStableBadge;
