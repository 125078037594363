import { AxiosResponse, AxiosResponseTransformer } from 'axios';
import { camelize, isObject, toSnake } from 'helpers/common';

export interface ServerError {
  data: any;
  errorCode: number;
  message: string;
  result: boolean;
}

export type AxiosServerError = AxiosResponse<ServerError>;

export enum ContentTypes {
  json = 'application/json',
  csv = 'text/csv; charset=utf-8',
  xlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  txt = 'text/plain; charset=utf-8',
}

export function getResponseCamelization(
  blacklists?: string[][]
): AxiosResponseTransformer {
  return function (data, headers) {
    if (
      headers != null &&
      headers['content-type'] != null &&
      headers['content-type'] === ContentTypes.json
    ) {
      let jsonResp;

      try {
        jsonResp = JSON.parse(data);
      } catch (e) {
        return {};
      }

      return camelize(jsonResp, blacklists);
    }

    return data;
  };
}

export function constructParams(params: (string | object)[]) {
  const readyParams: string[] = [];

  params.forEach((param) => {
    if (isObject(param)) {
      Object.keys(param).forEach((k) => {
        const paramKeys = (param as any)[k] as (string | object)[];

        if (!paramKeys || paramKeys.length === 0) return;

        readyParams.push(k);
        constructParams(paramKeys).forEach((p) => {
          readyParams.push(`${k}.${p}`);
        });
      });
    } else {
      readyParams.push(param as string);
    }
  });

  return readyParams.map((p) => toSnake(p));
}

export function isAxiosServerError(obj: any): obj is AxiosServerError {
  return (
    isObject(obj) &&
    obj.hasOwnProperty('data') &&
    obj.data.hasOwnProperty('errorCode') &&
    obj.data.hasOwnProperty('message')
  );
}

export function parseErrorCode(e: any) {
  return isAxiosServerError(e) ? e.data.errorCode : -1;
}

export function parseErrorMessage(e: any) {
  return isAxiosServerError(e) ? e.data.message : undefined;
}

export function parseErrorData<T = any>(e: any): T {
  return isAxiosServerError(e) ? e.data.data : undefined;
}
