import styled from 'styled-components/macro';
import { device } from 'components/Global/MediaQuery';

export const ScRequirementsList = styled.div`
  .requirements {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 24px;
    .requirement {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
      font: ${({ theme }) => `700 16px/25px ${theme.mainFontFamilyName}`};
      color: ${({ theme }) => theme.baseContent};
      position: relative;
      & > svg {
        min-width: 28px;
        min-height: 28px;
        & > path {
          fill: ${({ theme }) => theme.baseContent};
        }
      }
      @media (${device.mobileL}) {
        font-size: 12px;
      }
    }
    }
  }
`;
