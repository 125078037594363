import * as React from 'react';
import type { SVGProps } from 'react';
const SvgDemoArrow = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={28}
      height={36}
      fill="none"
      viewBox="0 0 28 36"
      {...props}
    >
      <path
        fill="#fff"
        d="M.41 16.931 14.81.932c.307-.34.736-.531 1.19-.531h9.6c.63 0 1.203.371 1.462.947.256.58.15 1.254-.272 1.722L13.354 18 26.79 32.927A1.603 1.603 0 0 1 25.6 35.6H16c-.454 0-.883-.195-1.19-.528l-14.4-16a1.601 1.601 0 0 1 0-2.14Z"
      />
    </svg>
  );
};
export default SvgDemoArrow;
