import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { FilePreview } from 'components/form/fileUpload/Preview';
import { Content } from 'components/Content';
import { Button } from 'components/Button';
import { DocumentType } from 'api/document';
import { ScFormUpload } from 'components/form/fileUpload/styled';

import { useFormData } from '../../context';

import { RequirementsList } from './RequirementsList';
import { ScPreviewList } from './styled';

interface CheckDocumentsProps {
  types: DocumentType[];
  prevStep: () => void;
}

export const CheckDocuments: FC<CheckDocumentsProps> = ({
  types,
  prevStep,
}) => {
  const { t } = useTranslation('documents');

  const {
    data: { documentType },
    setFormValues,
  } = useFormData();

  const handleBack = useCallback(() => {
    setFormValues({ documents: {} });
    prevStep();
  }, [setFormValues, prevStep]);

  const type = types.find(({ category }) => category === documentType);

  if (type == null) return null;

  const files = new Array(type.maxPagesCount).fill(0).map((_, index) => {
    return {
      prefixField: `page${index}`,
    };
  });

  return (
    <Content
      title={t('checkDocuments.title')}
      button={
        <>
          <Button transparent onClick={handleBack} type="button">
            {t('checkDocuments.changePhoto')}
          </Button>
          <Button type="submit">{t('checkDocuments.button')}</Button>
        </>
      }
      flexGap={24}
    >
      <ScPreviewList>
        {files.map((item, index) => (
          <PreviewArea key={index} fieldName={item.prefixField} />
        ))}
      </ScPreviewList>
      <RequirementsList />
    </Content>
  );
};

interface PreviewAreaProps {
  fieldName: string;
}

const PreviewArea: FC<PreviewAreaProps> = ({ fieldName }) => {
  const {
    data: { documents },
  } = useFormData();

  return (
    <ScFormUpload>
      {documents[fieldName] && (
        <FilePreview file={documents[fieldName] as File} magnifier />
      )}
    </ScFormUpload>
  );
};
