import * as React from 'react';
import type { SVGProps } from 'react';
const SvgCornersBadge = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={28}
      height={28}
      fill="none"
      viewBox="0 0 28 28"
      {...props}
    >
      <path
        fill="#fff"
        d="M3.5 24.5h2.333v-2.333H3.5V24.5ZM5.833 5.25H3.5v5.25h2.333V5.25ZM3.5 24.5h2.333v-7H3.5v7Zm2.333 0H10.5v-2.333H5.833V24.5Zm0-21H3.5v2.333h2.333V3.5Zm4.667 0H5.833v2.333H10.5V3.5Zm11.667 0H17.5v2.333h4.667V3.5Zm0 18.667H24.5V17.5h-2.333v4.667Zm0-18.667v2.333H24.5V3.5h-2.333Zm0 7H24.5V5.25h-2.333v5.25Zm-4.667 14h5.25v-2.333H17.5V24.5Zm4.667 0H24.5v-2.333h-2.333V24.5Z"
      />
    </svg>
  );
};
export default SvgCornersBadge;
