import * as React from 'react';
import type { SVGProps } from 'react';
const SvgVerifiedBadge = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={28}
      height={28}
      fill="none"
      viewBox="0 0 28 28"
      {...props}
    >
      <path
        fill="#fff"
        d="m26.833 13.988-2.846-3.255.396-4.305-4.211-.956-2.205-3.722L14 3.453 10.033 1.75 7.828 5.472l-4.211.945.396 4.316-2.846 3.255 2.846 3.255-.396 4.317 4.211.957 2.205 3.733L14 24.535l3.967 1.703 2.205-3.721 4.211-.957-.396-4.305 2.846-3.267Zm-4.608 1.727-.653.758.093.992.21 2.275-2.217.502-.98.221-.513.864-1.155 1.96-2.077-.899-.933-.396-.922.396-2.076.899-1.155-1.949-.514-.863-.98-.222-2.216-.501.21-2.287.093-.992-.653-.758L4.282 14l1.505-1.727.653-.758-.105-1.003-.21-2.264 2.217-.501.98-.222.513-.863 1.155-1.96 2.077.898.933.397.922-.397 2.076-.898 1.155 1.96.514.863.98.222 2.216.501-.21 2.275-.093.992.653.758 1.505 1.715-1.493 1.727Z"
      />
      <path
        fill="#fff"
        d="m11.772 16.042-2.707-2.719-1.727 1.739 4.434 4.445 8.563-8.587-1.727-1.738-6.836 6.86Z"
      />
    </svg>
  );
};
export default SvgVerifiedBadge;
