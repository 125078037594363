interface RGBColor {
  r: number;
  g: number;
  b: number;
}

function getLuminance(rgbColor: RGBColor): number {
  const [r, g, b] = (Object.keys(rgbColor) as (keyof RGBColor)[]).map((key) => {
    const channel = rgbColor[key] / 255;
    return channel <= 0.03928
      ? channel / 12.92
      : Math.pow((channel + 0.055) / 1.055, 2.4);
  });

  return parseFloat((0.2126 * r + 0.7152 * g + 0.0722 * b).toFixed(3));
}

export function hexToRgb(hex: string) {
  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

export function determineTextColor(color: RGBColor): string {
  if (getLuminance(color) > Math.sqrt(1.05 * 0.05) - 0.05) {
    return '#000000';
  } else {
    return '#FFFFFF';
  }
}
