import React, { useState, createContext, useContext, FC } from 'react';
import { DocumentCategory } from 'helpers/enums';

import { DocumentsUploadForm } from '../index';

const initialValue = {
  documentType: undefined,
  documents: {},
  manual: false,
};

interface dataContext {
  documentType?: DocumentCategory;
  documents: { [key: string]: File | undefined };
  manual: boolean;
}

interface FormContextParams {
  data: dataContext;
  setFormValues: (values: Partial<DocumentsUploadForm>) => void;
  setDocumentFile: (
    value: File | undefined,
    page: string,
    manual?: boolean
  ) => void;
}

export const FormContext = createContext<FormContextParams>({
  data: initialValue,
  setFormValues: () => {},
  setDocumentFile: () => {},
});

export const FormProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [data, setData] = useState<dataContext>(initialValue);

  const setFormValues = (values: Partial<dataContext>) => {
    setData((prevValues) => ({
      ...prevValues,
      ...values,
    }));
  };

  const setDocumentFile = (
    value: File | undefined,
    page: string,
    manual = false
  ) => {
    setData((prevState) => ({
      ...prevState,
      documents: {
        ...prevState.documents,
        [page]: value,
      },
      manual,
    }));
  };

  return (
    <FormContext.Provider value={{ data, setFormValues, setDocumentFile }}>
      {children}
    </FormContext.Provider>
  );
};

export const useFormData = () => useContext(FormContext);
