import React, { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ENV } from 'env/env.config';
import { Icon } from 'components/UI/Icon';
import { Button } from 'components/Button';
import { Content } from 'components/Content';
import { RedirectModal } from 'components/UI/RedirectBlock';
import {
  CameraError,
  DefaultError,
  TimeoutError,
} from 'components/ThemedComponents/Errors';

import { CameraErrors } from './constants';
import { ScCameraErrorScreen } from './styled';

interface CameraErrorScreenProps {
  tryAgainHandler: () => void;
  cameraError: CameraErrors;
  openManualHandler?: () => void;
}

export const CameraErrorScreen: FC<CameraErrorScreenProps> = ({
  tryAgainHandler,
  openManualHandler,
  cameraError,
}) => {
  const { t } = useTranslation('documents');

  const [showRedirectModal, setShowRedirectModal] = useState(false);

  const toggleRedirectModal = () =>
    setShowRedirectModal((prevState) => !prevState);

  const { image, title, text, buttons } = useMemo(() => {
    switch (cameraError) {
      case CameraErrors.CAMERA_NOT_AVAILABLE:
        return {
          image: <CameraError />,
          title: t('common:camera.cameraNotAvailable.title'),
          text: t('common:camera.cameraNotAvailable.text'),
          buttons: (
            <>
              <Button transparent onClick={tryAgainHandler} type="button">
                {t('common:tryAgain')}
              </Button>
              <Button onClick={toggleRedirectModal} type="button">
                {t('common:request.onAnotherDevice')}
              </Button>
            </>
          ),
        };
      case CameraErrors.TIMEOUT:
        return {
          image: <TimeoutError />,
          title: t('documentsUpload.errors.cameraTimeout.title'),
          text: ENV.IS_MOBILE
            ? t('documentsUpload.errors.cameraTimeout.text')
            : t('documentsUpload.errors.cameraTimeout.desktopText'),
          buttons: (
            <>
              {!ENV.IS_MOBILE ? (
                <>
                  <Button transparent onClick={tryAgainHandler} type="button">
                    {t('common:tryAgain')}
                  </Button>
                  <Button
                    onClick={toggleRedirectModal}
                    type="button"
                    icon={<Icon name="PhoneBadge" />}
                  >
                    {t('documentsUpload.inspectorForm.redirectMobile')}
                  </Button>
                  {openManualHandler && (
                    <Button
                      type="button"
                      autoWidth
                      asLink
                      onClick={openManualHandler}
                    >
                      {t('documentsUpload.inspectorForm.makeManualPhoto')}
                    </Button>
                  )}
                </>
              ) : (
                <>
                  {openManualHandler && (
                    <Button
                      transparent
                      onClick={openManualHandler}
                      type="button"
                    >
                      {t('documentsUpload.inspectorForm.makeManualPhoto')}
                    </Button>
                  )}
                  <Button onClick={tryAgainHandler} type="button">
                    {t('common:tryAgain')}
                  </Button>
                </>
              )}
            </>
          ),
        };
      case CameraErrors.NOT_SCANNED:
        return {
          image: <DefaultError />,
          title: t('documentsUpload.errors.notScanned.title'),
          text: ENV.IS_MOBILE
            ? t('documentsUpload.errors.notScanned.text')
            : t('documentsUpload.errors.notScanned.desktopText'),
          buttons: (
            <>
              <Button transparent onClick={tryAgainHandler} type="button">
                {t('common:tryAgain')}
              </Button>
              <Button
                onClick={toggleRedirectModal}
                type="button"
                icon={!ENV.IS_MOBILE && <Icon name="PhoneBadge" />}
              >
                {ENV.IS_MOBILE
                  ? t('common:request.onAnotherDevice')
                  : t('documentsUpload.inspectorForm.redirectMobile')}
              </Button>
              {openManualHandler && (
                <Button
                  type="button"
                  autoWidth
                  asLink
                  onClick={openManualHandler}
                >
                  {t('documentsUpload.inspectorForm.makeManualPhoto')}
                </Button>
              )}
            </>
          ),
        };
    }
  }, [cameraError, openManualHandler, tryAgainHandler, t]);

  return (
    <Content button={buttons}>
      <ScCameraErrorScreen>
        {image}
        <h3>{title}</h3>
        <div className="text">
          <p>{text}</p>
        </div>
      </ScCameraErrorScreen>

      {showRedirectModal && <RedirectModal onClose={toggleRedirectModal} />}
    </Content>
  );
};
