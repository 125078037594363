import * as React from 'react';
import type { SVGProps } from 'react';
const SvgUpload = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={25}
      height={24}
      fill="none"
      viewBox="0 0 25 24"
      {...props}
    >
      <path fill="#fff" d="M5.5 20h14v-2h-14v2Zm0-10h4v6h6v-6h4l-7-7-7 7Z" />
    </svg>
  );
};
export default SvgUpload;
