import React, { ButtonHTMLAttributes, FC, HTMLAttributes } from 'react';

import { ScButton, ScButtonProps, ScLink } from 'components/Button/styled';

interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
  target?: string;
  href?: string;
  to?: string;
  children: React.ReactNode;
  disabled?: boolean;
  isLoading?: boolean;
  onLinkClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  icon?: React.ReactNode;
}

const handleLinkWrapping = (
  Component: React.ComponentType<any>,
  props: ButtonProps
) => {
  const {
    href,
    to,
    target,
    children,
    disabled,
    isLoading,
    onLinkClick,
    icon,
    ...rest
  } = props;
  const button = (
    <Component disabled={disabled || isLoading} {...rest}>
      {icon && <span className="icon">{icon}</span>}
      {children}
    </Component>
  );

  if (href)
    return (
      <a
        className="button-link"
        href={href}
        target={target || '_blank'}
        rel={!target ? 'noopener noreferrer' : undefined}
      >
        {button}
      </a>
    );
  if (to)
    return (
      <ScLink to={to} onClick={onLinkClick}>
        {button}
      </ScLink>
    );
  return button;
};

export const Button: FC<
  ButtonProps &
    ScButtonProps & {
      component?: React.ComponentType<ButtonProps>;
    } & ButtonHTMLAttributes<HTMLButtonElement>
> = ({ component, ...rest }) => handleLinkWrapping(component || ScButton, rest);
