import { useCallback, useEffect, useRef, useState } from 'react';

export function useInterval(callback: () => void, delay: number) {
  const savedCallback = useRef<() => void>();

  // Remember the latest function.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    let id: NodeJS.Timer;
    function tick() {
      if (savedCallback.current == null) return;
      savedCallback.current();
    }
    if (delay !== null) {
      id = setInterval(tick, delay);
    }
    return () => {
      if (id) clearInterval(id);
    };
  }, [delay]);
}

export function useCountdown(
  initSeconds: number
): [number, (seconds: number) => void] {
  const [timeLeft, setTimeLeft] = useState(initSeconds);

  const resetCountdown = useCallback((seconds: number) => {
    setTimeLeft(seconds);
  }, []);

  useInterval(() => {
    if (!timeLeft) return;

    setTimeLeft(timeLeft - 1);
  }, 1000);

  return [timeLeft, resetCountdown];
}
