import * as React from 'react';
import type { SVGProps } from 'react';
const SvgCheckSuccess = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="#6CFB93"
        d="M19.189 4.045 9.012 14.378l-4.2-4.22-2.79 2.8 6.99 7.132L21.977 6.956 19.19 4.045Z"
      />
    </svg>
  );
};
export default SvgCheckSuccess;
