import * as React from 'react';
import type { SVGProps } from 'react';
const SvgDemoHeadMouth = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={80}
      height={21}
      fill="none"
      viewBox="0 0 80 21"
      {...props}
    >
      <path
        stroke="#2B292D"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={4.084}
        d="M2.713 2.197s36.776 35.597 74.574 0"
      />
    </svg>
  );
};
export default SvgDemoHeadMouth;
