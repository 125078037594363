import * as React from 'react';
import type { SVGProps } from 'react';
const SvgPassport = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={48}
      height={48}
      fill="none"
      viewBox="0 0 48 48"
      {...props}
    >
      <path
        fill="#fff"
        d="M40 4a2 2 0 0 1 2 2v36a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h32Zm-8 28H16v4h16v-4Zm-8-20a8 8 0 1 0 0 16.001A8 8 0 0 0 24 12Zm0 4a4 4 0 1 1 0 8 4 4 0 0 1 0-8Z"
      />
    </svg>
  );
};
export default SvgPassport;
