import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ScContainer } from 'components/styles/common';
import { useTheme } from 'hooks/theme';

import { ScFooter } from './styled';

export const Footer: FC = () => {
  const { copyright } = useTheme();

  const { t } = useTranslation('common');

  if (!copyright) return null;

  return (
    <ScFooter>
      <ScContainer>
        <p>
          {copyright} {t('footer.copyright')}
        </p>
      </ScContainer>
    </ScFooter>
  );
};
