import { Subscribable } from './common';

export class Camera extends Subscribable<
  (frame: HTMLVideoElement) => void | Promise<void>
> {
  video: HTMLVideoElement | null;
  stream: MediaStream | null;

  private currentTime: number;
  private readonly triggerCycle: boolean;
  private readonly constraints: MediaStreamConstraints;

  constructor(
    video: HTMLVideoElement | null,
    constraints: MediaStreamConstraints,
    triggerCycle: boolean = true
  ) {
    super();

    this.video = video;
    this.constraints = constraints;

    this.stream = null;
    this.currentTime = 0;
    this.triggerCycle = triggerCycle;
  }

  start = async () => {
    if (!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)) {
      throw new Error('not_available');
    }

    try {
      const stream = await navigator.mediaDevices.getUserMedia(
        this.constraints
      );

      this.setStream(stream);
    } catch (e) {
      throw new Error('failed_to_acquire');
    }
  };

  stop = () => {
    if (!this.stream) return;

    this.stream.getTracks().forEach((track) => {
      track.stop();
    });

    this.stream = null;
    this.currentTime = 0;
  };

  private setStream = (stream: MediaStream) => {
    this.stream = stream;

    if (this.video != null) this.initVideo();
  };

  setVideo = (video: HTMLVideoElement) => {
    this.video = video;

    this.initVideo();
  };

  private initVideo = () => {
    const video = this.video;
    const stream = this.stream;

    if (video == null || stream == null) return;

    video.srcObject = stream;
    video.onloadedmetadata = () => {
      video.play();

      if (this.triggerCycle) {
        this.initCycle();
      }
    };
  };

  private initCycle = () => {
    window.requestAnimationFrame(() => {
      this.cycle();
    });
  };

  private cycle = async () => {
    const video = this.video;

    if (video == null) return;

    let r = null;

    if (!video.paused && this.currentTime !== video.currentTime) {
      this.currentTime = video.currentTime;

      r = this.notify(video);
    }

    await r;

    this.initCycle();
  };
}
