import * as React from 'react';
import type { SVGProps } from 'react';
const SvgPassportMask = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={221}
      height={308}
      fill="none"
      viewBox="0 0 221 308"
      {...props}
    />
  );
};
export default SvgPassportMask;
