import styled from 'styled-components/macro';
import { device } from 'components/Global/MediaQuery';

export const ScTooltipForCamera = styled.div`
  padding: 12px 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background-color: ${({ theme }) => theme.base_400};
  border-radius: 12px;
  transition: 0.3s;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.2;
  .documentBadge {
    margin-right: 1.2rem;
    svg,
    img {
      width: 51px;
      height: auto;
    }
  }
  .successBadge {
    opacity: 0;
    margin-left: auto;
    path {
      fill: ${({ theme }) => theme.successActive};
    }
  }
  &.success {
    background-color: ${({ theme }) => theme.successBg};
    .documentBadge {
      opacity: 0.6;
    }
    .successBadge {
      opacity: 1;
    }
  }
  @media (${device.mobileL}) {
    padding: 8px 16px;
    font-size: 12px;
  }
`;
