import { useTranslation } from 'react-i18next';

import {
  errorHandlerFactoryWithTranslation,
  TranslationMapping,
} from 'helpers/error';
import { useShallowMemo } from 'hooks/common';

export const useErrorHandlerFactory = (
  mapping: TranslationMapping,
  ns?: string | string[]
) => {
  const { t } = useTranslation(ns);

  return useShallowMemo(
    () => errorHandlerFactoryWithTranslation(t, mapping),
    [mapping, t]
  );
};
