import React, {
  FC,
  useRef,
  useEffect,
  useMemo,
  useState,
  useCallback,
  Fragment,
} from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/Button';
import { Icon } from 'components/UI/Icon';
import { Loader } from 'components/Loader';

import { useCamera, useCameraPhoto, CameraStatus } from 'hooks/camera';

import {
  ScCameraButtonBlock,
  ScCameraModal,
  ScCanvas,
  ScVideo,
  ScVideoProps,
} from './styled';

const DEFAULT_CAPTURE_OPTIONS: MediaStreamConstraints = {
  audio: false,
  video: {
    height: 720,
    width: 1280,
    facingMode: 'user',
  },
};

export interface CameraProps extends ScVideoProps {
  getMediaStream?: (stream: MediaStream) => void;
  onNotAvailable?: () => void;
  captureOptions?: MediaStreamConstraints;
  withTakePhoto?: boolean;
  onSave?: (file: File[]) => void;
  onFrame?: (frame: HTMLVideoElement) => void | Promise<void>;
  children?: JSX.Element;
  mask?: JSX.Element;
  fullOnMobileScreen?: boolean;
  tooltipIllustration?: JSX.Element;
  applyPhotoWithoutPreview?: boolean;
}

export const Camera: FC<CameraProps> = ({
  getMediaStream,
  onNotAvailable,
  captureOptions,
  withTakePhoto,
  onSave,
  rounded,
  onFrame,
  mask,
  tooltipIllustration,
  children,
  fullOnMobileScreen,
  applyPhotoWithoutPreview,
  ...props
}) => {
  const [videoIsReady, setVideoIsReady] = useState(false);

  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const configCaptureOptions = useMemo(() => {
    return captureOptions == null
      ? DEFAULT_CAPTURE_OPTIONS
      : { ...DEFAULT_CAPTURE_OPTIONS, ...captureOptions };
  }, [captureOptions]);

  const [cameraStatus, camera] = useCamera(
    videoRef,
    configCaptureOptions,
    onFrame
  );

  useEffect(() => {
    if (cameraStatus === CameraStatus.NOT_AVAILABLE) {
      onNotAvailable && onNotAvailable();
    }
  }, [cameraStatus, onNotAvailable]);

  useEffect(() => {
    if (cameraStatus === CameraStatus.RUNNING && camera.stream != null) {
      getMediaStream && getMediaStream(camera.stream);
    }
  }, [cameraStatus, camera, getMediaStream]);

  const handleCanPlay = () => {
    if (videoRef.current == null) return;

    videoRef.current.play();
    setVideoIsReady(true);
  };

  const photoHandler = useCameraPhoto(
    videoRef,
    canvasRef,
    onSave || (() => {})
  );

  const handleTakePhoto = useCallback(() => {
    photoHandler.takePhoto();

    if (applyPhotoWithoutPreview) {
      const timeout = setTimeout(() => {
        photoHandler.handleApply();
      }, 1000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [applyPhotoWithoutPreview, photoHandler]);

  const { t } = useTranslation('common');

  return (
    <Fragment>
      <ScCameraModal rounded={rounded} fullOnMobileScreen={fullOnMobileScreen}>
        {mask && videoIsReady && <div className="mask">{mask}</div>}
        {tooltipIllustration && videoIsReady && (
          <div className="example">
            <div className="example__container">{tooltipIllustration}</div>
          </div>
        )}
        {!videoIsReady && <Loader />}
        <ScVideo
          {...props}
          ref={videoRef}
          rounded={rounded}
          onCanPlay={handleCanPlay}
          autoPlay
          playsInline
          muted
          isHide={photoHandler.isTakingPhoto}
        />
        <ScCanvas ref={canvasRef} isHide={!photoHandler.isTakingPhoto} />
      </ScCameraModal>
      {withTakePhoto && onSave && (
        <ScCameraButtonBlock>
          {photoHandler.isTakingPhoto ? (
            <Loader relative />
          ) : (
            <Button
              type="button"
              icon={<Icon name="PhotoCamera" />}
              onClick={handleTakePhoto}
            >
              {t('camera.take')}
            </Button>
          )}
        </ScCameraButtonBlock>
      )}
      {children}
    </Fragment>
  );
};
