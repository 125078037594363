import * as React from 'react';
import type { SVGProps } from 'react';
const SvgPhoneBadge = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={25}
      height={24}
      fill="none"
      viewBox="0 0 25 24"
      {...props}
    >
      <path
        fill="#fff"
        d="M17.5 19h-10V5h10v14Zm0-18h-10c-1.11 0-2 .89-2 2v18a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2Z"
      />
    </svg>
  );
};
export default SvgPhoneBadge;
