import styled, { css } from 'styled-components/macro';

import { device } from 'components/Global/MediaQuery';
import { TOOLTIP_REMOVE_TIMEOUT } from 'components/tooltip/utils';
import {
  AnimationHideKeyframes,
  AnimationTopHideKeyframes,
} from 'components/styles/keyframes';

import successIcon from 'assets/svg/notificationSuccess.svg';
import errorIcon from 'assets/svg/notificationError.svg';

export const AnimationTopHide = css`
  opacity: 0;
  transition: 1s ease;
  animation: ${AnimationTopHideKeyframes} ${TOOLTIP_REMOVE_TIMEOUT}ms ease;
`;

export const AnimationHide = css`
  opacity: 0;
  transition: 1s ease;
  animation: ${AnimationHideKeyframes} ${TOOLTIP_REMOVE_TIMEOUT}ms ease;
`;

export interface ScTooltipProps {
  info?: boolean;
  success?: boolean;
  error?: boolean;
  animate?: boolean;
  inline?: boolean;
}

export const ScTooltip = styled.div<ScTooltipProps>`
  position: relative;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  white-space: pre-wrap;
  box-sizing: border-box;
  padding: 14px 16px;
  width: 100%;
  max-width: 472px;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  text-transform: none;

  color: ${({ success, error, info, theme }) => {
    switch (true) {
      case success:
        return theme.successContent;
      case error:
        return theme.error;
      case info:
      default:
        return theme.primaryContent;
    }
  }};
  background: ${({ success, error, info, theme }) => {
    switch (true) {
      case success:
        return theme.success;
      case error:
        return theme.error;
      case info:
      default:
        return theme.base_400;
    }
  }};

  ${({ success, error }) =>
    (success || error) &&
    css`
      padding-left: 57px;
      &:before {
        content: '';
        position: absolute;
        left: 17px;
        top: 50%;
        width: 24px;
        height: 24px;
        transform: translateY(-50%);
        background-position: center;
        background-repeat: no-repeat;
        background-image: ${() => {
          switch (true) {
            case success:
              return `url(${successIcon})`;
            case error:
              return `url(${errorIcon})`;
            default:
              return 'none';
          }
        }};
      }
    `}
`;

export const ScTooltipGlobal = styled(ScTooltip)`
  position: fixed;
  bottom: 24px;
  right: 24px;
  z-index: 11111;
  max-width: 400px;
  ${({ animate }) => (animate ? AnimationHide : null)};
  @media (${device.mobileXL}) {
    max-width: 275px;
    right: 15px;
  }
  @media (${device.mobileS}) {
    max-width: 270px;
  }
`;
