import React, { forwardRef, useEffect } from 'react';
import { usePreventScroll } from 'hooks/scroll';

import { Icon } from 'components/UI/Icon';

import {
  ScModal,
  ScModalBg,
  ScModalBlock,
  ScModalContainer,
  ScModalContent,
} from './styled';
import { useComponentVisible } from 'hooks/visibility';

export interface ModalProps {
  children:
    | ((props: {
        ref: React.MutableRefObject<HTMLDivElement>;
      }) => React.ReactNode)
    | React.ReactNode;
  onClose?: () => void;
  alignTop?: boolean;
}

export function Modal(props: ModalProps) {
  const { children, onClose, alignTop } = props;

  const { ref, isComponentVisible } = useComponentVisible(true);

  usePreventScroll(document.body);

  useEffect(() => {
    if (!isComponentVisible) {
      onClose && onClose();
    }
  }, [isComponentVisible, onClose]);

  return (
    <ScModal>
      <ScModalBg />
      <ScModalContainer>
        <ScModalContent ref={ref} alignTop={alignTop}>
          {typeof children === 'function' ? children({ ref }) : children}
        </ScModalContent>
      </ScModalContainer>
    </ScModal>
  );
}

export interface ModalBlockProps {
  onClose: () => void;
  children?: React.ReactNode;
  fullOnMobileScreen?: boolean;
}

export const ModalBlock = forwardRef<HTMLDivElement, ModalBlockProps>(
  ({ children, fullOnMobileScreen, onClose }, ref) => {
    return (
      <ScModalBlock fullOnMobileScreen={fullOnMobileScreen} ref={ref}>
        {onClose && (
          <div className="closeIcon" onClick={onClose}>
            <Icon name="Close" />
          </div>
        )}
        {children}
      </ScModalBlock>
    );
  }
);
