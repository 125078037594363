import * as React from 'react';
import type { SVGProps } from 'react';
const SvgDemoHeadNose = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={27}
      height={9}
      fill="none"
      viewBox="0 0 27 9"
      {...props}
    >
      <path
        stroke="#2B292D"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={4.084}
        d="M24.034 2.534 17.418 5.83a9.013 9.013 0 0 1-7.718.15L2.043 2.534"
      />
    </svg>
  );
};
export default SvgDemoHeadNose;
