import * as React from 'react';
import type { SVGProps } from 'react';
const SvgOpenMouth = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={44}
      height={27}
      fill="none"
      viewBox="0 0 44 27"
      {...props}
    >
      <path
        fill="#2B292D"
        d="M44 .52H0c.413 8.667 6.445 26 22.31 26 15.865 0 20.657-17.333 21.69-26Z"
      />
      <mask
        id="openMouth_svg__a"
        width={44}
        height={27}
        x={0}
        y={0}
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#2B292D"
          d="M44 .52H0c.413 8.667 6.445 26 22.31 26 15.865 0 20.657-17.333 21.69-26Z"
        />
      </mask>
      <g mask="url(#openMouth_svg__a)">
        <path
          fill="#FFB1B1"
          d="M38 20.52c-10-5.6-25.5-2.333-32 0l-1.5 3 13.5 5.5c10.833-.5 30-2.9 20-8.5Z"
        />
      </g>
    </svg>
  );
};
export default SvgOpenMouth;
