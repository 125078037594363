import styled from 'styled-components/macro';

import { device } from 'components/Global/MediaQuery';
import { ScButton } from 'components/Button/styled';

export const ScContainer = styled.div`
  max-width: 1270px;
  width: 100%;
  margin: 0 auto;
  padding: 0 16px 16px;
  &.flex {
    display: flex;
    flex-grow: 1;
  }
  &.items-center {
    align-items: center;
  }
  &.items-start {
    align-items: flex-start;
  }
  &.justify-center {
    justify-content: center;
  }
`;

interface ScBlockProps {
  backBtn?: boolean;
  flexGap?: number;
}

export const ScBlock = styled.div<ScBlockProps>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: ${({ theme }) => theme.base_100};
  border: 1px solid ${({ theme }) => theme.base_300};
  width: 100%;
  max-width: 620px;
  border-radius: 12px;
  padding: 56px 36px;
  position: relative;
  @media (${device.mobileXL}) {
    padding: 24px 16px;
  }
  & > div:nth-last-child(1) {
    display: flex;
    flex-direction: column;
    gap: ${({ flexGap }) => (flexGap ? `${flexGap}px` : '40px')};
    @media (${device.mobileXL}) {
      gap: 20px;
    }
    .info {
      display: flex;
      flex-direction: column;
      gap: 12px;
      h4 {
        font: ${({ theme }) => '700 28px/32px ' + theme.mainFontFamilyName};
        color: ${({ theme }) => theme.baseContent};
        @media (${device.mobileXL}) {
          font-size: 18px;
          line-height: 24px;
        }
      }
      p {
        color: ${({ theme }) => theme.baseSecondaryContent};
      }
    }
    .button-block {
      text-align: center;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      row-gap: 20px;
      column-gap: 12px;
      ${ScButton}:not(:only-child) {
        flex-grow: 1;
      }
    }
  }
  .arrow-back {
    position: absolute;
    top: 16px;
    display: block;
    background-color: transparent;
    width: 24px;
    height: 24px;
    border: none;
    cursor: pointer;
    svg path {
      fill: ${({ theme }) => theme.baseContent};
    }
    @media (${device.mobileXL}) {
      position: static;
    }
  }
`;

interface ScWrapProps {
  noPadding?: boolean;
  bordered?: boolean;
  spaceBetween?: boolean;
  topPadding?: string;
  topMargin?: string;
  rightMargin?: string;
  bottomPadding?: string;
  alignLeft?: boolean;
  vertical?: boolean;
  maxWidth?: number;
  fullWidth?: boolean;
}
export const ScWrap = styled.div<ScWrapProps>`
  ${({ bordered }) => bordered && `border-top: 1px solid grey;`};
  justify-content: ${({ spaceBetween, alignLeft }) =>
    spaceBetween ? 'space-between' : alignLeft ? 'left' : 'center'};
  display: flex;
  flex-direction: ${({ vertical }) => (vertical ? `column` : `row`)};
  flex-wrap: wrap;
  align-items: center;
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : `auto`)};
  width: ${({ fullWidth }) => (fullWidth ? `100%` : `auto`)};
  ${({ topPadding }) => topPadding && `padding-top: ${topPadding}px;`};
  ${({ topMargin }) => topMargin && `margin-top: ${topMargin}px;`};
  ${({ rightMargin }) => rightMargin && `margin-right: ${rightMargin}px;`};
  ${({ bottomPadding }) =>
    bottomPadding && `padding-bottom: ${bottomPadding}px;`};
  button,
  a {
    margin: 0;
    &:nth-child(2) {
      ${({ vertical }) =>
        vertical ? `margin-top: 20px;` : `margin-right: 15px;`};
      @media (${device.tablet}) {
        margin-top: 10px;
        margin-left: 0;
      }
    }
  }

  @media (${device.tablet}) {
    flex-direction: column;
    button {
      width: 100%;
    }
  }

  ${({ vertical }) =>
    vertical
      ? `@media (${device.mobileL}) {button{width: 100%; margin: 0 0 5px 0;}}`
      : null};
  @media (${device.mobileL}) {
    a:first-child {
      margin-right: 5px;
    }
  }
`;
