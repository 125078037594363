import styled from 'styled-components/macro';

export const ScDocumentUploadForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  text-align: center;
  .documentIllustration {
    width: 100%;
    &.passport {
      max-width: 275px;
    }
    &.intPassport {
      max-width: 212px;
    }
    &.idCard {
      max-width: 326px;
    }
  }
  & > h4 {
    font: ${({ theme }) => `700 28px/32px ${theme.mainFontFamilyName}`};
  }
`;
