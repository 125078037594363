import * as React from 'react';
import type { SVGProps } from 'react';
const SvgCheckbox = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={13}
      height={9}
      fill="none"
      viewBox="0 0 13 9"
      {...props}
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M5.08 8.442a.837.837 0 0 1-1.16 0L.655 5.302a.873.873 0 0 1 1.21-1.258L4.5 6.577 10.735.582a.873.873 0 1 1 1.21 1.26l-6.865 6.6Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgCheckbox;
