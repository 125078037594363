import React, { forwardRef } from 'react';
import { Control, Controller, ControllerProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { InputBlock, InputBlockProps } from './primitives/InputBlock';

export type FormInputBlockProps = InputBlockProps &
  Omit<ControllerProps, 'render' | 'control'> & { control: Control<any> };

export const FormInputBlock = forwardRef<HTMLInputElement, FormInputBlockProps>(
  ({ control, rules, name, error, defaultValue, ...rest }, ref) => {
    const { t } = useTranslation();

    return (
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        rules={rules}
        render={({ field, fieldState: { error } }) => (
          <InputBlock
            {...field}
            {...rest}
            ref={ref}
            error={error?.message ? t(`${error?.message}`) : null}
          />
        )}
      />
    );
  }
);
