import styled, { css } from 'styled-components/macro';
import { device } from 'components/Global/MediaQuery';

import deleteBin from 'assets/svg/deleteBin.svg';
import magnifier from 'assets/svg/magnifier.svg';

interface ScFileDropAreaProps {
  active?: boolean;
}

export const ScFormUploadArea = styled.div`
  flex-grow: 1;
`;

export const ScFileDropArea = styled.div<ScFileDropAreaProps>`
  padding: 41px 70px 47px;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme }) => theme.borderHoverColor};
  border-radius: 8px;
  @media (${device.mobileXXL}) {
    padding: 24px 16px;
  }
  .info {
    .label {
      text-align: center;
      white-space: break-spaces;
      font-weight: 500;
      font-size: 16px;
      line-height: 1.5;
      color: ${({ theme }) => theme.baseContent};
      &.withIllustration {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        text-align: left;
        & img {
          max-height: 80px;
          max-width: 70px;
          margin-right: 16px;
        }
      }
    }
    .buttons {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 24px;
      grid-row-gap: 24px;
      margin-top: 36px;
      @media (${device.mobileXXL}) {
        margin-top: 24px;
        grid-row-gap: 16px;
        grid-template-columns: none;
      }
    }
  }
`;

const stylesButtonUpload = css`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  background-color: transparent;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme }) => theme.borderHoverColor};
  border-radius: ${({ theme }) => theme.btnBorderRadius};
  font-weight: ${({ theme }) => theme.fontBoltWeight};
  font-size: 16px;
  line-height: 1.12;
  padding: 0 15px;
  height: 48px;
  text-transform: ${({ theme }) =>
    theme.btnTextUppercase ? 'uppercase' : 'none'};
  color: ${({ theme }) => theme.baseSecondaryContent};
  cursor: pointer;
  svg {
    vertical-align: middle;
    margin-right: 8px;
    & > path {
      fill: ${({ theme }) => theme.svgColor};
    }
  }
  &:hover {
    background-color: ${({ theme }) => theme.btnSecondaryHoverColor};
  }
  &:active {
    background-color: ${({ theme }) => theme.btnSecondaryActiveColor};
  }
`;

export const ScButtonUpload = styled.button`
  ${stylesButtonUpload}
`;

export const ScLabelUpload = styled.label`
  ${stylesButtonUpload}
  input {
    display: none;
  }
`;

export const ScFormUpload = styled.div`
  display: flex;
  flex-grow: 1;
`;

export const ScFilePreview = styled.div`
  position: relative;
  height: 190px;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme }) => theme.base_400};
  border-radius: 12px;
  background-color: ${({ theme }) => theme.base_100};

  .name {
    position: absolute;
    left: 16px;
    top: 16px;
    background: ${({ theme }) => theme.btnSecondaryActiveColor};
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    padding: 4px;
    font-size: 12px;
    line-height: 1.33;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.baseContent};
    z-index: 1;
  }

  .button-remove {
    position: absolute;
    right: 12px;
    top: 12px;
    background: ${({ theme }) => theme.base_400} url(${deleteBin}) center
      no-repeat;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12);
    width: 36px;
    height: 36px;
    border-radius: 100%;
    border: none;
    cursor: pointer;
    z-index: 1;
  }

  .button-magnifier {
    position: absolute;
    right: 12px;
    top: 12px;
    background: ${({ theme }) => theme.base_400} url(${magnifier}) center
      no-repeat;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12);
    width: 36px;
    height: 36px;
    border-radius: 100%;
    border: none;
    cursor: pointer;
    z-index: 1;
  }

  .file {
    position: absolute;
    left: 0;
    right: 0;
    display: flex;
    top: 0;
    bottom: 0;
    text-align: center;
    justify-content: center;
    align-items: center;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
`;

export const ScFileFullSizePreview = styled.div`
  overflow: hidden;
  padding-top: 56px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  & img {
    border-radius: 0 0 12px 12px;
    width: auto;
    height: auto;
    max-width: 600px;
    max-height: 80vh;
    @media (${device.tablet}) {
      max-width: 100%;
    }
  }
`;
