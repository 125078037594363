import * as React from 'react';
import type { SVGProps } from 'react';
const SvgCalendarBadge = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={28}
      height={28}
      fill="none"
      viewBox="0 0 28 28"
      {...props}
    >
      <path
        fill="#fff"
        d="M22.167 4.667H21V2.334h-2.333v2.333H9.333V2.334H7v2.333H5.833A2.323 2.323 0 0 0 3.512 7L3.5 23.334a2.333 2.333 0 0 0 2.333 2.333h16.334a2.34 2.34 0 0 0 2.333-2.334V7a2.34 2.34 0 0 0-2.333-2.333Zm0 18.666H5.833V11.668h16.334v11.667Z"
      />
      <path
        fill="#fff"
        d="m17.362 14-4.76 4.508-1.964-1.841-1.305 1.222L12.603 21l6.064-5.73L17.362 14Z"
      />
    </svg>
  );
};
export default SvgCalendarBadge;
