import React, { memo } from 'react';

import { useTranslation } from 'react-i18next';

import { Button } from 'components/Button';
import { NotFoundError } from 'components/ThemedComponents/Errors';

import { ErrorBlock } from './styled';

const NotFoundBlock = () => {
  const { t } = useTranslation('common');

  return (
    <>
      <ErrorBlock>
        <NotFoundError />

        <div>
          <h3>{t('notFoundPage.title')}</h3>
          <Button to="/face-check">{t('notFoundPage.buttonText')}</Button>
        </div>
      </ErrorBlock>
    </>
  );
};

export default memo(NotFoundBlock);
