import React, { lazy } from 'react';

import { titleGetter } from 'helpers/trans';
import { infoVerification } from 'api/verification';

const NotFoundBlock = lazy(() => import('components/internal/NotFound'));
const DemoDonePage = lazy(() => import('modules/demo/components/DemoDonePage'));
const DemoPage = lazy(() => import('modules/demo/components/DemoPage'));

const route = [
  {
    path: 'create',
    element: <DemoPage />,
  },
  {
    path: 'return',
    loader: async () => ({
      verificationInfo: await infoVerification().then((r) => r.data),
    }),
    element: <DemoDonePage />,
  },
  {
    element: <NotFoundBlock />,
    meta: {
      title: titleGetter('common:errors.internal.title'),
    },
  },
];

export default route;
