import * as React from 'react';
import type { SVGProps } from 'react';
const SvgSuccessBadge = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={40}
      height={40}
      fill="none"
      viewBox="0 0 40 40"
      {...props}
    >
      <path
        fill="#00DF53"
        fillRule="evenodd"
        d="M20 0C8.955 0 0 8.955 0 20c0 11.046 8.955 20 20 20 11.046 0 20-8.954 20-20C40 8.955 31.046 0 20 0Zm8.67 16.618a1.819 1.819 0 1 0-2.794-2.327l-7.818 9.38-4.045-4.047a1.818 1.818 0 0 0-2.571 2.57l5.454 5.455a1.818 1.818 0 0 0 2.682-.122l9.091-10.909Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgSuccessBadge;
