import React, { FC, useEffect, Suspense, memo } from 'react';

import { useTheme } from 'hooks/theme';

import { addStylesheet } from 'helpers/common';
import { GlobalTooltip } from 'modules/globalTooltip';

import { Header } from 'components/Header';
import { Footer } from 'components/Footer';

const useFonts = () => {
  const { mainFontFamilyUrl } = useTheme();

  useEffect(() => {
    addStylesheet(mainFontFamilyUrl);
  }, [mainFontFamilyUrl]);
};

const Layout: FC<{ children: React.ReactNode }> = ({ children }) => {
  useFonts();

  return (
    <>
      <GlobalTooltip />

      <Suspense fallback={null}>
        <Header />
        {children}
        <Footer />
      </Suspense>
    </>
  );
};

export default memo(Layout);
