import React, { FC } from 'react';

import { ScFieldWrapper } from './styled';

export interface FieldWrapperProps {
  children?: React.ReactNode;
  error?: React.ReactNode;
  label?: React.ReactNode;
}

export const FieldWrapper: FC<FieldWrapperProps> = ({
  children,
  error,
  label,
}) => {
  return (
    <ScFieldWrapper>
      {label && <div className="label">{label}</div>}
      {children}
      {error && <div className="error">{error}</div>}
    </ScFieldWrapper>
  );
};
