import { Theme } from '../contracts';
import { images } from './config-images';
import lookStraightAnimation from 'assets/lottie/lookStraightAnimation.json';
import ukrainianPassport from 'assets/lottie/ukrainianPassport.json';
import openMouthAnimation from 'assets/lottie/openMouthAnimation.json';
import faceBlinkAnimation from 'assets/lottie/faceBlinkAnimation.json';

const theme: Theme = {
  name: 'VCHECK',

  mainFontFamilyName: `'Manrope', sans-serif`,
  mainFontFamilyUrl:
    'https://fonts.googleapis.com/css2?family=Manrope:wght@400;600&display=swap',

  //Semantic Colors
  primary: '#2E75FF',
  primaryHover: '#2E96FF',
  primaryActive: '#3361EC',
  primaryContent: '#FFFFFF',
  primaryBg: '#5D6884',
  accent: '#6096FF',
  accentHover: '#6ABFFF',
  accentActive: '#4F79F7',
  accentContent: '#FFFFFF',
  accentBg: '#32404A',
  neutral: '#FFFFFF',
  neutralHover: 'rgba(255, 255, 255, 0.4)',
  neutralActive: 'rgba(255, 255, 255, 0.1)',
  neutralContent: '#000000',
  //Statuses Colors
  success: '#6CFB93',
  successHover: '#C8FDD2',
  successActive: '#00DF53',
  successBg: '#3A4B3F',
  successContent: '#3B3B3B',
  error: '#F47368',
  errorHover: '#FF877C',
  errorActive: '#DE473A',
  errorBg: '#4B2A24',
  errorContent: '#3B3B3B',
  warning: '#FFB482',
  warningHover: '#FFBF94',
  warningActive: '#D3834E',
  warningBg: '#3F3229',
  warningContent: '#3B3B3B',
  //System colors
  base: '#2A2A2A',
  base_100: '#3C3C3C',
  base_200: '#555555',
  base_300: '#6A6A6A',
  base_400: '#7F7F7F',
  base_500: '#949494',
  baseContent: '#FFFFFF',
  baseSecondaryContent: '#D8D8D8',
  disabled: '#AAAAAA',
  disabledContent: '#6A6A6A',
  // end frame

  //media
  logo: images.logo ? images.logo.path : undefined,
  background: images.background ? images.background.path : undefined,

  //lottieAnimation

  lookStraightAnimation: lookStraightAnimation,
  animationUkrainianPassport: ukrainianPassport,
  openMouthAnimation: openMouthAnimation,
  faceBlinkAnimation: faceBlinkAnimation,

  //demo image
  demoPhone: images.demo.path,
  images,
};

export default theme;
