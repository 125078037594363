import React, { FC } from 'react';

import { ScBlock, ScContainer } from 'components/styles/common';
import { Icon } from 'components/UI/Icon';

interface ContentProps {
  children: React.ReactNode;
  title?: string;
  description?: string;
  button?: React.ReactNode;
  onBack?: React.MouseEventHandler<HTMLButtonElement>;
  flexGap?: number;
}

export const Content: FC<ContentProps> = ({
  children,
  title,
  description,
  button,
  onBack,
  flexGap,
}) => {
  return (
    <ScContainer className="flex items-start justify-center">
      <ScBlock flexGap={flexGap} backBtn={!!onBack}>
        {onBack && (
          <button type="button" className="arrow-back" onClick={onBack}>
            <Icon name="ArrowBack" />
          </button>
        )}

        <div>
          {(title != null || description != null) && (
            <div className="info">
              {title && <h4>{title}</h4>}
              {description && <p>{description}</p>}
            </div>
          )}
          {children}
          {button != null && <div className="button-block">{button}</div>}
        </div>
      </ScBlock>
    </ScContainer>
  );
};
