import styled from 'styled-components/macro';

export const ScFooter = styled.div`
  margin-top: auto;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    color: ${({ theme }) => theme.copyrightTextColor};
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }
`;
