import { FC, useEffect } from 'react';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

const DocumentTitle: FC<{
  getTitle: (t: TFunction) => string;
  children: JSX.Element;
}> = ({ getTitle, children }) => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = getTitle(t);
  }, [getTitle, t]);

  return children;
};

export default DocumentTitle;
