import styled from 'styled-components/macro';
import { device } from 'components/Global/MediaQuery';

export const DocumentEditFieldList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;
  align-items: stretch;
`;

export const ScPreviews = styled.div<{ single?: boolean }>`
  display: grid;
  grid-template-columns: ${({ single }) =>
    single ? `repeat(1, 1fr)` : `repeat(2, 1fr)`};
  grid-column-gap: 14px;
  grid-row-gap: 14px;
  @media (${device.mobileXL}) {
    grid-template-columns: none;
  }
`;

export const ScPreview = styled.div`
  position: relative;
  height: 190px;
  border-width: 1px;
  border-style: solid;
  background-color: ${({ theme }) => theme.base_100};
  border-color: ${({ theme }) => theme.base_400};
  border-radius: 8px;
  .file {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    text-align: center;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
`;
