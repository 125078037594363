import React from 'react';
import { useTheme } from 'hooks/theme';

const DefaultError = () => {
  const { primary, error, primaryContent } = useTheme();

  return (
    <div style={{ maxWidth: 278, width: '100%', height: 'auto' }}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 280 280"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_4398_3922)">
          <path
            d="M218.515 47.2078L26.978 71.2803L50.2083 256.116L241.745 232.044L218.515 47.2078Z"
            fill="black"
          />
          <path
            d="M226.25 36.3182L34.7134 60.3906L57.9436 245.227L249.48 221.154L226.25 36.3182Z"
            fill={primary}
          />
          <path
            opacity="0.4"
            d="M199.046 17.8503L187.054 27.5498L196.752 39.5395L208.744 29.84L199.046 17.8503Z"
            fill={primary}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M179.898 269.326L179.472 277.191L172.871 272.891L166.272 268.589L173.297 265.025L180.322 261.461L179.898 269.326Z"
            fill={primary}
          />
          <path
            opacity="0.2"
            d="M162.308 28.8897L128.845 36.8633L130.28 42.8866L163.743 34.9129L162.308 28.8897Z"
            fill={primary}
          />
          <path
            d="M10.5742 156.522C14.5772 156.522 17.8222 153.277 17.8222 149.274C17.8222 145.271 14.5772 142.026 10.5742 142.026C6.57122 142.026 3.32617 145.271 3.32617 149.274C3.32617 153.277 6.57122 156.522 10.5742 156.522Z"
            fill={primary}
          />
          <path
            opacity="0.2"
            d="M272.16 153.789C274.653 153.789 276.675 151.768 276.675 149.274C276.675 146.781 274.653 144.76 272.16 144.76C269.667 144.76 267.646 146.781 267.646 149.274C267.646 151.768 269.667 153.789 272.16 153.789Z"
            fill={primary}
          />
          <path
            opacity="0.4"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M109.521 273.301C109.483 275.241 109.158 277.085 109.562 279.176C112.903 278.97 114.649 279.394 117.592 277.71C119.68 276.516 121.238 274.699 122.703 272.863C124.55 270.547 125.56 267.932 128.819 266.805C131.895 265.743 135.105 267.3 138.424 265.992C142.673 264.318 144.793 260.705 146.703 257.796C147.644 256.36 148.136 254.973 150.59 254.089C152.296 253.473 154.314 253.882 156.322 253.76C161.377 253.446 165.518 251.416 167.46 246.65C165.94 245.722 164.239 245.327 162.672 244.55C158.705 250.451 152.309 245.874 146.097 249.697C141.543 252.498 140.751 258.741 136.228 260.466C134.538 261.113 132.278 260.585 130.786 260.655C128.941 260.74 126.964 260.923 125.12 261.845C120.334 264.237 118.794 270.852 114.109 272.821C112.55 273.477 111.33 273.077 109.521 273.301Z"
            fill={primary}
          />
          <path
            d="M59.1506 219.257C60.1917 222.732 61.9227 225.307 64.6383 227.181C67.1733 228.929 69.5964 228.913 71.1061 227.276C72.643 225.61 72.3642 223.437 70.1417 221.113C68.2943 219.18 66.1491 217.539 65.0069 215.005C64.3213 213.485 62.9301 212.533 61.1301 212.958C59.0625 213.447 58.6504 215.102 58.7499 216.913C58.7999 217.828 59.0576 218.738 59.1506 219.257Z"
            fill={primaryContent}
          />
          <path
            d="M73.8197 232.288C74.54 233.954 77.5002 235.01 79.3204 234.303C80.6997 233.768 81.1917 232.728 80.6284 231.355C79.9396 229.676 77.0457 228.677 75.2327 229.451C73.905 230.017 73.3897 230.996 73.8197 232.288Z"
            fill={primaryContent}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M219.831 54.9193C226.128 55.768 223.97 45.8256 219.552 47.7833C217.252 48.8016 217.381 54.5893 219.831 54.9193Z"
            fill={primaryContent}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M223.11 60.2311C222.669 59.4292 220.642 58.3957 219.021 59.5798C216.912 61.1281 216.946 74.1579 219.707 75.398C223.647 77.1633 226.637 66.6403 223.11 60.2311Z"
            fill={primaryContent}
          />
          <path
            d="M214.341 142.183C214.774 103.153 183.486 71.1626 144.456 70.7293C105.426 70.2961 73.4355 101.584 73.0023 140.614C72.569 179.643 103.857 211.633 142.887 212.067C181.917 212.5 213.908 181.212 214.341 142.183Z"
            fill={primaryContent}
          />
          <path
            d="M145.479 201.684C178.773 200.698 204.965 172.908 203.979 139.613C202.993 106.319 175.203 80.1274 141.908 81.1133C108.614 82.0992 82.4223 109.889 83.4082 143.184C84.3941 176.478 112.184 202.67 145.479 201.684Z"
            fill={error}
          />
          <path
            d="M172.341 112.74C170.316 110.716 167.036 110.716 165.012 112.74L143.684 134.068L122.356 112.74C120.332 110.716 117.051 110.716 115.027 112.74C113.002 114.764 113.002 118.046 115.027 120.07L136.354 141.398L115.027 162.726C113.002 164.75 113.002 168.032 115.027 170.056C116.039 171.068 117.366 171.574 118.692 171.574C120.017 171.574 121.344 171.068 122.356 170.056L143.684 148.728L165.012 170.056C166.024 171.068 167.35 171.574 168.676 171.574C170.003 171.574 171.329 171.068 172.341 170.056C174.366 168.032 174.366 164.75 172.341 162.726L151.013 141.398L172.341 120.07C174.366 118.046 174.366 114.764 172.341 112.74Z"
            fill={primaryContent}
          />
          <path
            d="M95.6896 187.957C102.493 181.154 102.493 170.124 95.6896 163.321C88.8866 156.518 77.8568 156.518 71.0538 163.321C64.2508 170.124 64.2508 181.154 71.0538 187.957C77.8568 194.76 88.8866 194.76 95.6896 187.957Z"
            fill={primaryContent}
          />
          <path
            d="M90.8936 184.398C89.9846 184.398 89.2469 183.661 89.2469 182.751C89.2469 179.449 86.5607 176.762 83.258 176.762C79.9553 176.762 77.2691 179.448 77.2691 182.751C77.2691 183.661 76.5313 184.398 75.6223 184.398C74.7133 184.398 73.9756 183.661 73.9756 182.751C73.9756 177.633 78.1389 173.469 83.258 173.469C88.3765 173.469 92.5403 177.633 92.5403 182.751C92.5403 183.661 91.8026 184.398 90.8936 184.398Z"
            fill={primary}
          />
          <path
            d="M75.8222 172.801C77.037 172.801 78.0218 171.816 78.0218 170.601C78.0218 169.386 77.037 168.401 75.8222 168.401C74.6074 168.401 73.6226 169.386 73.6226 170.601C73.6226 171.816 74.6074 172.801 75.8222 172.801Z"
            fill={primary}
          />
          <path
            d="M90.8319 172.801C92.0468 172.801 93.0316 171.816 93.0316 170.601C93.0316 169.386 92.0468 168.401 90.8319 168.401C89.6171 168.401 88.6323 169.386 88.6323 170.601C88.6323 171.816 89.6171 172.801 90.8319 172.801Z"
            fill={primary}
          />
        </g>
        <defs>
          <clipPath id="clip0_4398_3922">
            <rect
              width="278.352"
              height="278.352"
              fill={primaryContent}
              transform="translate(0.824219 0.824219)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default DefaultError;
