import styled from 'styled-components/macro';
import { device } from 'components/Global/MediaQuery';

export const ScResult = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    text-align: center;
    font-size: 28px;
    line-height: 32px;
    font-weight: 700;
    margin-top: 12px;
    color: ${({ theme }) => theme.baseContent};
    @media (${device.mobileXL}) {
      font-size: 18px;
      line-height: 24px;
    }
  }

  .text {
    text-align: center;
    margin-top: 15px;
    color: ${({ theme }) => theme.baseSecondaryContent};
    @media (${device.mobileXL}) {
      padding: 0;
    }
    p {
      font-size: 16px;
      line-height: 24px;
      @media (${device.mobileXL}) {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  .button-block {
    margin-top: 45px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    @media (${device.mobileXXL}) {
      margin-top: 40px;
    }
    & > * {
      margin: 4px 12px;
      @media (${device.mobileXXL}) {
        margin: 5px 0 !important;
      }
    }
  }

  .btn-approve {
    display: flex;
    margin-top: 20px;
    justify-content: center;
  }
`;
