import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Camera } from 'helpers/camera';
import { blobToFile, uuid } from 'helpers/common';

export enum CameraStatus {
  CONNECTING,
  RUNNING,
  NOT_AVAILABLE,
}

export const useCamera = (
  videoRef: React.RefObject<HTMLVideoElement>,
  constraints: MediaStreamConstraints,
  onFrame?: (frame: HTMLVideoElement) => void | Promise<void>
) => {
  const cameraRef = useRef(new Camera(null, constraints, true));

  const [cameraStatus, setCameraStatus] = useState<CameraStatus>(
    CameraStatus.CONNECTING
  );

  useEffect(() => {
    const camera = cameraRef.current;

    if (camera == null) return;

    (async () => {
      try {
        await camera.start();

        setCameraStatus(CameraStatus.RUNNING);
      } catch (e) {
        setCameraStatus(CameraStatus.NOT_AVAILABLE);
      }
    })();

    return () => {
      camera.stop();
    };
  }, []);

  useEffect(() => {
    const camera = cameraRef.current;

    if (
      cameraStatus === CameraStatus.RUNNING &&
      camera != null &&
      camera.stream != null &&
      videoRef.current != null
    ) {
      camera.setVideo(videoRef.current);
    }
  }, [videoRef, cameraStatus]);

  useEffect(() => {
    if (onFrame == null) return;

    const camera = cameraRef.current;

    camera.subscribe(onFrame);

    return () => {
      camera.unsubscribe(onFrame);
    };
  }, [onFrame]);

  return [cameraStatus, cameraRef.current] as const;
};

export const useCameraPhoto = (
  videoRef: React.RefObject<HTMLVideoElement>,
  canvasRef: React.RefObject<HTMLCanvasElement>,
  onSave: (file: File[]) => void
) => {
  const [isTakingPhoto, setIsTakingPhoto] = useState<boolean>(false);

  const takePhoto = () => {
    if (canvasRef.current == null || videoRef.current == null) return;

    const width = videoRef.current.offsetWidth;
    const height =
      videoRef.current.videoHeight / (videoRef.current.videoWidth / width);

    canvasRef.current.width = width;
    canvasRef.current.height = height;

    const context = canvasRef.current.getContext('2d');

    if (context == null) return;

    setIsTakingPhoto(true);

    context.drawImage(videoRef.current, 0, 0, width, height);
  };

  const handleReset = () => {
    if (canvasRef.current == null) return;

    const context = canvasRef.current.getContext('2d');

    if (context == null) return;

    context.clearRect(0, 0, 0, 0);

    setIsTakingPhoto(false);
  };

  const handleApply = useCallback(() => {
    if (canvasRef.current == null) return;

    canvasRef.current.toBlob(
      (blob) => {
        if (blob == null || onSave == null) return;
        const file = blobToFile(blob, `webcam-${uuid()}.jpg`);
        onSave([file]);
      },
      'image/jpeg',
      1
    );
    setIsTakingPhoto(false);
  }, [canvasRef, onSave]);

  return { isTakingPhoto, handleApply, handleReset, takePhoto };
};
