import styled from 'styled-components/macro';
import { device } from 'components/Global/MediaQuery';

export const ScPreviewList = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 28px;
  row-gap: 16px;
  @media (${device.tablet}) {
    flex-direction: column;
  }
`;
export const ScRequirementsList = styled.div`
  .requirements {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .requirement {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.2px;
      color: ${({ theme }) => theme.baseContent};
      position: relative;
      padding-left: 17px;
      &:before {
        content: '';
        display: block;
        position: absolute;
        width: 10px;
        height: 10px;
        background: ${({ theme }) => theme.primary};
        border-radius: 50%;
        left: 0;
        top: 7px;
        @media (${device.mobileL}) {
          top: 2px;
        }
      }
      @media (${device.mobileL}) {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
`;
