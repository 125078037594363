import React from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from 'components/UI/Icon';

import { ScRequirementsList } from './styled';

export const RequirementsList = () => {
  const { t } = useTranslation('documents');

  return (
    <ScRequirementsList>
      <div className="requirements">
        <div className="requirement">
          <Icon name="SecurityBadge" />
          {t('requirementsForDocument.requirements.0')}
        </div>
        <div className="requirement">
          <Icon name="CornersBadge" />
          {t('requirementsForDocument.requirements.1')}
        </div>
        <div className="requirement">
          <Icon name="CalendarBadge" />
          {t('requirementsForDocument.requirements.2')}
        </div>
        <div className="requirement">
          <Icon name="ClipBadge" />
          {t('requirementsForDocument.requirements.3')}
        </div>
        <div className="requirement">
          <Icon name="VerifiedBadge" />
          {t('requirementsForDocument.requirements.4')}
        </div>
        <div className="requirement">
          <Icon name="LightBadge" />
          {t('requirementsForDocument.requirements.5')}
        </div>
        <div className="requirement">
          <Icon name="PhotoCamera" />
          {t('requirementsForDocument.requirements.6')}
        </div>
      </div>
    </ScRequirementsList>
  );
};
