import * as React from 'react';
import type { SVGProps } from 'react';
const SvgDirectionsBadge = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={28}
      height={28}
      fill="none"
      viewBox="0 0 28 28"
      {...props}
    >
      <path
        fill="#fff"
        d="M26.168 12.372 15.656 1.845c-.875-.877-2.415-.889-3.302 0L1.854 12.36a2.33 2.33 0 0 0 0 3.295l10.5 10.516c.455.456 1.05.677 1.645.677s1.19-.221 1.645-.677l10.489-10.505c.921-.888.933-2.36.035-3.295ZM14.01 24.524l-10.5-10.516 10.5-10.516 10.5 10.516-10.5 10.516ZM8.166 14.02s0 1.168 1.167 1.168h5.833v2.921l4.083-4.09-4.083-4.089v2.921H9.333c-.642 0-1.167.526-1.167 1.169Z"
      />
    </svg>
  );
};
export default SvgDirectionsBadge;
