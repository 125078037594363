import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ScTooltipGlobal } from 'components/tooltip/styled';
import { TOOLTIP_REMOVE_TIMEOUT } from 'components/tooltip/utils';

import { TooltipTypes, globalTooltipStore } from '../store';

export const GlobalTooltip: FC = () => {
  const tooltip = globalTooltipStore((state) => state.tooltip);
  const remove = globalTooltipStore((state) => state.remove);

  useEffect(() => {
    if (tooltip && tooltip.animate) {
      const timeout = setTimeout(() => {
        remove();
      }, TOOLTIP_REMOVE_TIMEOUT);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [tooltip, remove]);

  const { t } = useTranslation();

  if (tooltip == null) return null;

  let body;
  if (tooltip.translate) {
    if (typeof tooltip.body === 'function') {
      body = tooltip.body(t);
    } else if (typeof tooltip.body === 'string') {
      body = t(tooltip.body);
    }
  } else {
    body = tooltip.body;
  }

  return (
    <ScTooltipGlobal
      key={tooltip.id}
      success={tooltip.type === TooltipTypes.SUCCESS}
      info={tooltip.type === TooltipTypes.INFO}
      error={tooltip.type === TooltipTypes.ERROR}
      animate={tooltip.animate}
      onClick={remove}
    >
      {body}
    </ScTooltipGlobal>
  );
};
