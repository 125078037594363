import styled from 'styled-components/macro';
import { device } from 'components/Global/MediaQuery';

export const ScCameraErrorScreen = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h3 {
    text-align: center;
    font-size: 32px;
    line-height: 40px;
    font-weight: 700;
    margin-top: 12px;
    color: ${({ theme }) => theme.baseContent};
    @media (${device.mobileXL}) {
      font-size: 18px;
      line-height: 24px;
    }
  }

  .text {
    text-align: center;
    margin-top: 16px;
    color: ${({ theme }) => theme.baseSecondaryContent};
    p {
      font-size: 16px;
      line-height: 24px;
      @media (${device.mobileXL}) {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  .redirectLink {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
  }
`;
