import React, { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Content } from 'components/Content';
import { Button } from 'components/Button';

import { Icon } from 'components/UI/Icon';
import { DocumentType } from 'api/document';
import { DocumentCategory } from 'helpers/enums';

import {
  IdCard,
  IntPassport,
  Passport,
} from 'components/ThemedComponents/Documents';

import { ManualPhotoBlock } from '../../components/ManualPhotoBlock';
import { CameraErrorScreen } from '../../components/CameraErrorScreen';
import { CameraErrors } from '../../components/CameraErrorScreen/constants';

import { ScDocumentUploadForm } from './styled';

interface UploadDocumentFormProps {
  type: DocumentType;
  nextStep: () => void;
  prevStep: () => void;
}

export const DocumentUploadForm: FC<UploadDocumentFormProps> = ({
  type,
  prevStep,
  nextStep,
}) => {
  const { t } = useTranslation('documents');

  const [isCameraOpen, setIsCameraOpen] = useState(false);

  const toggleCamera = () => setIsCameraOpen((prevState) => !prevState);

  const [cameraNotAvailable, setCameraNotAvailable] = useState(false);

  const toggleCameraNotAvailable = () =>
    setCameraNotAvailable((prevState) => !prevState);

  const handleNotAvailableCamera = () => toggleCameraNotAvailable();

  const uploadFormContent = useMemo(() => {
    switch (type.category) {
      case DocumentCategory.PASSPORT:
        return {
          illustration: <Passport className="documentIllustration passport" />,
          title: t('documentsUpload.inspectorForm.passport.title'),
          text: t('documentsUpload.inspectorForm.passport.text'),
        };
      case DocumentCategory.FOREIGN_PASSPORT:
        return {
          illustration: (
            <IntPassport className="documentIllustration intPassport" />
          ),
          title: t('documentsUpload.inspectorForm.intPassport.title'),
          text: t('documentsUpload.inspectorForm.intPassport.text'),
        };
      case DocumentCategory.ID_CARD:
      default:
        return {
          illustration: <IdCard className="documentIllustration idCard" />,
          title: t('documentsUpload.inspectorForm.idCard.title'),
          text: t('documentsUpload.inspectorForm.idCard.text'),
        };
    }
  }, [type.category, t]);

  return (
    <>
      {cameraNotAvailable ? (
        <CameraErrorScreen
          cameraError={CameraErrors.CAMERA_NOT_AVAILABLE}
          tryAgainHandler={toggleCameraNotAvailable}
        />
      ) : (
        <Content
          button={
            <Button
              icon={<Icon name="PhotoCamera" />}
              onClick={toggleCamera}
              type="button"
            >
              {t('documentsUpload.inspectorForm.makePhoto')}
            </Button>
          }
          onBack={prevStep}
        >
          <ScDocumentUploadForm>
            {uploadFormContent.illustration}
            <h4>{uploadFormContent.title}</h4>
            <p className="body1">{uploadFormContent.text}</p>
          </ScDocumentUploadForm>
          {isCameraOpen && (
            <ManualPhotoBlock
              type={type}
              onClose={toggleCamera}
              onNotAvailable={handleNotAvailableCamera}
              nextStep={nextStep}
            />
          )}
        </Content>
      )}
    </>
  );
};
