import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal, ModalBlock } from 'components/Modal';

import {
  ScFilePreview,
  ScFormUploadArea,
  ScFileFullSizePreview,
} from './styled';

interface FilePreviewProps {
  file: File;
  label?: string;
  onDelete?: () => void;
  magnifier?: boolean;
}

export const FilePreview: FC<FilePreviewProps> = ({
  file,
  onDelete,
  magnifier,
}) => {
  const [previewSrc, setPreviewSrc] = useState<string | undefined>(undefined);

  const { t } = useTranslation('common');

  const [isComponentVisible, setIsComponentVisible] = useState(false);

  useEffect(() => {
    try {
      const reader = new FileReader();

      try {
        reader.onload = (e) => {
          if (e.target == null) return;

          try {
            setPreviewSrc((e.target as any).result);
          } catch {}
        };
      } catch {}
      reader.readAsDataURL(file as any);
    } catch {}
  }, [file]);

  return (
    <ScFormUploadArea>
      {previewSrc != null ? (
        <>
          <ScFilePreview>
            <div className="file">
              <img src={previewSrc} alt="" />
            </div>
            {onDelete && (
              <button
                className="button-remove"
                type="button"
                onClick={onDelete}
              />
            )}
            {magnifier && (
              <button
                className="button-magnifier"
                type="button"
                onClick={() => setIsComponentVisible(true)}
              />
            )}
          </ScFilePreview>

          {isComponentVisible && (
            <Modal onClose={() => setIsComponentVisible(false)}>
              <ModalBlock onClose={() => setIsComponentVisible(false)}>
                <ScFileFullSizePreview>
                  <img src={previewSrc} alt="" />
                </ScFileFullSizePreview>
              </ModalBlock>
            </Modal>
          )}
        </>
      ) : (
        <div>{t('loading')}...</div>
      )}
    </ScFormUploadArea>
  );
};
