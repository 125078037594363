import { spin } from 'components/Global/Animations';

import styled from 'styled-components/macro';

export const ScInProcessLoader = styled.div`
  max-width: 193px;
  width: 88%;
  max-height: 193px;
  height: 77%;
  position: absolute;
  right: 5.6%;
  bottom: 15.4%;
  svg {
    animation: ${spin} 1s ease-in-out infinite;
  }
`;
