import { useEffect, useRef, useMemo, DependencyList } from 'react';

export function isObject(o: any) {
  return o === Object(o) && !Array.isArray(o) && typeof o !== 'function';
}

export function useMutable<T>(value: T) {
  const ref = useRef(value);

  useEffect(() => {
    ref.current = value;
  });

  return ref;
}

export function useMount() {
  const isMounted = useRef(true);

  useEffect(
    () => () => {
      isMounted.current = false;
    },
    []
  );

  return isMounted;
}

export function is(x: any, y: any) {
  if (x === y) {
    return x !== 0 || y !== 0 || 1 / x === 1 / y;
  } else {
    return x !== x && y !== y; // eslint-disable-line no-self-compare
  }
}

export function shallowEqual(objA: any, objB: any) {
  if (is(objA, objB)) return true;

  if (
    typeof objA !== 'object' ||
    objA === null ||
    typeof objB !== 'object' ||
    objB === null
  ) {
    return false;
  }

  const keysA = Object.keys(objA);
  const keysB = Object.keys(objB);

  if (keysA.length !== keysB.length) return false;

  const hasOwn = Object.prototype.hasOwnProperty;
  for (let i = 0; i < keysA.length; i++) {
    if (!hasOwn.call(objB, keysA[i]) || !is(objA[keysA[i]], objB[keysA[i]])) {
      return false;
    }
  }

  return true;
}

export function useShallowDepsCompare(deps?: DependencyList) {
  const depsRef = useRef(deps);

  if (depsRef.current === deps) return depsRef.current;

  function reset() {
    depsRef.current = deps;
  }

  if (
    depsRef &&
    depsRef.current &&
    deps &&
    depsRef.current.length === deps.length
  ) {
    const differ = deps.some((newValue, index) => {
      if (depsRef.current == null) return true;

      const oldValue = depsRef.current[index];

      if (typeof oldValue !== typeof newValue) {
        return true;
      } else if (Array.isArray(newValue) || isObject(newValue)) {
        if (!shallowEqual(oldValue, newValue)) {
          return true;
        }
      } else if (oldValue !== newValue) {
        return true;
      }

      return false;
    });

    if (differ) reset();
  } else {
    reset();
  }

  return depsRef.current;
}

export function useShallowMemo<T>(
  factory: () => T,
  deps: DependencyList | undefined
): T {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(factory, useShallowDepsCompare(deps));
}

export function useShallowCallback<T extends (...args: any[]) => any>(
  callback: T,
  deps: DependencyList
): T {
  return useShallowMemo(() => callback, deps);
}
