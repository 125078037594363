import styled, { keyframes } from 'styled-components/macro';

const loaderAnimation1 = keyframes`
  0% { transform: translate(18.089999999999996px,86.42999999999999px) scale(0); }
  25% { transform: translate(18.089999999999996px,86.42999999999999px) scale(0); }
  50% { transform: translate(18.089999999999996px,86.42999999999999px) scale(1); }
  75% { transform: translate(86.42999999999999px,86.42999999999999px) scale(1); }
  100% { transform: translate(154.76999999999998px,86.42999999999999px) scale(1); }
`;

const loaderAnimation2 = keyframes`
  0% { transform: translate(154.76999999999998px,86.42999999999999px) scale(1); }
  100% { transform: translate(154.76999999999998px,86.42999999999999px) scale(0); }
`;

const loaderAnimation3 = keyframes`
    0% { background: var(--primaryHover) }
    25% { background: var(--primary) }
    50% { background: var(--primaryHover) }
    75% { background: var(--primary) }
  100% { background: var(--primaryHover) }
`;

interface ScLoaderProps {
  relative?: boolean;
  small?: boolean;
  bgColor?: string;
  borderRadius?: string;
}

export const ScLoader = styled.div<ScLoaderProps>`
  position: ${({ relative }) => (relative ? 'relative' : 'absolute')};
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 33px;
  margin: 0 auto;
  ${({ bgColor }) => (bgColor ? `background-color: ${bgColor}` : null)};
  ${({ borderRadius }) =>
    borderRadius ? `border-radius: ${borderRadius}` : null};
  ${({ small }) => (small ? 'width: 124px; height: 68px' : null)};
  z-index: 99;
  .loadingio-spinner-ellipsis-841072ms0ro {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: ${({ small }) =>
      small ? 'scale(0.6) translate(-83%, -83%)' : 'translate(-50%, -50%)'};
  }
  .ldio-4uxzczc88hy div {
    position: absolute;
    width: 28.139999999999997px;
    height: 28.139999999999997px;
    border-radius: 0;
    transform: translate(86.42999999999999px, 86.42999999999999px) scale(1);
    background: ${({ theme }) => theme.primaryHover};
    animation: ${loaderAnimation1} 1.7857142857142856s infinite
      cubic-bezier(0, 0.5, 0.5, 1);
  }
  .ldio-4uxzczc88hy div:nth-child(1) {
    --primary: ${({ theme }) => theme.primary};
    --primaryHover: ${({ theme }) => theme.primaryHover};
    background: ${({ theme }) => theme.primary};
    transform: translate(154.76999999999998px, 86.42999999999999px) scale(1);
    animation: ${loaderAnimation2} 0.4464285714285714s infinite
        cubic-bezier(0, 0.5, 0.5, 1),
      ${loaderAnimation3} 1.7857142857142856s infinite step-start;
  }
  .ldio-4uxzczc88hy div:nth-child(2) {
    animation-delay: -0.4464285714285714s;
    background: ${({ theme }) => theme.primaryHover};
  }
  .ldio-4uxzczc88hy div:nth-child(3) {
    animation-delay: -0.8928571428571428s;
    background: ${({ theme }) => theme.primary};
  }
  .ldio-4uxzczc88hy div:nth-child(4) {
    animation-delay: -1.3392857142857142s;
    background: ${({ theme }) => theme.primaryHover};
  }
  .ldio-4uxzczc88hy div:nth-child(5) {
    animation-delay: -1.7857142857142856s;
    background: ${({ theme }) => theme.primary};
  }
  .loadingio-spinner-ellipsis-841072ms0ro {
    width: 201px;
    height: 201px;
    display: inline-block;
    overflow: hidden;
    background: none;
  }
  .ldio-4uxzczc88hy {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0;
  }
  .ldio-4uxzczc88hy div {
    box-sizing: content-box;
  }
`;
