import React from 'react';
import { useTheme } from 'hooks/theme';

const Failed = () => {
  const { primary, error } = useTheme();

  return (
    <div style={{ maxWidth: 280, width: '100%', height: 'auto' }}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 279 279"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_7_1618)">
          <path
            d="M217.691 46.384L26.1538 70.4564L49.3841 255.292L240.921 231.22L217.691 46.384Z"
            fill="black"
          />
          <path
            d="M225.426 35.4945L33.8891 59.5669L57.1193 244.403L248.656 220.33L225.426 35.4945Z"
            fill={primary}
          />
          <path
            opacity="0.4"
            d="M198.221 17.027L186.23 26.7265L195.928 38.7162L207.919 29.0167L198.221 17.027Z"
            fill={primary}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M179.073 268.502L178.647 276.367L172.046 272.067L165.447 267.766L172.472 264.201L179.497 260.637L179.073 268.502Z"
            fill={primary}
          />
          <path
            opacity="0.2"
            d="M161.483 28.0657L128.02 36.0393L129.455 42.0626L162.918 34.089L161.483 28.0657Z"
            fill={primary}
          />
          <path
            d="M9.74988 155.699C13.7528 155.699 16.9979 152.454 16.9979 148.451C16.9979 144.448 13.7528 141.203 9.74988 141.203C5.74691 141.203 2.50186 144.448 2.50186 148.451C2.50186 152.454 5.74691 155.699 9.74988 155.699Z"
            fill={primary}
          />
          <path
            opacity="0.2"
            d="M271.336 152.965C273.829 152.965 275.85 150.944 275.85 148.451C275.85 145.957 273.829 143.936 271.336 143.936C268.842 143.936 266.821 145.957 266.821 148.451C266.821 150.944 268.842 152.965 271.336 152.965Z"
            fill={primary}
          />
          <path
            opacity="0.4"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M108.696 272.477C108.658 274.417 108.334 276.261 108.737 278.352C112.079 278.146 113.825 278.57 116.768 276.886C118.856 275.692 120.414 273.875 121.878 272.039C123.726 269.723 124.735 267.108 127.995 265.981C131.071 264.918 134.281 266.476 137.6 265.167C141.848 263.494 143.968 259.881 145.879 256.972C146.82 255.536 147.311 254.149 149.765 253.265C151.472 252.649 153.489 253.058 155.497 252.936C160.553 252.622 164.693 250.592 166.636 245.826C165.116 244.898 163.415 244.503 161.847 243.726C157.88 249.627 151.484 245.05 145.272 248.873C140.719 251.674 139.926 257.917 135.404 259.642C133.714 260.289 131.454 259.761 129.961 259.83C128.117 259.916 126.139 260.099 124.296 261.02C119.509 263.413 117.969 270.028 113.285 271.997C111.726 272.653 110.506 272.253 108.696 272.477Z"
            fill={primary}
          />
          <path
            d="M58.3262 218.433C59.3673 221.909 61.0983 224.484 63.8139 226.358C66.349 228.106 68.772 228.09 70.2818 226.453C71.8187 224.787 71.5398 222.614 69.3173 220.289C67.47 218.357 65.3247 216.715 64.1825 214.181C63.497 212.662 62.1057 211.709 60.3057 212.135C58.2382 212.624 57.8261 214.279 57.9256 216.09C57.9756 217.005 58.2333 217.915 58.3262 218.433Z"
            fill="white"
          />
          <path
            d="M72.9953 231.464C73.7157 233.13 76.6759 234.186 78.496 233.48C79.8753 232.944 80.3673 231.904 79.8041 230.531C79.1152 228.852 76.2214 227.853 74.4083 228.627C73.0807 229.193 72.5653 230.172 72.9953 231.464Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M219.007 54.0952C225.304 54.9439 223.146 45.0015 218.728 46.9592C216.428 47.9775 216.557 53.7652 219.007 54.0952Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M222.286 59.4068C221.845 58.6049 219.818 57.5714 218.197 58.7555C216.088 60.3039 216.122 73.3336 218.883 74.5737C222.823 76.339 225.813 65.816 222.286 59.4068Z"
            fill="white"
          />
          <path
            d="M213.516 141.359C213.949 102.329 182.661 70.3387 143.631 69.9055C104.602 69.4722 72.611 100.76 72.1777 139.79C71.7444 178.819 103.033 210.81 142.062 211.243C181.092 211.676 213.083 180.388 213.516 141.359Z"
            fill="white"
          />
          <path
            d="M144.654 200.861C177.949 199.875 204.14 172.086 203.154 138.791C202.168 105.496 174.378 79.305 141.084 80.2909C107.789 81.2768 81.5979 109.067 82.5838 142.361C83.5697 175.656 111.36 201.847 144.654 200.861Z"
            fill={error}
          />
          <path
            d="M171.516 111.917C169.492 109.893 166.211 109.893 164.187 111.917L142.859 133.245L121.532 111.917C119.507 109.893 116.227 109.893 114.202 111.917C112.178 113.941 112.178 117.223 114.202 119.247L135.53 140.575L114.202 161.903C112.178 163.927 112.178 167.209 114.202 169.233C115.214 170.245 116.541 170.751 117.867 170.751C119.193 170.751 120.519 170.245 121.532 169.233L142.859 147.905L164.187 169.233C165.199 170.245 166.526 170.751 167.852 170.751C169.178 170.751 170.504 170.245 171.516 169.233C173.541 167.209 173.541 163.927 171.516 161.903L150.189 140.575L171.516 119.247C173.541 117.223 173.541 113.941 171.516 111.917Z"
            fill="white"
          />
          <path
            d="M94.8653 187.134C101.668 180.331 101.668 169.301 94.8653 162.498C88.0623 155.695 77.0324 155.695 70.2294 162.498C63.4264 169.301 63.4264 180.331 70.2294 187.134C77.0324 193.937 88.0623 193.937 94.8653 187.134Z"
            fill="white"
          />
          <path
            d="M90.0692 183.575C89.1603 183.575 88.4225 182.838 88.4225 181.928C88.4225 178.626 85.7363 175.939 82.4336 175.939C79.1309 175.939 76.4447 178.625 76.4447 181.928C76.4447 182.838 75.707 183.575 74.798 183.575C73.889 183.575 73.1512 182.838 73.1512 181.928C73.1512 176.81 77.3145 172.646 82.4336 172.646C87.5521 172.646 91.716 176.81 91.716 181.928C91.716 182.838 90.9782 183.575 90.0692 183.575Z"
            fill={primary}
          />
          <path
            d="M74.9978 171.978C76.2127 171.978 77.1975 170.993 77.1975 169.779C77.1975 168.564 76.2127 167.579 74.9978 167.579C73.783 167.579 72.7982 168.564 72.7982 169.779C72.7982 170.993 73.783 171.978 74.9978 171.978Z"
            fill={primary}
          />
          <path
            d="M90.0077 171.978C91.2225 171.978 92.2073 170.993 92.2073 169.779C92.2073 168.564 91.2225 167.579 90.0077 167.579C88.7928 167.579 87.808 168.564 87.808 169.779C87.808 170.993 88.7928 171.978 90.0077 171.978Z"
            fill={primary}
          />
        </g>
        <defs>
          <clipPath id="clip0_7_1618">
            <rect width="278.352" height="278.352" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default Failed;
