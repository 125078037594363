import React from 'react';

import { useTheme } from 'hooks/theme';

const NotFoundError = () => {
  const {
    neutralContent,
    primary,
    primaryHover,
    primaryBg,
    primaryContent,
    error,
  } = useTheme();

  return (
    <div style={{ maxWidth: 378, width: '100%', height: 'auto' }}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 462 462"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="xMidYMid meet"
      >
        <path
          d="M354.628 93.7086L75.1459 128.834L109.042 398.538L388.524 363.413L354.628 93.7086Z"
          fill={neutralContent}
        />
        <path
          d="M365.914 77.82L86.4327 112.945L120.329 382.649L399.81 347.524L365.914 77.82Z"
          fill={primary}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M74.9358 168.134V334.689C74.9358 348.765 86.8464 360.172 101.543 360.172H358.918C373.614 360.172 385.528 348.765 385.528 334.689V168.134L236.602 155.863L74.9358 168.134Z"
          fill={primaryContent}
        />
        <path
          opacity="0.2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M74.9358 168.134V334.689C74.9358 348.765 86.8464 360.172 101.543 360.172H358.918C373.614 360.172 385.528 348.765 385.528 334.689V168.134L236.602 155.863L74.9358 168.134Z"
          fill={primary}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M385.528 146.682C385.528 132.611 373.614 121.204 358.918 121.204H101.543C86.8471 121.204 74.9365 132.611 74.9365 146.682V168.133H385.529V146.682H385.528Z"
          fill={primaryBg}
        />
        <path
          d="M81.3589 329.643C82.7736 334.366 85.1257 337.865 88.8157 340.411C92.2604 342.786 95.5529 342.764 97.6044 340.54C99.6928 338.276 99.3138 335.324 96.2939 332.165C93.7837 329.539 90.8686 327.308 89.3166 323.865C88.385 321.8 86.4946 320.506 84.0487 321.085C81.2393 321.749 80.6793 323.997 80.8145 326.458C80.8825 327.702 81.2326 328.938 81.3589 329.643Z"
          fill={primaryContent}
        />
        <path
          d="M101.292 347.35C102.271 349.614 106.294 351.048 108.767 350.088C110.641 349.36 111.31 347.947 110.544 346.082C109.608 343.8 105.676 342.443 103.212 343.494C101.408 344.264 100.708 345.594 101.292 347.35Z"
          fill={primaryContent}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M372.205 173.175C380.761 174.328 377.829 160.818 371.826 163.479C368.7 164.862 368.876 172.727 372.205 173.175Z"
          fill={primaryContent}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M376.661 180.392C376.061 179.303 373.307 177.898 371.104 179.507C368.239 181.611 368.285 199.317 372.037 201.002C377.39 203.4 381.453 189.101 376.661 180.392Z"
          fill={primaryContent}
        />
        <path
          d="M326.561 251.378C327.15 198.344 284.634 154.875 231.6 154.286C178.566 153.697 135.096 196.212 134.507 249.246C133.918 302.28 176.434 345.75 229.468 346.338C282.502 346.927 325.972 304.412 326.561 251.378Z"
          fill={primaryContent}
        />
        <path
          d="M232.989 332.231C278.231 330.891 313.82 293.129 312.48 247.888C311.141 202.646 273.379 167.057 228.138 168.397C182.896 169.736 147.307 207.498 148.646 252.739C149.986 297.981 187.748 333.57 232.989 332.231Z"
          fill={error}
        />
        <path
          d="M269.49 211.372C266.739 208.621 262.282 208.621 259.531 211.372L230.55 240.353L201.57 211.372C198.819 208.621 194.361 208.621 191.61 211.372C188.859 214.122 188.859 218.581 191.61 221.331L220.591 250.313L191.61 279.294C188.859 282.044 188.859 286.503 191.61 289.253C192.986 290.629 194.788 291.317 196.59 291.317C198.392 291.317 200.194 290.629 201.57 289.253L230.55 260.273L259.531 289.253C260.906 290.629 262.709 291.317 264.51 291.317C266.313 291.317 268.115 290.629 269.49 289.253C272.241 286.503 272.241 282.044 269.49 279.294L240.51 250.313L269.49 221.331C272.241 218.581 272.241 214.122 269.49 211.372Z"
          fill={primaryContent}
        />
        <path
          d="M159.698 227.643H101.843C93.5786 227.643 86.8783 234.345 86.8783 242.61V264.776C86.8783 273.043 93.5786 279.745 101.843 279.745H159.698C167.963 279.745 174.663 273.043 174.663 264.776V242.61C174.663 234.345 167.963 227.643 159.698 227.643Z"
          fill={primary}
        />
        <path
          d="M129.978 238.979C121.736 238.979 115.033 245.683 115.033 253.924C115.033 262.165 121.737 268.869 129.978 268.869C138.218 268.869 144.925 262.165 144.925 253.924C144.925 245.683 138.219 238.979 129.978 238.979ZM140.746 253.924C140.746 259.859 135.916 264.689 129.978 264.689C124.044 264.689 119.214 259.86 119.214 253.924C119.214 247.988 124.043 243.159 129.978 243.159C135.916 243.159 140.746 247.989 140.746 253.924Z"
          fill={primaryContent}
        />
        <path
          d="M112.746 255.603H110.199V241.069C110.199 240.197 109.649 239.409 108.83 239.107C108.018 238.808 107.082 239.05 106.519 239.712L92.3397 256.337C91.8123 256.955 91.6904 257.831 92.0302 258.571C92.3752 259.307 93.1213 259.784 93.9302 259.784H106.019V266.74C106.019 267.893 106.957 268.83 108.109 268.83C109.26 268.83 110.199 267.893 110.199 266.74V259.784H112.745C113.897 259.784 114.835 258.846 114.835 257.693C114.836 256.54 113.897 255.603 112.746 255.603ZM106.019 246.74V255.603H98.4579L106.019 246.74Z"
          fill={primaryContent}
        />
        <path
          d="M165.826 255.603H163.279V241.069C163.279 240.197 162.729 239.409 161.91 239.107C161.095 238.808 160.162 239.05 159.599 239.712L145.42 256.337C144.892 256.955 144.77 257.831 145.11 258.57C145.45 259.308 146.196 259.785 147.007 259.785H159.096V266.741C159.096 267.894 160.034 268.831 161.186 268.831C162.339 268.831 163.278 267.893 163.278 266.741V259.785H165.825C166.977 259.785 167.915 258.847 167.915 257.694C167.915 256.54 166.977 255.603 165.826 255.603ZM159.096 246.74V255.603H151.538L159.096 246.74Z"
          fill={primaryContent}
        />
        <path
          d="M165.337 313.579C174.581 304.335 174.581 289.347 165.337 280.103C156.093 270.859 141.105 270.859 131.861 280.103C122.617 289.347 122.617 304.335 131.861 313.579C141.105 322.823 156.093 322.823 165.337 313.579Z"
          fill={primaryHover}
        />
        <path
          d="M158.82 308.742C157.585 308.742 156.582 307.741 156.582 306.505C156.582 302.018 152.932 298.366 148.444 298.366C143.956 298.366 140.306 302.017 140.306 306.505C140.306 307.741 139.304 308.742 138.069 308.742C136.834 308.742 135.831 307.741 135.831 306.505C135.831 299.55 141.488 293.892 148.444 293.892C155.399 293.892 161.057 299.55 161.057 306.505C161.057 307.741 160.055 308.742 158.82 308.742Z"
          fill={primaryContent}
        />
        <path
          d="M138.34 292.984C139.991 292.984 141.329 291.645 141.329 289.995C141.329 288.344 139.991 287.006 138.34 287.006C136.69 287.006 135.351 288.344 135.351 289.995C135.351 291.645 136.69 292.984 138.34 292.984Z"
          fill={primaryContent}
        />
        <path
          d="M158.736 292.984C160.386 292.984 161.725 291.645 161.725 289.995C161.725 288.344 160.386 287.006 158.736 287.006C157.085 287.006 155.747 288.344 155.747 289.995C155.747 291.645 157.085 292.984 158.736 292.984Z"
          fill={primaryContent}
        />
      </svg>
    </div>
  );
};

export default NotFoundError;
