import React from 'react';
import { useTranslation } from 'react-i18next';

import { ScRequirementsList } from './styled';

export const RequirementsList = () => {
  const { t } = useTranslation('documents', { useSuspense: true });

  return (
    <ScRequirementsList>
      <div className="requirements">
        <div className="requirement">{t('checkDocuments.requirements.0')}</div>
        <div className="requirement">{t('checkDocuments.requirements.1')}</div>
        <div className="requirement">{t('checkDocuments.requirements.2')}</div>
      </div>
    </ScRequirementsList>
  );
};
