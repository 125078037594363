import * as React from 'react';
import type { SVGProps } from 'react';
const SvgDesktopBadge = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={25}
      height={24}
      fill="none"
      viewBox="0 0 25 24"
      {...props}
    >
      <path
        fill="#fff"
        d="M21.5 2h-18c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h7v2h-2v2h8v-2h-2v-2h7c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2Zm0 14h-18V4h18v12Z"
      />
    </svg>
  );
};
export default SvgDesktopBadge;
