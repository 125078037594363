import React, { lazy } from 'react';
import {
  createBrowserRouter,
  Outlet,
  RouterProvider,
  useLoaderData,
} from 'react-router-dom';

import { initVerificationMatcher } from 'helpers/routes';

import entrypointRoute from 'modules/entrypoint';

const Layout = lazy(() => import('components/Layout'));

const RedirectComponent = () => {
  const { redirect } = useLoaderData() as { redirect?: JSX.Element };

  if (!redirect) return null;

  return <>{redirect}</>;
};

const router = createBrowserRouter([
  {
    path: 'verification',
    children: [
      {
        path: ':token',
        element: <RedirectComponent />,
        loader: async ({ params: { token } }) => ({
          redirect: await initVerificationMatcher(token),
        }),
      },
    ],
  },
  {
    element: (
      <Layout>
        <Outlet />
      </Layout>
    ),
    children: entrypointRoute,
  },
]);

export const Routes = () => {
  return <RouterProvider router={router} />;
};
